import React, { useState, useEffect } from "react";
import {Table} from 'react-bootstrap';
import '../Dependencias/formulario.css'
import {Periodo} from './periodo'
import UpdatedNavBar from "../NavBar/UpdatedNavBar";

// HTTP Requests
import Axios from 'axios';

export const PeriodosView = () => {

    /**
     * States de tipos de objetivos
     */
    const [idPeriodo, setIdPeriodo] = useState('');
    /**
     * nombre usuario
     */
    const [usuarioWeb, setUsuarioWeb] = useState('');

    useEffect(()=> {
        Axios.get("/api/getuserweb",
        {headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=> {
            setUsuarioWeb(response.data.usuarioWeb);
        });
    }, [])

    /**
     * Bandera para área de evaluación
     */
    const [areaFlag, setPeriodoFlag] = useState(true);

    return(
        <>
            <UpdatedNavBar />
            <div className="container-dependencias">
                {
                    (areaFlag) ?
                    <Periodo
                        idPeriodo={idPeriodo} setIdPeriodo={setIdPeriodo}
                        setPeriodoFlag={setPeriodoFlag}
                        usuarioWeb={usuarioWeb}
                    /> : 
                    <>
                        <h2>Tipos de Objetivos</h2 >
                        <p className="lead blog-description">Períodos de la organización</p>
                        
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Descripción</th>
                                </tr> 
                                <tr className="segunda-fila">
                                    <td>{idPeriodo}</td>
                                </tr>
                            </thead>
                        </Table>
                    </>
                }
            </div>
        
        </>
    )

}
