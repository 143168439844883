import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { AiFillEdit } from 'react-icons/ai'
import EditarRoles from './EditarRoles'

function FilaRoles(props) {

    const [lista, setLista] = useState([])
    const [show, setShow] = useState(false)

    const cerrarModal = () => setShow(false)
    const abrirModal = () => setShow(true)

    const refresh = () => {
        Axios.get('/api/get/adminusuariorolesusuario', { params: { id: props.id }, headers: { "x-access-token": localStorage.getItem('token') } })
            .then((response) => {
                setLista(response.data.result)
            })
    }

    useEffect(() => {
        if (props.id) {
            refresh()
        }
    }, [props.id])

    return (
        <tr key={props.indice}>
            <EditarRoles refresh={refresh} lista={lista} show={show} cerrarModal={cerrarModal} roles={props.roles} id={props.id} />
            <td>{props.indice + 1}</td>
            <td>
                <OverlayTrigger placement="top"
                    overlay={<Tooltip id="button-tooltip-1">Editar roles del usuario</Tooltip>} >
                    <Button variant='info' onClick={abrirModal}>
                        <AiFillEdit /> Editar roles
                    </Button>
                </OverlayTrigger>
            </td>
            <td>{props.persona.NOMBRE_COMPLETO_JUNTO}</td>
            <td>
                <ul>
                    {
                        lista &&
                            lista.length === 0 ? <>Este usuario no tienen ningún rol asignado</> :
                            <>
                                {
                                    lista.map((rol, indice) => {
                                        return (
                                            (rol.ACTIVO === 1) ?
                                                <li key={rol + indice}>{rol.NOMBRE}</li> : <></>
                                        )
                                    })
                                }
                            </>
                    }
                </ul>
            </td>
        </tr>
    )
}

export default FilaRoles