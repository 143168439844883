import React, { useState } from "react";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './recContra.css';
import { Button } from "react-bootstrap";

// HTTP Requests
import Axios from 'axios';

export const RecContra = () => {

    const [invalid, setInvalid] = useState(false);

    function recoverPassword() {
        if (document.getElementById('mail').value !== ''){
            setInvalid(false);
            Axios.post('/recoverpass', {
                correo: document.getElementById('mail').value,
            }).then((response) => {
                if (response.data.error){
                    toast.error('Ha ocurrido un error, por favor intente más tarde o comuniquese con soporte para verificar que usted tenga acceso a esta función');
                } else if(response.data.vacio){
                    toast.error(String(response.data.vacio));
                } else {
                    toast.success('El correo fue enviado con exito, revise su bandeja de entrada.');
                }
            });
        } else {
            setInvalid(true);
        }
    }

    return(
        <div className="container padding-bottom-3x mb-2 mt-5">
            <div className="row justify-content-center">
                <div className="col-lg-8 col-md-10">
                    <div className="forgot">
                        <h2>¿Ha olvidado su contraseña?</h2>
                        <p>Cambie su contraseña en tres simples pasos</p>
                        <ol className="list-unstyled">
                            <li><span className="text-primary text-medium">1. </span>Ingrese su correo registrado en el cuadro de abajo.</li>
                            <li><span className="text-primary text-medium">2. </span>Nuestro sistema se encargará de generarle una nueva contraseña y enviarla a su correo registrado.</li>
                            <li><span className="text-primary text-medium">3. </span>Ingrese de nuevo al sistema con su contraseña nueva y su correo registrado.</li>
                        </ol>
                    </div>	
                    <form className="card mt-4">
                    <div className="card-body">
                        <div className="form-group">
                            <label>Ingrese su correo registrado</label>
                            <input className="form-control" type="text" id="mail"/>
                            {
                                (invalid)?<span className='label-danger'>por favor, ingrese su correo registrado</span>:<></>
                            }
                        </div>
                    </div>
                    <div className="card-footer">
                        <Button style={{marginLeft: '5px'}} variant='success' onClick={()=>{recoverPassword()}} >Generar nueva contraseña</Button>
                        <Button style={{marginLeft: '5px'}} variant='danger' href="/">Regresar a la página principal</Button>
                    </div>
                    </form>
                </div>
            </div>
        </div>
        
    )

}