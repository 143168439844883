import React from "react";
import { Row, Col, InputGroup, Button, Form } from "react-bootstrap";
import { Stack } from '@mui/material'
//import {toast} from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css'

// HTTP Requests
import Axios from 'axios';

function FormSinacig(props) {

    return (
        <Stack spacing={1} direction={{ xs: 'column', md: 'row' }}>

            <InputGroup className="mb-3">
                <Button
                    variant="outline-secondary"
                    disabled
                >
                    Seleccione un período
                </Button>
                <Form.Select
                    value={props.periodo}
                    onChange={(e) => {
                        props.setPeriodo(e.target.value);
                        props.setListaAreasSelec(null);
                        props.setListaAreasSelecDesc(null);
                    }}
                >
                    <option value={''} ></option>
                    {
                        props.periodos.map((value) => {
                            return (
                                <option key={value.ANO} value={value.ANO}>
                                    {value.ANO}
                                </option>
                            )
                        })
                    }
                </Form.Select>
            </InputGroup>
   {/*         
            <InputGroup className="mb-3">
                <Button
                    variant="outline-secondary"
                    id="button-addon1"
                    onClick={() => {
                        let newArray = []
                        let newArrayDesc = []
                        props.listaAreas.forEach((value) => {
                            newArray.push(value.CODIGO);
                            newArrayDesc.push(value.DESCRIPCION);
                        })
                        props.setListaAreasSelec(newArray);
                        props.setListaAreasSelecDesc(null);
                    }}
                >
                    Seleccionar todas las áreas
                </Button>
                <Form.Select
                    multiple
                    value={props.listaAreasSelec}
                    onChange={(e) => {
                        let value = Array.from(e.target.selectedOptions, option => option.value);
                        props.setListaAreasSelec(value);
                    }}
                >
                    {
                        props.listaAreas.map((value) => {
                            return (
                                <option key={value.CODIGO} value={value.CODIGO}>
                                    {value.ABREVIATURA} - {value.DESCRIPCION}
                                </option>
                            )
                        })
                    }
                </Form.Select>
            </InputGroup>
   */}         
        </Stack>
    )
}

export default FormSinacig