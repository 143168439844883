import React, { useState } from "react";
import { NavBar } from '../NavBarAdmin/navBar';
// HTTP Requests
import Axios from 'axios';
import { Table, Form, Row, Col, Button } from "react-bootstrap";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Historial() {

    const [fi, setFi] = useState('')
    const [ff, setFf] = useState('')
    const [r1, setR1] = useState([])
    const [r2, setR2] = useState([])
    const [r3, setR3] = useState([])

    const getInfo = () => {
        const conf = {
            params: {
                fi: fi,
                ff: ff
            }, 
            headers: {
                "x-access-token": localStorage.getItem('tokenAdminSys')
            }
        }
        Axios.get('/api/get/historial', conf)
        .then((response)=>{
            if(response.data.result1.length !== 0) {
                setR1(response.data.result1)
                setR2(response.data.result2)
                setR3(response.data.result3)
            } else {
                toast.info('No hay registros en estas fechas.')
                setR1([])
                setR2([])
                setR3([])
            }
        })
    }

    return (
        <>
            <NavBar/>
            <div className="container-usuario-administracion">
                <h3 className="blog-title">Historial de accesos a la aplicación</h3>
                <p className="lead blog-description">Detalle de usuarios que ingresan</p>
                <hr/>
                <Form noValidate >
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="validationFormik101" className="position-relative" >
                            <Form.Label>Fecha inicio</Form.Label>
                            <Form.Control onChange={(e)=>{setFi(e.target.value)}} type="date" />
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationFormik102" className="position-relative" >
                            <Form.Label>Fecha fin</Form.Label>
                            <Form.Control onChange={(e)=>{setFf(e.target.value)}} type="date" />
                        </Form.Group>
                    </Row>
                    <Button variant="success" onClick={getInfo} >Buscar</Button>
                </Form>
                <p/>
                <Row>
                    <Col>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Institución</th>
                                    <th>Nombre de usuario</th>
                                    <th>fecha que ingreso</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    r1.map((elemento, indice) => {
                                        return(
                                            <tr key={indice}>
                                                <td>{elemento.NOMBRE}</td>
                                                <td>{elemento.usuario}</td>
                                                <td>{elemento.FECHA_CREACION.substring(0, 10)}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Col>
                    <Col>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Institución</th>
                                    <th>Nombre de usuario</th>
                                    <th>Cantidad de veces que ingreso</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    r3.map((elemento, indice) => {
                                        return(
                                            <tr key={indice}>
                                                <td>{elemento.NOMBRE}</td>
                                                <td>{elemento.usuario}</td>
                                                <td>{elemento.cantidad}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Col>
                    <Col>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Institución</th>
                                    <th>Cantidad de usuarios que ingresaron</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    r2.map((elemento, indice) => {
                                        return(
                                            <tr key={indice}>
                                                <td>{elemento.NOMBRE}</td>
                                                <td>{elemento.cantidad}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Historial