import React from 'react';
import './home.css';
import { NavBar } from '../NavBarAdmin/navBar';
import { NotificacionesAdmin } from './notificaciones';

export const HomeAdminSys = () => {
    return(
        <>
            <NavBar/>
            <div className='container-home'>
                <h1>Bienvenido al sistema de evaluación y gestión de riesgos</h1>   
                <h5>A traves de este portal podrá administrar las instituciones y usuarios dentro del sistema.</h5>     
                <NotificacionesAdmin/>    
            </div>
        </>
    ); 
}