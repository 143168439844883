import React from 'react'
import { Modal, Button } from "react-bootstrap";
//import { Form } from 'react-bootstrap';
//import { Modal, Box, Button, Stack } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  backgroundColor: 'white',
  boxShadow: 24,
  p: 4,
};

const PopUpBorrar = ({ showModal, hideModal, confirmModal, id, message }) => {
  return (
    <Modal show={showModal} onHide={hideModal} centered>
      <div style={style}>
        <Modal.Header>
          <Modal.Title>Borrar registro</Modal.Title>
        </Modal.Header>
        <Modal.Body><div className="alert alert-danger">{message}</div></Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => confirmModal(id)}>
            Aceptar
          </Button>
          <Button variant="default" onClick={hideModal}>
            Cancelar
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  )
}

export default PopUpBorrar;