import React, { useState, useEffect } from "react";
import { Button, Table, Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiOutlinePlus, AiFillEdit, AiOutlineSearch, AiFillDelete } from 'react-icons/ai';
import { BiUser } from "react-icons/bi";
import { MdWork } from "react-icons/md";
import './responsable.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UpdateCargo } from "./updateCargo";
import { CreatePersona } from "./createPersona";

import { UpdateSupervisor } from "./updateSupervisor";
import { UpdatePersona } from "./updatePersona";

// HTTP Requests
import Axios from 'axios';
import UpdatedNavBar from "../NavBar/UpdatedNavBar";
import PopUpBorrar from '../../Components/popUpBorrar'



export const ResponsablesView = () => {

    /**
     * Lista de dependencias
     */
    const [pNombre, setPNombre] = useState('');
    const [sNombre, setSNombre] = useState('');
    const [tNombre, setTNombre] = useState('');
    const [pApellido, setPApellido] = useState('');
    const [sApellido, setSApellido] = useState('');
    const [tApellido, setTApellido] = useState('');
    const [correo, setCorreo] = useState('');
    const [fechaNacimiento, setFechaNacimiento] = useState('');
    const [sexo, setSexo] = useState('');
    const [supervisor, setSupervisor] = useState('');
    const [puesto, setPuesto] = useState('');
    const [estado, setEstado] = useState('');
    const [direccion, setDireccion] = useState('');
    const [fechaCreacion, setFechaCreacion] = useState('');
    const [documento, setDocumento] = useState('');
    const [tipoDocumento, setTipoDocumento] = useState('');

    /**
     * Variables para cargo
     */
    const [cargo, setCargo] = useState('');
    const [fechaToma, setFechaToma] = useState('');
    const [fechaEntrega, setFechaEntrega] = useState('');
    const [codigoResponsableEntidad, setCodigoResponsableEntidad] = useState('');
    const [codigoEntidad, setCodigoEntidad] = useState('');

    /**
     * Variable para supervisor
     */
    const [codigoPersona, setCodigoPersona] = useState('');
    const [dependencia, setDependencia] = useState('');

    /**
     * Lista de dependencias
     */
    const [listaDep, setListaDep] = useState([]);

    /**
     * Lista de responsables
     */
    const [listaResponsables, setListaResponsables] = useState([]);
    const [listaRespSec, setListaRespSec] = useState([])

    /**
     * Modals de dependencias
     */
    const [showState, setShowState] = useState(false);
    const [showPersona, setShowPersona] = useState(false);


    /*modal del popup borrar */
    const [id, setId] = useState(null);
    const [nombre, setNombre] = useState(null);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState(null);

    // Handle the displaying of the modal based on type and id
    const showDeleteModal = (id, nombre) => {
        setId(id);
        setNombre(nombre)
        setDeleteMessage(`¿Está seguro de borrar el responsable ${nombre} ?`);
        setDisplayConfirmationModal(true);
    };

    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };


    /**
     * Flags para saber si estan correctas las forms
     */
    const [validatedCreate, setValidatedCreate] = useState(false);
    const [validatePersona, setValidatePersona] = useState(false);

    const refreshPersonas = () => {
        //responsables
        Axios.get("/api/get/responsablesInfo", { headers: { "x-access-token": localStorage.getItem('token') } })
            .then((response) => {
                setListaResponsables(response.data);
                setListaRespSec(response.data);
            });
    }

    const deletePersona = (codigo) => {
        Axios.delete('/api/delete/persona/' + String(codigo), { headers: { "x-access-token": localStorage.getItem('token') } })
            .then((response) => {
                if (response.data.result === 'Error') {
                    toast.error('Error al eliminar persona: ' + nombre)
                } else {
                    toast.info('Responsable eliminado con éxito: ' + nombre)
                    refreshPersonas()
                }
            });
        setDisplayConfirmationModal(false);
    };
    /**
     * Función para recuperar lista de personas y dependencias
     */
    useEffect(() => {
        var tmp = [];
        var index = 0;
        Axios.get("/api/various/getEntity", { headers: { "x-access-token": localStorage.getItem('token') } })
            .then((response) => {
                //dependencia
                Axios.get("/api/get/dependencia", { headers: { "x-access-token": localStorage.getItem('token') } })
                    .then((responseGet) => {
                        for (index = 0; index < responseGet.data.length; index++) {
                            tmp.push({
                                codigo: responseGet.data[index].CODIGO,
                                nombre: responseGet.data[index].NOMBRE,
                            });
                        }
                        setListaDep(tmp);
                    });
                refreshPersonas()
            });
    },
        []);

    /**
     * Funciones para crear una nueva persona
     */
    const submitPersona = () => {
        const params = {
            pNombre: pNombre,
            puesto: puesto,
            sNombre: sNombre,
            tNombre: tNombre,
            pApellido: pApellido,
            sApellido: sApellido,
            tApellido: tApellido,
            fechaNacimiento: fechaNacimiento,
            correo: correo,
            codigo_entidad: String(codigoEntidad),
            sexo: sexo,
            supervisor: supervisor,
            estado: estado,
            direccion: direccion,
            documento: documento,
            tipoDocumento: tipoDocumento,
        }
        Axios.post("/api/insert/persona", params, { headers: { "x-access-token": localStorage.getItem('token') } })
            .then((response) => {
                if (response.data.result) {
                    refreshPersonas()
                    setShowState(false);
                    setValidatedCreate(false);
                } else {
                    if (response.data.mensaje) {
                        toast.error(response.data.mensaje);
                    } else if (String(response.data.error) === 'ORA-00001') {
                        toast.error('Error, usuario web ya existe, asigne uno nuevo.');
                    } else {
                        toast.error('Error al guardar nuevo responsable');
                    }
                }
            });
    };

    const handleSubmitNew = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            setValidatedCreate(true);
        } else {
            submitPersona(setValidatedCreate);
        }
        setValidatedCreate(true);
    };

    /**
     * Funciones para editar a la persona
     */
    const updatePersona = () => {
        Axios.put("/api/update/persona/" + codigoPersona.toString(), {
            pNombre: pNombre,
            puesto: puesto,
            sNombre: sNombre,
            tNombre: tNombre,
            pApellido: pApellido,
            sApellido: sApellido,
            tApellido: tApellido,
            fechaNacimiento: fechaNacimiento,
            correo: correo,
            sexo: sexo,
            supervisor: supervisor,
            CODIGO_USUARIO_ROL: dependencia,
            estado: estado,
            direccion: direccion,
            documento: documento,
            tipoDocumento: tipoDocumento,
        }, { headers: { "x-access-token": localStorage.getItem('token') } }).then((response) => {
            if (response.data.mensaje) {
                console.log('error1')
                toast.error(response.data.mensaje);
            } else if (response.data.error) {
                console.log('error2')
                toast.error('Error al actualizar usuario');
            } else {
                refreshPersonas()
                setShowPersona(false);
                setValidatePersona(false);
            }
        });

    }

    const handleSubmitPersona = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            setValidatePersona(true);
        } else {
            updatePersona();
        }
        setValidatePersona(true);
    };

    useEffect(() => {
        setListaRespSec(busquedaResponsable(listaResponsables))
    }, [listaResponsables])

    const busquedaResponsable = (listaInfo) => {
        const input1 = document.getElementById('input-1').value;
        const input2 = document.getElementById('input-2').value;
        const input3 = document.getElementById('input-3').value;
        const input4 = document.getElementById('input-4').value;
        let tmpArray = []
        if (String(input1) === '' && String(input2) === '' && String(input3) === ''  && String(input4) === '') {
            return listaInfo
        } else {
            const indexes = listaInfo.map(
                (element, index) => {
                    let flag = false;

                    if (String(input1) !== '') {
                        if (element.NOMBRE_APELLIDO.toLowerCase().includes(input1.toLowerCase())) {
                            flag = true;
                        } else {
                            return -1
                        }
                    }

                    let tmpDependencia;
                    try {
                        if (element.CODIGO_DEPENDENCIA) {
                            tmpDependencia = listaDep.find(value => String(value['codigo']) === String(element.CODIGO_DEPENDENCIA))['nombre'];
                        } else {
                            tmpDependencia = 'Pendiente por asignar';
                        }
                    } catch (error) {
                        tmpDependencia = 'Pendiente por asignar';
                    }

                    if (String(input2) !== '') {
                        if (tmpDependencia.toLowerCase().includes(input2.toLowerCase())) {
                            flag = true;
                        } else {
                            return -1
                        }
                    }

                    if (String(input3) !== '') {
                        if (element.CORREO_ELECTRONICO.toLowerCase().includes(input3.toLowerCase())) {
                            flag = true;
                        } else {
                            return -1
                        }
                    }

                    if (String(input4) !== '') {
                        if (element.ACTIVO.toLowerCase() === (input4.toLowerCase())) {
                            flag = true;
                        } else {
                            return -1
                        }
                    }

                    if (flag) {
                        return index
                    }

                }
            ).filter(value => value >= 0)
            for (let i of indexes) {
                tmpArray.push(listaResponsables[i])
            }
            return tmpArray
        }
    }

    const limpiar = () => {
        setPNombre('')
        setSNombre('')
        setTNombre('')
        setPApellido('')
        setSApellido('')
        setTApellido('')
        setCorreo('')
        setSexo('')
        setFechaNacimiento('')
        setSupervisor('')
        setPuesto('')
    }

    /**
     * Renderización
    */
    return (
        <>
            <UpdatedNavBar />
            <div className="container-responsables">
                {/**
             * Titulos principales de la página
             */}
                <h2>Empleados</h2 >
                <p className="lead blog-description">Empleados dentro de la institución</p>
                <p />
                {/**
             * Botón para agregar nuevo responsable
            */}
                <Button size='sm' variant="success" className="nueva-dependencia" onClick={() => { limpiar(); setShowState(true); }}>
                    <AiOutlinePlus /> Nuevo Empleado
                </Button>
                <p />
                {/**
             * Modal con form para agregar nueva persona
             */}
                <CreatePersona showState={showState} validatedCreate={validatedCreate} setValidatedCreate={setValidatedCreate} submitPersona={submitPersona} setPNombre={setPNombre}
                    setSNombre={setSNombre} setTNombre={setTNombre} setPApellido={setPApellido} setSApellido={setSApellido}
                    setTApellido={setTApellido} setCorreo={setCorreo} setSexo={setSexo} setFechaNacimiento={setFechaNacimiento}
                    setShowState={setShowState} handleSubmitNew={handleSubmitNew} setSupervisor={setSupervisor}
                    setPuesto={setPuesto} setEstado={setEstado} setDireccion={setDireccion} setTipoDocumento={setTipoDocumento} setDocumento={setDocumento}
                />
                {/**
             * Tabla de responsables
             */}
                {/*<pre>
                    <AiFillEdit /> = Editar <AiFillDelete /> = Eliminar responsable
                </pre>*/}
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Opción</th>
                            <th>Nombre</th>
                            <th>Correo Electrónico</th>
                            <th>Dependencia</th>
                            <th>Estado</th>
                        </tr>
                        <tr>
                            <td>Herramientas de búsqueda</td>
                            <td>
                                <InputGroup className="search-input">
                                    <Button
                                        variant="outline-secondary"
                                        onClick={() => {
                                            setListaRespSec(busquedaResponsable(listaResponsables));
                                        }}
                                    >
                                        <AiOutlineSearch />
                                    </Button>
                                    <Form.Control
                                        onChange={() => {
                                            setListaRespSec(busquedaResponsable(listaResponsables));
                                        }}
                                        id="input-1"
                                        className="search-input"
                                        type="text"
                                        placeholder="búsqueda por nombre"
                                    />
                                </InputGroup>
                            </td>
                            <td>
                                <InputGroup className="search-input">
                                    <Button
                                        variant="outline-secondary"
                                        onClick={() => {
                                            setListaRespSec(busquedaResponsable(listaResponsables));
                                        }}
                                    >
                                        <AiOutlineSearch />
                                    </Button>
                                    <Form.Control
                                        onChange={() => {
                                            setListaRespSec(busquedaResponsable(listaResponsables));
                                        }}
                                        id="input-3"
                                        className="search-input"
                                        type="text"
                                        placeholder="búsqueda por correo"
                                    />
                                </InputGroup>
                            </td>
                            <td>
                                <InputGroup className="search-input">
                                    <Button
                                        variant="outline-secondary"
                                        onClick={() => {
                                            setListaRespSec(busquedaResponsable(listaResponsables));
                                        }}
                                    >
                                        <AiOutlineSearch />
                                    </Button>
                                    <Form.Control
                                        onChange={() => {
                                            setListaRespSec(busquedaResponsable(listaResponsables));
                                        }}
                                        id="input-2"
                                        className="search-input"
                                        type="text"
                                        placeholder="búsqueda por dependencia"
                                    />
                                </InputGroup>
                            </td>
                            <td>
                                <InputGroup className="search-input">
                                <Form.Select
                                            variant="outline-secondary"
                                            id="input-4"
                                            placeholder="búsqueda por estado"
                                            onChange={() => {
                                                setListaRespSec(busquedaResponsable(listaResponsables));
                                            }}
                                        >
                                            <option value='' ></option>
                                            <option value='0' >{'Inactivo'}</option>
                                            <option value='1' >{'Activo'}</option>
                                        </Form.Select>
                                </InputGroup>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            listaRespSec.map((value) => {
                                var tmpCargo = ''
                                var tmpDependencia = ''
                                var tmpActivo = ''
                                if (value.DESC_PUESTO === null) {
                                    tmpCargo = 'Pendiente por asignar';
                                } else {
                                    tmpCargo = value.DESC_PUESTO;
                                }
                                try {
                                    if (value.CODIGO_DEPENDENCIA) {
                                        tmpDependencia = listaDep.find(element => String(element['codigo']) === String(value.CODIGO_DEPENDENCIA))['nombre'];
                                    } else {
                                        tmpDependencia = 'Pendiente por asignar';
                                    }
                                } catch (error) {
                                    tmpDependencia = 'Pendiente por asignar';
                                }
                                return (
                                    <tr style={{
                                        backgroundColor: tmpActivo === 'No' ? '#FFF300' : ''
                                    }} key={value.CODIGO_PERSONA}>
                                        <td>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="button-tooltip-1">Editar responsable</Tooltip>}
                                            >
                                                <Button className="segundo-boton" size="sm" variant="info"
                                                    onClick={() => {
                                                        var firstDate = new Date(value.FECHA_NACIMIENTO);
                                                        firstDate.setDate(firstDate.getDate());
                                                        var fd = firstDate.toISOString().substr(0, 10);
                                                        
                                                        var fCreacion = new Date(value.FECHA_CREACION);
                                                        fCreacion.setDate(fCreacion.getDate());
                                                        var fc = fCreacion.toISOString().substr(0, 10);
                                                        setFechaNacimiento(fd);
                                                        setPNombre(value.PRIMER_NOMBRE);
                                                        setSNombre((value.SEGUNDO_NOMBRE) ? value.SEGUNDO_NOMBRE : '');
                                                        setTNombre((value.TERCER_NOMBRE) ? value.TERCER_NOMBRE : '');
                                                        setPApellido(value.PRIMER_APELLIDO);
                                                        setSApellido((value.SEGUNDO_APELLIDO) ? value.SEGUNDO_APELLIDO : '');
                                                        setTApellido((value.TERCER_APELLIDO) ? value.TERCER_APELLIDO : '');
                                                        setCorreo(value.CORREO_ELECTRONICO);
                                                        setSexo(value.SEXO);
                                                        setShowPersona(true);
                                                        setCodigoPersona(value.CODIGO_PERSONA);
                                                        setSupervisor(value.SUPERVISORFLAG);
                                                        setDependencia(value.CODIGO_ROL);
                                                        setPuesto(value.CODIGO_DEPENDENCIA);
                                                        setEstado(value.ACTIVO);
                                                        setDireccion(value.DIRECCION_DOMICILIO)
                                                        setFechaCreacion(fc)
                                                        setDocumento(value.DOCUMENTO)
                                                        setTipoDocumento(value.CODIGO_TIPO_DOCUMENTO)
                                                    }}
                                                >
                                                    <AiFillEdit /> Editar
                                                </Button>
                                            </OverlayTrigger>
                                            {
                                                <Button className="segundo-boton" size="sm" variant="danger"
                                                    onClick={() => showDeleteModal(value.CODIGO_PERSONA, (value.PRIMER_NOMBRE + ' ' + value.PRIMER_APELLIDO))}
                                                >
                                                    <AiFillDelete />    Eliminar
                                                </Button>
                                            }
                                        </td>
                                        <td>
                                            {value.NOMBRE_APELLIDO}
                                        </td>
                                        <td>
                                            {value.CORREO_ELECTRONICO}
                                        </td>
                                        <td>
                                            {tmpDependencia}
                                        </td>
                                        <td>
                                            {value.ACTIVO === '1' ? 'Activo' : 'Inactivo'}
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>
                {/**
             * Modal para actualizar responsable
                <UpdateCargo setValidatedUpdateCargo={setValidatedUpdateCargo} handleSubmitUpdateCargo={handleSubmitUpdateCargo}
                    showStateUpdate={showStateUpdate} validatedUpdateCargo={validatedUpdateCargo}
                    setShowStateUpdate={setShowStateUpdate} dependencia={dependencia} setDependencia={setDependencia}
                    listaDep={listaDep} setCargo={setCargo} cargo={cargo} setFechaToma={setFechaToma}
                    setFechaEntrega={setFechaEntrega} fechaEntrega={fechaEntrega} fechaToma={fechaToma}
                    codigoResponsableEntidad={codigoResponsableEntidad} codigoPersona={codigoPersona}
                />

                <UpdateSupervisor showSupervisor={showSupervisor} setShowSupervisor={setShowSupervisor}
                    handleSubmitSupervisor={handleSubmitSupervisor} pNombre={pNombre} listaSupervisores={listaSupervisores}
                    supervisor={supervisor} setSupervisor={setSupervisor} codigoPersona={codigoPersona}
                    setValidateUpdateSupervisor={setValidateUpdateSupervisor} validateUpdateSupervisor={validateUpdateSupervisor}
                />
             */}

                <UpdatePersona showPersona={showPersona} setShowPersona={setShowPersona} validatePersona={validatePersona}
                    setValidatePersona={setValidatePersona} handleSubmitPersona={handleSubmitPersona} setPNombre={setPNombre}
                    setSNombre={setSNombre} setTNombre={setTNombre} setPApellido={setPApellido} setSApellido={setSApellido}
                    setTApellido={setTApellido} setCorreo={setCorreo} setSexo={setSexo} setFechaNacimiento={setFechaNacimiento}
                    setShowState={setShowState} pNombre={pNombre} sNombre={sNombre} tNombre={tNombre} pApellido={pApellido}
                    sApellido={sApellido} tApellido={setTApellido} correo={correo} sexo={sexo} fechaNacimiento={fechaNacimiento}
                    setSupervisor={setSupervisor} supervisor={supervisor} puesto={puesto} setPuesto={setPuesto} setEstado={setEstado}
                    estado={estado} setDireccion={setDireccion} direccion={direccion} setFechaCreacion={setFechaCreacion} fechaCreacion={fechaCreacion}
                    setTipoDocumento={setTipoDocumento} tipoDocumento={tipoDocumento} setDocumento={setDocumento} documento={documento}
                />

                <PopUpBorrar showModal={displayConfirmationModal}
                    confirmModal={deletePersona} hideModal={hideConfirmationModal}
                    id={id} message={deleteMessage} />
            </div>
        </>
    );
}