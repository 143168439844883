import React, { useState, useEffect } from "react";
import {Table} from 'react-bootstrap';
import '../Dependencias/formulario.css'
import {TipoObjetivo} from './tipoObjetivo'
import UpdatedNavBar from "../NavBar/UpdatedNavBar";

// HTTP Requests
import Axios from 'axios';

export const TipoObjetivoView = () => {

    /**
     * States de tipos de objetivos
     */
    const [idTipObj, setIdTipObj] = useState('');
    const [descrTipObj, setDescrTipObj] = useState('');

    /**
     * nombre usuario
     */
    const [usuarioWeb, setUsuarioWeb] = useState('');

    useEffect(()=> {
        Axios.get("/api/getuserweb",
        {headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=> {
            setUsuarioWeb(response.data.usuarioWeb);
        });
    }, [])

    /**
     * Bandera para área de evaluación
     */
    const [areaFlag, setTipObjFlag] = useState(true);

    return(
        <>
            <UpdatedNavBar />
            <div className="container-dependencias">
                {
                    (areaFlag) ?
                    <TipoObjetivo
                        descrTipObj={descrTipObj} setDescrTipObj={setDescrTipObj} 
                        idTipObj={idTipObj} setIdTipObj={setIdTipObj}
                        setTipObjFlag={setTipObjFlag}
                        usuarioWeb={usuarioWeb}
                    /> : 
                    <>
                        <h2>Tipos de Objetivos</h2 >
                        <p className="lead blog-description">Tipos de objetivos de la organización</p>
                        
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Descripción</th>
                                </tr> 
                                <tr className="segunda-fila">
                                    <td>{idTipObj}</td>
                                    <td>{descrTipObj}</td>
                                </tr>
                            </thead>
                        </Table>
                    </>
                }
            </div>
        
        </>
    )

}
