import React, { useState, useEffect } from "react";
import { NavBar } from '../NavBarAdmin/navBar';
// HTTP Requests
import Axios from 'axios';
import { Button, Table } from "react-bootstrap";
import { Stack, Typography } from '@mui/material'
import { BsCheckCircle, BsXCircle } from "react-icons/bs";
import CrearCantidadUsuarios from "./CrearCantidadUsuarios";
import EditarCantidadUsuarios from "./EditarCantidadUsuarios";

function CantidadUsuarios() {

    const [instituciones, setInstituciones] = useState([])
    const [pendientes, setPendientes] = useState([])
    const [editar, setEditar] = useState(false)
    const [crear, setCrear] = useState(false)
    const [id, setId] = useState('')

    const handleEditarO = () => setEditar(true)
    const handleEditarC = () => setEditar(false)
    const handleCrearO = () => setCrear(true)
    const handleCrearC = () => setCrear(false)
    const handleId = (id) => setId(id)

    useEffect(() => {
        refresh()
    }, [])

    const refresh = () => {
        Axios.get('/instituciones/informacion/cantidades-vigencias',
            { headers: { "x-access-token": localStorage.getItem('tokenAdminSys') } })
            .then((response) => {
                if (response.data.err) { } else {
                    setInstituciones(response.data.instituciones)
                }
            })
    }

    const getDateFormat = (tmpDate) => {
        let firstDate = new Date(tmpDate);
        let options = { day: 'numeric', month: 'long', year: 'numeric', timeZone: "GMT" };
        return String(firstDate.toLocaleDateString('es-ES', options))
    }

    return (
        <>
            <NavBar />
            <div className="container-usuario-administracion">
                <Stack spacing={2}>
                    <Stack>
                        <Typography variant="h2">Control de usuarios</Typography>
                        <Typography variant="h4" color='lightgray'>Detalle de cuantos usuarios validos tiene cada institución</Typography>
                    </Stack>
                    <hr />
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Acciones</th>
                                <th>Institución</th>
                                <th>Cantidad</th>
                                <th>Vigencia</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                instituciones.map((institucion, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <Button variant="secondary"
                                                    onClick={() => {
                                                        handleEditarO()
                                                        handleId(institucion.CODIGO)
                                                    }}
                                                >
                                                    Editar
                                                </Button>
                                            </td>
                                            <td>
                                                {institucion.NOMBRE}
                                            </td>
                                            <td>
                                                {institucion.CANTIDAD}
                                            </td>
                                            <td>
                                                {(institucion.VIGENCIA) ? getDateFormat(institucion.VIGENCIA) : 'Sin definir'}
                                            </td>
                                            <td>
                                                {
                                                    String(institucion.ESTADO) === '1' ?
                                                        <p className="text-success"><BsCheckCircle />Habilitada</p> :
                                                        <p className="text-danger"><BsXCircle />Sin habilitar</p>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    <CrearCantidadUsuarios refresh={refresh} id={id} show={crear} handleClose={handleCrearC} handleId={handleId} />
                    <EditarCantidadUsuarios refresh={refresh} id={id} show={editar} handleClose={handleEditarC} handleId={handleId} />
                </Stack>
            </div>
        </>
    )

}

export default CantidadUsuarios