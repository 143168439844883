import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Table, InputGroup } from 'react-bootstrap';
import {toast} from 'react-toastify';
import { AiOutlinePlus, AiOutlineRollback, AiOutlineSearch, AiOutlineClockCircle, AiFillEdit, AiFillDelete } from 'react-icons/ai';
import {PeriodoEvaluacion} from './periodoEvaluacion'
import 'react-toastify/dist/ReactToastify.css'
import './formulario.css'


// HTTP Requests
import Axios from 'axios';

toast.configure()

export const AreaEvaluacion = (props) => {

    /**
     * States de área de evaluaciones
     */
    const [nombreArea, setNombreArea] = useState('');
    const [descArea, setDescArea] = useState('');
    const [siglasArea, setSiglasArea] = useState('');
    const [idArea, setIdArea] = useState('');

    /**
     * Lista de áreas
     */
    const [listaPrinArea, setListaPrinArea] = useState([]);
    const [listaArea, setListaArea] = useState([]);
    const [listaEliminar, setListaEliminar] = useState([]);

    /**
     * Modals de áreas
     */
    const [showState, setShowState] = useState(false);
    const [showStateUpdate, setShowStateUpdate] = useState(false);

    /**
     * Bandera para período de evaluación
     */
     const [periodoEvaluacion, setPeriodoEvaluacion] = useState(false);

    const refresh = (param) => {
        Axios.get("/api/get/area/"+String(param),
        {headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=> {
            setListaArea(response.data);
            setListaPrinArea(response.data);
        });
    }

    useEffect(() => {
        if(props.idDep){
            refresh(props.idDep)
        }
      }, [props.idDep]);

    useEffect(() => {
        Axios.get("/api/getareawithchildren/"+String(props.idDep),
        {headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=> {
            setListaEliminar(response.data);
        });
      }, [listaArea]);

      const busquedaArea = (listaInfo) => {
        const input1 = document.getElementById('input-1').value;
        const input2 = document.getElementById('input-2').value;
        const input3 = document.getElementById('input-3').value;
        let tmpArray = []
        if (String(input1) === '' && String(input2) === '' && String(input3) === ''){
            return listaInfo
        } else {
            const indexes = listaInfo.map(
                    (element, index) => {
                            let flag = false;

                            if (String(input1) !== ''){
                                if(element.NOMBRE.toLowerCase().includes(input1.toLowerCase())){
                                    flag = true;
                                } else {
                                    return -1
                                }
                            }

                            if (String(input2) !== ''){
                                if(element.DESCRIPCION.toLowerCase().includes(input2.toLowerCase())){
                                    flag = true;
                                } else {
                                    return -1
                                }
                            }

                            if (String(input3) !== ''){
                                if(element.SIGLAS.toLowerCase().includes(input3.toLowerCase())){
                                    flag = true;
                                } else {
                                    return -1
                                }
                            }

                            if(flag){
                                return index
                            }
                            
                        }
                    ).filter(value => value >= 0)
            for (let i of indexes) {
                tmpArray.push(listaInfo[i])
            }
            return tmpArray
        }
    }

    const submitArea = () => {
        if(props.nombreDep !== '' && props.siglasDep !== ''){
            Axios.post("/api/insert/area", {
                    nombreDep: nombreArea,
                    descrDep: descArea, 
                    siglasDep: siglasArea,
                    codigo_padre: props.idDep,
                    codigo_tipo_entidad: String(2),
                },{headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=>{
                    if(response.data.result){
                        setShowState(false);
                        refresh(props.idDep)
                    }else{
                        toast.error(response.data.error)
                    }
                });
        } else {
            toast.error('Ingrese al menos el nombre y siglas de la área de evaluación (8 caracteres máximo para las siglas)')
        }

    };

    const deleteArea = (codigo, nombre) => {
        Axios.delete('/api/delete/area/' + String(codigo), {headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=>{
            if (response.data.result === 'Error'){
                toast.error('Error al eliminar la dependencia: ' + nombre)
            } else {
                refresh(props.idDep)
            }
        });
    };

    const updateArea = () => {
        if(props.nombreDep !== '' && props.siglasDep !== ''){
            Axios.put('/api/update/area/'+String(idArea), {
            nombreDep: nombreArea,
            descrDep: descArea, 
            siglasDep: siglasArea,
            },{headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=>{
                if (response.data.result === 'Error'){
                toast.error('Error al actualizar la dependencia: ' + nombreArea)
                } else {
                    refresh(props.idDep)
                }
            });
        } else {
            toast.error('Ingrese al menos el nombre y siglas de la área de evaluación (8 caracteres máximo para las siglas)')
        }
    };

    const [validatedCreate, setValidatedCreate] = useState(false);

    const handleSubmitCreate = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            setValidatedCreate(true);
        } else{
            submitArea();
        }
        setValidatedCreate(true);
    };

    if (!periodoEvaluacion){
        return(
            <div>
                <div>
                    <Button 
                        size='sm' 
                        variant="outline-success"
                        className="boton-area-evaluacion" 
                        onClick={() => {
                            setShowState(true);
                        }}
                    >
                        <AiOutlinePlus/> Nueva área de evaluación
                    </Button>
                    <Button 
                        size='sm' 
                        variant="outline-warning" 
                        className="boton-area-evaluacion" 
                        onClick={() => {
                            props.setDependenciaFlag(true);
                        }}
                    >
                        <AiOutlineRollback/> Regresar a dependencias
                    </Button>
                </div>
                <p/>
                {/** 
                 * Primer Modal
                 * Este modal es para el formulario de una nueva dependencia
                */}
                <Modal show={showState} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Agregar un área de evaluacion
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form validated={validatedCreate} onSubmit={handleSubmitCreate}>
                            <Form.Group className="mb-3">
                                <Form.Label>Nombre del área de evaluacion</Form.Label>
                                <Form.Control 
                                    required 
                                    onChange={(e)=> {
                                        setNombreArea(e.target.value);
                                    }} 
                                    placeholder="Nombre del área" 
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Descripción del área de evaluacion</Form.Label>
                                <Form.Control
                                    onChange={(e)=> {
                                        setDescArea(e.target.value)
                                    }} 
                                    placeholder="Descripción del área de evaluación" 
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Siglas</Form.Label>
                                <Form.Control 
                                    required 
                                    onChange={(e)=> {
                                        setSiglasArea(e.target.value);
                                    }} 
                                    placeholder="Siglas" 
                                />
                            </Form.Group>
                            <hr/>
                            <Button 
                                type='submit'
                            >
                                Guardar
                            </Button>
                            <Button 
                                style={{marginLeft: '5px'}}
                                variant='secondary' 
                                onClick={()=>{
                                    setValidatedCreate(false); 
                                    setShowState(false);
                                }}
                            >
                                Cancelar
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Opción</th>
                            <th>Nombre</th>
                            <th>Descripción</th>
                            <th>Siglas</th>
                        </tr> 
                        <tr>
                            <td>Herramientas de busqueda</td>
                            <td>
                                <InputGroup className="search-input">
                                    <Button 
                                        variant="outline-secondary"
                                        onClick={()=>{
                                            setListaArea(busquedaArea(listaPrinArea));
                                        }}
                                    >
                                        <AiOutlineSearch/>
                                    </Button>
                                    <Form.Control
                                        onChange={()=>{
                                            setListaArea(busquedaArea(listaPrinArea));
                                        }}
                                        id="input-1"
                                        type="text"
                                        placeholder="busqueda por nombre"
                                    />
                                </InputGroup>
                            </td>
                            <td>
                                <InputGroup className="search-input">
                                    <Button 
                                        variant="outline-secondary"
                                        onClick={()=>{
                                            setListaArea(busquedaArea(listaPrinArea));
                                        }}
                                    >
                                        <AiOutlineSearch/>
                                    </Button>
                                    <Form.Control
                                        onChange={()=>{
                                            setListaArea(busquedaArea(listaPrinArea));
                                        }}
                                        id="input-2"
                                        type="text"
                                        placeholder="busqueda por descripción"
                                    />
                                </InputGroup>
                            </td>
                            <td>
                                <InputGroup className="search-input">
                                    <Button 
                                        variant="outline-secondary"
                                        onClick={()=>{
                                            setListaArea(busquedaArea(listaPrinArea));
                                        }}
                                    >
                                        <AiOutlineSearch/>
                                    </Button>
                                    <Form.Control
                                        onChange={()=>{
                                            setListaArea(busquedaArea(listaPrinArea));
                                        }}
                                        id="input-3"
                                        type="text"
                                        placeholder="busqueda por siglas"
                                    />
                                </InputGroup>
                            </td>
                        </tr> 
                    </thead>
                    <tbody>
                        {listaArea.map((value) => {
                            return (
                            <tr key={value.CODIGO}>
                                <td>
                                <Button className="primer-boton" size="sm" variant="warning" onClick={() => {
                                    setNombreArea(value.NOMBRE);
                                    setDescArea(value.DESCRIPCION);
                                    setSiglasArea(value.SIGLAS);
                                    setIdArea(value.CODIGO_AREA);
                                    setPeriodoEvaluacion(true);
                                    }}
                                >
                                    <AiOutlineClockCircle/> Período de evaluación
                                </Button>
                                <Button className="segundo-boton" size="sm" variant="info" onClick={() => {
                                    setNombreArea(value.NOMBRE);
                                    setDescArea(value.DESCRIPCION);
                                    setSiglasArea(value.SIGLAS); 
                                    setShowStateUpdate(true);
                                    setIdArea(value.CODIGO);
                                    }}>
                                        <AiFillEdit/>    Editar
                                </Button>
                                {
                                    (!(listaEliminar.find(Element => Element.CODIGO === value.CODIGO)) 
                                        && props.usuarioWeb === value.USUARIO_CREACION)?
                                    <Button className="segundo-boton" size="sm" variant="danger" onClick={() => {
                                        deleteArea(value.CODIGO, value.NOMBRE);
                                        }}>
                                        <AiFillDelete/>    Eliminar
                                    </Button> : 
                                    <></>
                                }
                                </td>
                                <td>{value.NOMBRE}</td>
                                <td>{value.DESCRIPCION}</td>
                                <td>{value.SIGLAS}</td>
                            </tr>
                            ); 
                        })}
                    </tbody>
                </Table>
                <Modal show={showStateUpdate} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Editar área de evaluación
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Nombre del área de evaluación</Form.Label>
                                <Form.Control 
                                    onChange={(e)=> {
                                        setNombreArea(e.target.value);
                                    }} 
                                    placeholder="Nombre del área" 
                                    defaultValue={nombreArea}/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Descripción del área de evaluación</Form.Label>
                                <Form.Control 
                                    onChange={(e)=> {
                                        setDescArea(e.target.value)
                                    }} 
                                    placeholder="Descripción de la dependencia" 
                                    defaultValue={descArea}/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Siglas</Form.Label>
                                <Form.Control
                                    disabled 
                                    onChange={(e)=> {
                                        setSiglasArea(e.target.value);
                                    }}
                                    placeholder="Siglas" 
                                    defaultValue={siglasArea}/>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button 
                            onClick={() => {
                                updateArea();
                                setShowStateUpdate(false);
                            }}
                        >
                            Guardar
                        </Button>
                        <Button variant='secondary' onClick={()=>{
                            setShowStateUpdate(false);
                            props.setNombreDep('');
                            props.setDescrDep('');
                            props.setSiglasDep(''); 
                            props.setIdArea('');}}>Cancelar</Button>
                    </Modal.Footer>
                </Modal>
            </div>
            );
    }   else {
        return(
            <div>
                <Button 
                    className="primer-boton" 
                    size="sm" variant="warning" 
                    onClick={() => {
                        setNombreArea('');
                        setDescArea('');
                        setSiglasArea('');
                        setIdArea('');
                        setPeriodoEvaluacion(false);
                        props.setDependenciaFlag(true);
                        }}
                > 
                    Regresar a dependencias
                </Button>
                <Table responsive>
                <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Descripción</th>
                            <th>Siglas</th>
                        </tr> 
                        <tr className="segunda-fila">
                            <td>{nombreArea}</td>
                            <td>{descArea}</td>
                            <td>{siglasArea}</td>
                        </tr>
                    </thead>
                </Table>
                {/**
                * Botón para crear nueva dependencia
                */}
                <legend className='ng-scope'>
                    Período de evaluación
                </legend>
                <hr/>
                <p/>
                <PeriodoEvaluacion setPeriodoEvaluacion={setPeriodoEvaluacion} idArea={idArea} usuarioWeb={props.usuarioWeb} />
            </div>
        );
    }
    

}