import React from "react";
import {Modal, Button, Form, Col, Row} from 'react-bootstrap';

export const UpdateCargo = (props) => {

    return(
        <Modal show={props.showStateUpdate} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Cargo de la persona
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={props.validatedUpdateCargo} onSubmit={props.handleSubmitUpdateCargo}>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                            <Form.Label>Dependencia</Form.Label>
                            <Form.Select 
                                defaultValue={props.dependencia} 
                                onChange={(e)=>
                                    props.setDependencia(e.target.value)
                                } 
                                required
                            >
                                <option>Seleccione una dependencia</option>
                                {props.listaDep.map((value) => {
                                    return(<option value={value.codigo} key={value.codigo} >{value.nombre}</option>)
                                })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                ¡Por favor, ingrese su dependencia!
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label>Cargo</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Cargo"
                                onChange={(e)=> {props.setCargo(e.target.value)}}
                                defaultValue={props.cargo}
                            />
                            <Form.Control.Feedback type="invalid">
                                ¡Por favor, ingrese el cargo!
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="6" controlId="validationCustom11">
                            <Form.Label>Fecha de toma de posesión</Form.Label>
                            <Form.Control 
                                type='date' 
                                onChange={(e)=> {
                                    props.setFechaToma(e.target.value);
                                }} 
                                defaultValue={props.fechaToma} 
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom03">
                            <Form.Label>Fecha de entrega de cargo</Form.Label>
                            <Form.Control 
                                type='date' 
                                defaultValue={props.fechaEntrega} 
                                onChange={(e)=> {
                                    props.setFechaEntrega(e.target.value);
                                }} 
                            />
                        </Form.Group>
                    </Row>
                    <hr/>
                    <Button type="submit">Guardar</Button>
                    <Button variant='secondary' className="segundo-btn" 
                        onClick={()=>{
                            props.setShowStateUpdate(false);
                            props.setValidatedUpdateCargo(false);
                        }}
                    >
                        Cancelar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}