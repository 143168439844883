export const backgroundPlugin = {
    id: 'backgroundPlugin',
    beforeDraw: (chart) => {
        const ctx = chart.ctx;
        const chartArea = chart.chartArea;
        const { top, left, width, height } = chartArea;

        const colors = [
            ['#3FA535', '#3FA535', '#FFED00', '#CD1719', '#CD1719'],
            ['#3FA535', '#3FA535', '#FFED00', '#CD1719', '#CD1719'],
            ['#3FA535', '#3FA535', '#3FA535', '#FFED00', '#FFED00'],
            ['#3FA535', '#3FA535', '#3FA535', '#3FA535', '#3FA535'],
            ['#3FA535', '#3FA535', '#3FA535', '#3FA535', '#3FA535']
        ];

        const values = [
            [5, 10, 15, 20, 25],
            [4, 8, 12, 16, 20],
            [3, 6, 9, 12, 15],
            [2, 4, 6, 8, 10],
            [1, 2, 3, 4, 5]
        ];

        // Ajustar el tamaño de los pasos para que coincidan con la cuadrícula de los puntos
        const stepX = width / 5;
        const stepY = height / 5;

        ctx.save();
        colors.forEach((row, rowIndex) => {
            row.forEach((color, colIndex) => {
                ctx.fillStyle = color;
                ctx.fillRect(left + colIndex * stepX, top + rowIndex * stepY, stepX, stepY);
                ctx.strokeStyle = '#000000';
                ctx.strokeRect(left + colIndex * stepX, top + rowIndex * stepY, stepX, stepY);

                ctx.fillStyle = '#000000';
                ctx.font = 'bold 14px Arial';
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.fillText(values[rowIndex][colIndex], left + colIndex * stepX + stepX / 2, top + rowIndex * stepY + stepY / 2);
            });
        });
        ctx.restore();
    }
};
