import React from 'react'
import { Button, Table } from 'react-bootstrap'

function TablaPlanesAccion(props) {

    const handleOpen = (plan) => {
        props.setRiesgoInfo({
            id: plan.corr,
            nombre: plan.riesgo
        })
        props.handleEditarO(plan.id)
    }

    return (
        <>
        <Table bordered striped hover responsive>
            <thead>
                <tr>
                    <th>
                        Acciones
                    </th>
                    <th style={{minWidth:'125px'}}>
                        Id riesgo
                    </th>
                    <th style={{minWidth:'200px'}}>
                        Riesgo
                    </th>
                    <th style={{minWidth:'200px'}}>
                        Plan de acción
                    </th>
                    <th style={{minWidth:'125px'}}>
                        Responsable
                    </th>
                    <th style={{minWidth:'125px'}}>
                        Delegado
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    props.listaRiesgos.map((plan, index) => {
                        return(
                            <tr key={index}>
                                <td>
                                    <Button variant='secondary' onClick={()=>{handleOpen(plan)}} >Editar</Button>
                                </td>
                                <td>
                                    {plan.corr}
                                </td>
                                <td>
                                    {plan.riesgo}
                                </td>
                                <td>
                                    {plan.metodo}
                                </td>
                                <td>
                                    {plan.responsable}
                                </td>
                                <td>
                                    {(plan.delegado)?plan.delegado:'Sin delegado'}
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </Table>
        </>
    )
}

export default TablaPlanesAccion