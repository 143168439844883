import React, { useState, useEffect } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { Grid, Stack, Typography } from '@mui/material'
import Axios from 'axios'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ModalEditarPlan(props) {

    const [validated, setValidated] = useState(false)
    const [nombre, setNombre] = useState('')
    const [frecuencia, setFrecuencia] = useState('')
    const [delegado, setDelegado] = useState('')
    const [responsable, setResponsable] = useState('')
    const [puesto, setPuesto] = useState('')

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            setValidated(true);
        }
    };

    useEffect(() => {
        if (props.id) {
            Axios.get('/api/get/plan-responsable-info', { params: { id: props.id }, headers: { "x-access-token": localStorage.getItem('token'), } })
                .then((response) => {
                    if (response.data.err) {
                        toast.error('Error al obtener lista de empleados')
                    } else {
                        setNombre(response.data.result[0].metodo)
                        setFrecuencia(response.data.result[0].frecuencia)
                        setDelegado(response.data.result[0].delegado)
                        setResponsable(response.data.result[0].codigo_persona)
                        setPuesto(response.data.result[0].puesto)
                    }
                })
        } else {
            setNombre('')
            setFrecuencia('')
            setDelegado('')
            setResponsable('')
            setPuesto('')
        }
    }, [props.id])

    const changePuesto = (e) => {
        setResponsable(e.target.value);
        Axios.get('/api/get/puesto-responsable', {
            params: { id: e.target.value },
            headers: { "x-access-token": localStorage.getItem('token') }
        }).then((response) => {
            if (response.data.result) { setPuesto(response.data.result) }
            else { setPuesto('') }
        })
    }

    const guardar = () => {
        Axios.post('/api/update/plan-responsable/personas', {
            id: props.id,
            responsable: responsable,
            puesto: puesto,
            delegado: delegado,
        }, { headers: { "x-access-token": localStorage.getItem('token') } })
            .then((response) => {
                if (response.data.err) {
                    toast.error('Error al actualizar.')
                } else {
                    toast.success('¡Exito!')
                    props.setCont(props.cont + 1)
                    setNombre('')
                    setFrecuencia('')
                    setDelegado('')
                    setResponsable('')
                    setPuesto('')
                    props.handleClose()
                }
            })
    }

    return (
        <Modal show={props.show} onHide={props.handleClose} centered size="xl">
            <Modal.Header>
                <Modal.Title>
                    {(props.riesgoInfo.id) ? props.riesgoInfo.id : ''} - {(props.riesgoInfo.nombre) ? props.riesgoInfo.nombre : ''}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Stack spacing={2}>
                    <Typography variant='span' color='black'>Método: {nombre}.</Typography>
                    <Typography variant='span' color='black'>Frecuencia: {frecuencia}.</Typography>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Grid container spacing={2} >
                            <Grid item xs={12} lg={4}>
                                <Form.Group>
                                    <Form.Label>
                                        Responsable
                                    </Form.Label>
                                    <Form.Select value={responsable} onChange={(e) => { changePuesto(e) }}>
                                        <option value={''}>Sin definir</option>
                                        {
                                            props.personas.map((persona, index) => {
                                                return (
                                                    (String(persona.id) !== String(delegado)) &&
                                                    <option value={persona.id} key={index}>
                                                        {persona.nombre}
                                                    </option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <Form.Group>
                                    <Form.Label>
                                        Puesto responsable
                                    </Form.Label>
                                    <Form.Control value={puesto} onChange={(e) => setPuesto(e.target.value)} />
                                </Form.Group>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <Form.Group>
                                    <Form.Label>
                                        Delegado
                                    </Form.Label>
                                    <Form.Select value={delegado} onChange={(e) => setDelegado(e.target.value)}>
                                        <option value={''}>Sin definir</option>
                                        {
                                            props.personas.map((persona, index) => {
                                                return (
                                                    (String(persona.id) !== String(responsable)) &&
                                                    <option value={persona.id} key={index}>
                                                        {persona.nombre}
                                                    </option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Grid>
                        </Grid>
                    </Form>
                    <Stack direction={'row'}>
                        <Button variant="success" onClick={guardar}>
                            Guardar
                        </Button>
                        <Button variant="secondary" onClick={props.handleClose}>
                            Cancelar
                        </Button>
                    </Stack>
                </Stack>

            </Modal.Body>
        </Modal>
    )
}

export default ModalEditarPlan