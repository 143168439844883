// HTTP Requests
import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Modal, Button, Table } from 'react-bootstrap'
import Subtema from './Subtema';

function ModalDetalles(props) {

    const [lista, setLista] = useState([])
    const [subtema, setSubtema] = useState(false)
    const [params, setParams] = useState({
        metodo: null,
        responsable: null,
        frecuencia: null,
        id: null
    })

    const refresh = (code) => {
        Axios.get('/api/get/cantidadmetodospendientesdetalle', {params: {id: code}, headers:{"x-access-token": localStorage.getItem('token')}})
        .then((response)=>{
            setLista(response.data.result)
        })
    }
        
    useEffect(() => {
        refresh(props.codigo)
    }, [props.codigo])

    const cerrar = () => {
        props.setCodigo('')
        props.handleClose()
        setSubtema(false)
        setLista([])
        props.searchRiesgo(props.periodo)
    }

    return (
        <Modal centered size='xl' show={props.show} onHide={cerrar}>
            <Modal.Header>
                <Modal.Title>Detalles de riesgo {props.titulo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    (subtema)?
                    <Subtema params={params} setSubtema={setSubtema} refresh={refresh} id={props.codigo} />
                    :
                    <>
                    {
                        (lista.length === 0)?
                        <></>:
                        <>
                        <Table bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Método</th>
                                    <th>Responsable</th>
                                    <th>Frecuencia</th>
                                    <th>Subtema</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    lista.map((plan, indice) => {
                                        return(
                                            <tr key={indice} >
                                                <td>{plan.METODO}</td>
                                                <td>{plan.personanombre}</td>
                                                <td>{plan.NOMBRE}</td>
                                                <td>
                                                    {(plan.SUBTEMA)?plan.SUBTEMA:'Sin definir'}<p/>
                                                    <Button variant='info' 
                                                    onClick={()=>{
                                                        setParams({
                                                            metodo: plan.METODO,
                                                            responsable: plan.personanombre,
                                                            frecuencia: plan.NOMBRE,
                                                            id: plan.id_plan_responsable,
                                                            p: plan.probabilidad,
                                                            s: plan.severidad,
                                                            c: plan.COMENTARIO,
                                                            d: plan.SUBTEMA
                                                        })
                                                        setSubtema(true)
                                                    }} > 
                                                    Agregar/editar 
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                        </>
                    }
                    </>
                }
                <hr />
                <Button variant="secondary" onClick={cerrar}>
                    Cerrar
                </Button>
            </Modal.Body>
      </Modal>
    )
}

export default ModalDetalles