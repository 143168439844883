import React, { useState, useEffect } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import Axios from 'axios';

function SearchPeriodo(props) {
    const [listaPeriodo, setListaPeriodo] = useState([]);

    useEffect(() => {
        // Obtiene los períodos de la empresa
        Axios.get("/api/get/periodos", { headers: { "x-access-token": localStorage.getItem('token') } })
            .then((response) => {
                setListaPeriodo(response.data);
            });
    }, []);

    return (
        <>
            <Row className="mb-3">
                <Form.Group as={Col} md="2">
                    <Form.Label><b></b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label><b>Período de evaluación</b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Select onChange={(e) => {
                        if (e.target.value !== '') {
                            const selectedOption = e.target.selectedOptions[0];
                            props.setPeriodo(e.target.value, selectedOption.getAttribute('data-description'));
                        }
                    }}>
                        <option value=''></option>
                        {listaPeriodo.map((value) => (
                            <option value={value.CODIGO_PERIODO} key={value.CODIGO_PERIODO} data-description={`Del ${value.FECINI} al ${value.FECFIN}`}>
                                Del {value.FECINI} al {value.FECFIN} de {value.CODIGO_PERIODO}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Row>
        </>
    );
}

export default SearchPeriodo;
