import React, { useState, useEffect, useRef } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, Tooltip, Legend, Title } from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import { backgroundPlugin } from './ChartBackgroundPlugin';
import html2canvas from 'html2canvas';
import axios from 'axios';
import { toast } from 'react-toastify';

ChartJS.register(CategoryScale, LinearScale, PointElement, Tooltip, Legend, Title, backgroundPlugin);

const HeatMapChart = ({ data, title, tipoObjetivo, codigoPeriodo, riesgoTipo, isIndividual }) => {
    const [bubbleSize, setBubbleSize] = useState(10); // Estado local para el tamaño de las burbujas
    const [alertVisible, setAlertVisible] = useState(false);
    const chartRef = useRef(null);

    const handleBubbleSizeChange = (event) => {
        const newSize = Number(event.target.value);
        setBubbleSize(newSize);
    };

    useEffect(() => {
        // Calcular la distancia mínima permitida entre los puntos
        const minDistance = bubbleSize / 100; // Ajustar este valor según sea necesario
        let pointsCanTouch = false;

        Object.values(groupedData).forEach(group => {
            for (let i = 0; i < group.length; i++) {
                for (let j = i + 1; j < group.length; j++) {
                    const dx = (group[i].x - group[j].x);
                    const dy = (group[i].y - group[j].y);
                    const distance = Math.sqrt(dx * dx + dy * dy);
                    if (distance < (bubbleSize / 100)) { // Calcular el mínimo permitido basado en el tamaño de la burbuja
                        pointsCanTouch = true;
                    }
                }
            }
        });

        setAlertVisible(pointsCanTouch);
    }, [bubbleSize, data]);

    const generateOffsets = (count) => {
        const offsets = [];
        const step = 1 / (count + 1);
        for (let i = 1; i <= count; i++) {
            offsets.push(i * step - 0.5);
        }
        return offsets;
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const item = context.raw;
                        return [
                            `Riesgo: ${item.description}`,
                            `Probabilidad: ${item.y}`,
                            `Severidad: ${item.x}`,
                            `Inherente: ${item.riesgo_inherente}`,
                            `Residual: ${item.riesgo_residual}`,
                            `Probabilidad Inherente: ${item.probabilidad_inherente}`,
                            `Severidad Inherente: ${item.severidad_inherente}`,
                            `Valor Mitigación: ${item.valor_mitigacion}`,
                            `Responsable: ${item.responsable}`,
                            `Código Periodo: ${codigoPeriodo}`
                        ];
                    }
                }
            },
            title: {
                display: true,
                text: riesgoTipo === 'inherente' ? 'Mapa de Calor Riesgo Inherente' : 'Mapa de Calor Riesgo Residual'
            },
            backgroundPlugin: true
        },
        scales: {
            x: {
                type: 'linear',
                position: 'bottom',
                title: {
                    display: true,
                    text: 'Severidad'
                },
                min: 0,
                max: 5,
                ticks: {
                    stepSize: 1,
                    callback: function (value) {
                        return value; // Mostrar valores de 0 a 5
                    }
                }
            },
            y: {
                type: 'linear',
                title: {
                    display: true,
                    text: 'Probabilidad'
                },
                min: 0,
                max: 5,
                ticks: {
                    stepSize: 1,
                    callback: function (value) {
                        return value; // Mostrar valores de 0 a 5
                    }
                }
            }
        }
    };

    const groupedData = data.reduce((acc, item) => {
        const key = `${riesgoTipo === 'inherente' ? item.VALOR_SEVERIDAD : item.SEVERIDAD_INHERENTE}-${riesgoTipo === 'inherente' ? item.VALOR_PROBABILIDAD : item.PROBABILIDAD_INHERENTE}`;
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(item);
        return acc;
    }, {});

    const chartData = {
        datasets: Object.values(groupedData).flatMap(group => {
            const offsets = generateOffsets(group.length);
            return group.map((item, index) => {
                const offsetX = offsets[index];
                const offsetY = offsets[(index + Math.floor(group.length / 2)) % offsets.length];
                const point = {
                    x: riesgoTipo === 'inherente' ? item.VALOR_SEVERIDAD - 0.5 + offsetX : item.SEVERIDAD_INHERENTE - 0.5 + offsetX,
                    y: riesgoTipo === 'inherente' ? item.VALOR_PROBABILIDAD - 0.5 + offsetY : item.PROBABILIDAD_INHERENTE - 0.5 + offsetY,
                    r: bubbleSize, // Tamaño de la burbuja controlado por el estado
                    description: `${tipoObjetivo}-${item.CODIGO_RIESGO}`,
                    riesgo_inherente: item.RIESGO_INHERENTE,
                    riesgo_residual: item.RIESGO_RESIDUAL,
                    probabilidad_inherente: item.PROBABILIDAD_INHERENTE,
                    severidad_inherente: item.SEVERIDAD_INHERENTE,
                    valor_mitigacion: item.VALORMITIGACION,
                    responsable: item.RESPONSABLE,
                    codigo_periodo: codigoPeriodo // Usa codigoPeriodo directamente
                };
                console.log(`Coordenadas del punto (Riesgo ${item.CODIGO_RIESGO}):`, point);
                return {
                    label: point.description,
                    data: [point],
                    backgroundColor: '#FFFFFF', // Color del punto
                    borderColor: 'black',
                    borderWidth: 1,
                    hoverBackgroundColor: '#FFFFFF',
                    hoverBorderColor: 'black'
                };
            });
        })
    };

    const handleSendToJasper = async () => {
        if (chartRef.current) {
            const canvas = chartRef.current.canvas;
            const canvasImage = await html2canvas(canvas);
            const imgData = canvasImage.toDataURL('image/png');

            // Eliminar el encabezado "data:image/png;base64,"
            const base64Image = imgData.replace(/^data:image\/png;base64,/, '');

            // Enviar la imagen y otros datos al servidor
            try {
                toast.info(`Generando reporte`);
                await axios.post('/api/get/generar-MapaCalor', {
                    image: base64Image,
                    codigoPeriodo: codigoPeriodo,
                    riesgoTipo: riesgoTipo,
                    codigoArea: data[0].CODIGO_AREA,
                    codigoRiesgo: isIndividual ? data[0].CODIGO_RIESGO : null
                },
                {
                    headers: { "x-access-token": localStorage.getItem("token") },
                    responseType: 'blob',
                }).then((response) => {
                    if (response.headers['content-length'] === '0') {
                        toast.error('Ha ocurrido un error al generar su reporte, se ha enviado un correo a soporte. Tendrá pronta respuesta, gracias.');
                    } else {
                        const file = new Blob([response.data], { type: 'application/pdf' });
                        const fileURL = URL.createObjectURL(file);
                        const link = document.createElement('a');
                        link.href = fileURL;
                        link.setAttribute('download', `mapa de calor.pdf`);
                        document.body.appendChild(link);
                        link.click();
                        window.open(fileURL);
                        link.parentNode.removeChild(link);
                    }
                });
            } catch (error) {
                console.error('Error enviando la gráfica a Jasper:', error);
                alert('Error enviando la gráfica a Jasper');
            }
            console.log("Enviando imagen sin encabezado: ", base64Image);
            console.log("Código Periodo: ", codigoPeriodo);
        }
    };

    return (
        <div style={{ width: '100%', height: '600px' }}>
            {alertVisible && <div style={{ color: 'red', marginBottom: '10px' }}>¡Los puntos pueden tocarse! ¿Desea continuar?</div>}
            <input
                type="range"
                min="1"
                max="50"
                value={bubbleSize}
                onChange={handleBubbleSizeChange}
                style={{ marginBottom: '20px' }}
            />
            <Bubble ref={chartRef} data={chartData} options={options} plugins={[{
                id: 'labelPlugin',
                afterDraw: (chart) => {
                    const ctx = chart.ctx;
                    chart.data.datasets.forEach((dataset, i) => {
                        const meta = chart.getDatasetMeta(i);
                        meta.data.forEach((element, index) => {
                            // Dibujar el texto en el punto
                            ctx.fillStyle = 'black'; // Color de la letra
                            const fontSize = 12;
                            const fontStyle = 'normal';
                            const fontFamily = 'Helvetica Neue';
                            ctx.font = `${fontSize}px ${fontStyle} ${fontFamily}`;
                            const dataString = dataset.data[index].description;

                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'middle';
                            const position = element.tooltipPosition();
                            ctx.fillText(dataString, position.x, position.y);
                        });
                    });
                }
            }]} />
            {riesgoTipo === 'inherente' && !isIndividual && (
                <button onClick={handleSendToJasper}
                    size='sm'
                    style={{
                        backgroundColor: '#28a745',
                        borderColor: '#28a745',
                        color: 'white',
                        padding: '0.25rem 0.5rem',
                        fontSize: '0.8rem'
                    }}
                    variant="success"
                    className="nueva-dependencia">
                    Reporte Mapa de Riesgos Inherente
                </button>
            )}
            {riesgoTipo === 'residual' && (
                <button onClick={handleSendToJasper}
                    size='sm'
                    style={{
                        backgroundColor: '#28a745',
                        borderColor: '#28a745',
                        color: 'white',
                        padding: '0.25rem 0.5rem',
                        fontSize: '0.8rem'
                    }}
                    variant="success"
                    className="nueva-dependencia">
                    Reporte Mapa de Riesgos Residual
                </button>
            )}
            {isIndividual && riesgoTipo === 'inherente' && (
                <button onClick={handleSendToJasper}
                    size='sm'
                    style={{
                        backgroundColor: '#28a745',
                        borderColor: '#28a745',
                        color: 'white',
                        padding: '0.25rem 0.5rem',
                        fontSize: '0.8rem'
                    }}
                    variant="success"
                    className="nueva-dependencia">
                    Reporte Mapa de Calor Inherente Individual
                </button>
            )}
        </div>
    );
};

export default HeatMapChart;
