import React, { useState, useEffect } from 'react'
import { Button, Table, Form, Col, Row } from 'react-bootstrap'
import { SuggestionsInput } from '../../Components/suggestionsInput';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Axios from 'axios';

function Subtema(props) {

    const regresar = () => props.setSubtema(false)
    const [descripcion, setDescripcion] = useState('')
    const [validated, setValidated] = useState(false);
    const [suggestions, setSuggestions] = useState([])
    const [probabilidad, setProbabilidad] = useState('')
    const [severidad, setSeveridad] = useState('')
    const [comentario, setComentario] = useState('')

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            setValidated(true)
        } else {
            sendData()
        }
        setValidated(true);
    };

    const sendData = () => {
        Axios.post('/api/update/pt', {subtema: descripcion, p: probabilidad, s: severidad, c: comentario, id: props.params.id},
        {headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=> {
            if(response.data.result){
                toast.success('Exito en asignar subtema')
                props.refresh(props.id)
                props.setSubtema(false)
            } else {
                toast.error('Error al asignar subtema')
            }
        })
    }

    useEffect(()=>{
        Axios.get('/api/get/eventosparametodos', {params: {id: props.params.id}, 
        headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=> {
            setSuggestions(response.data.result.map((sugg) => {return sugg.DESCRIPCION}))
        }) 
        if(props.params.c){
            setComentario(props.params.c)
        }
        if(props.params.s){
            setSeveridad(props.params.s)
        }
        if(props.params.p){
            setProbabilidad(props.params.p)
        }
        if(props.params.d){
            setDescripcion(props.params.d)
        }
    }, [props.params])

    return (
        <>
        <p />
        <Table bordered hover responsive>
            <thead>
                <tr>
                    <th>
                        Método
                    </th>
                    <th>
                        Responsable
                    </th>
                    <th>
                        Frecuencia
                    </th>
                </tr>
            </thead>
            <tbody >
                <tr>
                    <td>{props.params.metodo}</td>
                    <td>{props.params.responsable}</td>
                    <td>{props.params.frecuencia}</td>
                </tr>
            </tbody>
        </Table>
        <p />
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>Sub tema</Form.Label>
                    <SuggestionsInput required={true} input={descripcion} setInput={setDescripcion} suggestions={suggestions} />
                    <Form.Control.Feedback type="invalid">
                        ¡Ingrese sub tema, por favor!
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom02">
                    <Form.Label>Probabilidad</Form.Label>
                    <Form.Select required value={probabilidad} onChange={(e)=>{setProbabilidad(e.target.value)}} >
                        <option value='' >Seleccione la probabilidad</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        ¡Ingrese la nueva probabilidad, por favor!
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                    <Form.Label>Severidad</Form.Label>
                    <Form.Select required value={severidad} onChange={(e)=>{setSeveridad(e.target.value)}} >
                        <option value='' >Seleccione la severidad</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        ¡Ingrese la nueva severidad, por favor!
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="validationCustom01">
                    <Form.Label>Ingrese comentario (opcional)</Form.Label>
                    <Form.Control type="text" placeholder="Comentario" 
                    value={comentario} onChange={(e)=>{setComentario(e.target.value)}} />
                </Form.Group>
            </Row>
            <Button variant='success' type="submit">Guardar cambios</Button>
            <Button variant='secondary' onClick={regresar} >Regresar a métodos</Button>
        </Form>
        </>
    )
}

export default Subtema