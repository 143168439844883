import React, { useState } from 'react'
import { Form, Col, Row, Button, InputGroup } from 'react-bootstrap';
import { Box } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from 'axios';

function NuevosRoles(props) {

    const [fi, setFi] = useState('')
    const [ff, setFf] = useState('')
    const [validated, setValidated] = useState(false)
    const [roles, setRoles] = useState([])

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            setValidated(true);
        } else {
            Axios.post('/api/get/adminusuariocrearrol', {
                fi: fi,
                ff: ff,
                roles: roles,
                id: props.id
            },
            {headers:{"x-access-token": localStorage.getItem('token')}})
            .then((response)=>{
                if(response.data.result){
                    toast.success('Se ha editado con exito.')
                    props.refresh()
                    props.cerrarCrear()
                } else {
                    toast.error('Ha habido un error al crear estos roles.')
                }
            })
        }
        setValidated(true);
    };

    return (
        <Box borderRadius={3} border={1} p={2} >
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                        <Form.Label>Roles</Form.Label>
                        <InputGroup >
                            <Button variant='outline-secondary' onClick={()=>{
                                        let newArray = []
                                        props.roles.forEach((value) => {
                                            newArray.push(value.CODIGO_ROL);
                                        })
                                        setRoles(newArray);
                                    }}
                            >
                                SELECCIONAR TODOS
                            </Button>
                            <Form.Select multiple value={roles} required
                                    onChange={(e)=>{
                                        let value = Array.from(e.target.selectedOptions, option => option.value);
                                        setRoles(value);
                                    }}>
                                {
                                    props.roles.map((rol,index)=>{
                                        return(
                                            <option key={rol+index} value={rol.CODIGO_ROL}>{rol.NOMBRE}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                            Ingrese fecha de inicio de vigencia.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                        <Form.Label>Fecha de inicio de vigencia</Form.Label>
                        <Form.Control value={fi} type="date" required onChange={(e)=>setFi(e.target.value)}/>
                        <Form.Control.Feedback type="invalid">
                            Ingrese fecha de inicio de vigencia.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom04">
                        <Form.Label>Fecha de fin de vigencia</Form.Label>
                        <Form.Control value={ff} type="date" required onChange={(e)=>setFf(e.target.value)} />
                        <Form.Control.Feedback type="invalid">
                            Ingrese fecha de fin de vigencia.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <p/>
                <Button variant='success' size='small' type="submit">Guardar</Button>
                <Button variant='secondary' size='small' onClick={props.cerrarEditar}>Cancelar</Button>
            </Form>
        </Box>
    )
}

export default NuevosRoles