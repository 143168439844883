import {
    AppBar, styled, Toolbar, Typography, Box, Avatar, Divider,
    Menu, MenuItem, Button, Link, IconButton
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { MdDehaze, MdSettings } from 'react-icons/md';
import Axios from 'axios';
import logoSis from '../../images/logo.png'
import AcercaDeModal from '../../Components/acercaDe';

const StyledToolbar = styled(Toolbar)({
    display: "flex",
    justifyContent: "space-between"
});

const Icons = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
}));

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#212529'),
    backgroundColor: '#212529',
    '&:hover': {
        backgroundColor: '#212529',
    },
}));

const UserBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
}));

const themeOptions = [
    { name: 'dark', label: 'Tema Claro', color: '#BDC3C7' },
    { name: 'light', label: 'Tema Oscuro', color: '#3498DB' },
    { name: 'red', label: 'Tema Rojo', color: '#C0392B' },
    { name: 'green', label: 'Tema Verde', color: '#27AE60' },
    { name: 'blue', label: 'Tema Azul', color: '#2980B9' }
];

const UpdatedNavBar = (props) => {
    const [roles, setRoles] = useState([]);
    const [openR, setOpenR] = useState(false);
    const [openL, setOpenL] = useState(false);
    const [openSettings, setOpenSettings] = useState(false);
    const [nombre, setNombre] = useState('');
    const [theme, setTheme] = useState('dark');
    const [logo, setLogo] = useState('');

    function getPosition(string, subString, index) {
        return string.split(subString, index).join(subString).length;
    }

    /*modal del popup borrar */
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

    // Handle the displaying of the modal based on type and id
    const showAcercaDeModal = (id, nombre) => {
        setDisplayConfirmationModal(true);
    };

    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    const logOut = () => {
        localStorage.clear();
        window.location = String(window.location.href).substring(0, getPosition(window.location.href, '/', 3));
    }

    useEffect(() => {
        Axios.get('/api/various/getRoles', { headers: { "x-access-token": localStorage.getItem('token') } }).then((response) => {
            if (response.data.auth === false) {
                localStorage.clear();
                window.location = String(window.location.href).substring(0, getPosition(window.location.href, '/', 3));
            } else {
                setRoles(response.data[0].roles);
                setNombre(response.data[0].nombre);
                const path = String(window.location.href).substring(getPosition(window.location.href, '/', 3));
                if (path === '/' || (response.data[0].roles.find(value => value.URL === path) || path !== 'matrizcontinuidad')) { } else {
                    window.location = String(window.location.href).substring(0, getPosition(window.location.href, '/', 3));
                }
            }
        });


        setLogo(logoSis);
        Axios.get('/api/get/image-url', { headers: { "x-access-token": localStorage.getItem('token') } })
            .then((response) => {
                //setLogo('https://monitorderiesgo.com/Pictures/' + response.data.result[0].path)
                setLogo('http://localhost:3001/Pictures/' + response.data.result[0].path)

            })
            .catch((error) => {
                console.error('Error fetching logo:', error);
            });
    }, []);

    useEffect(() => {
        document.body.setAttribute('data-theme', theme);
    }, [theme]);

    const groupedRoles = roles.reduce((acc, value) => {
        let group;
        switch (value.NIVEL) {
            case 1:
                group = "Seguridad";
                break;
            case 2:
                group = "Configuración";
                break;
            case 3:
                group = "Procesos";
                break;
            case 4:
                group = "Reportes";
                break;
            default:
                group = "Otros"; // Por si hay algún nivel no especificado
                break;
        }

        if (!acc[group]) {
            acc[group] = [];
        }
        acc[group].push(value);
        return acc;
    }, {});

    return (
        <AppBar position='sticky' sx={{ background: '#212529' }} style={{zIndex: 0}}>
            <StyledToolbar>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {logo && <img src={logo} alt="Logo" style={{ width: '40px', marginRight: '10px' }} />}
                    <IconButton sx={{ color: 'white' }} onClick={e => setOpenL(true)}>
                        <MdDehaze style={{ fontSize: '1.5rem' }} />
                        <font size="3">
                            Menú
                        </font>
                    </IconButton>
                    <Menu aria-labelledby="demo-positioned-button" open={openL}
                        onClose={e => setOpenL(false)}
                        anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                        sx={{ display: { xs: "block" } }}
                    >
                        {Object.keys(groupedRoles).map((group) => (
                            <div key={group}>
                                <MenuItem style={{backgroundColor: '#ECF0F1'}}>{group}</MenuItem> {/* Título del grupo */}
                                {groupedRoles[group].map((value, index) => (
                                    <MenuItem sx={{ pl:4}} key={index} component={Link} href={value.URL} >{value.ETIQUETA}</MenuItem>
                                ))}
                            </div>
                        ))}
                    </Menu>
                </Box>
                <ColorButton href="/">
                    <Typography variant="h6" color={'white'} >
                        Gestión de riesgos
                    </Typography>
                </ColorButton>
                <Icons>
                    <UserBox onClick={e => setOpenR(true)}>
                        <Avatar sx={{ width: '30px', height: '30px' }} src='' />
                        <Typography variant='span'>{nombre}</Typography>
                    </UserBox>
                    <IconButton onClick={e => setOpenSettings(true)} sx={{ marginLeft: 'auto' }}>
                        <MdSettings style={{ color: 'white' }} />
                    </IconButton>
                </Icons>
                <Menu aria-labelledby="demo-positioned-button" open={openR}
                    onClose={e => setOpenR(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MenuItem component={Link} href={'/Perfil'}>Perfil</MenuItem>
                    <MenuItem component={Link} onClick={() => showAcercaDeModal()}>Acerca de...</MenuItem>
                    <Divider />
                    <MenuItem onClick={() => logOut()}>Cerrar sesión</MenuItem>
                </Menu>
                <Menu aria-labelledby="settings-button" open={openSettings}
                    onClose={e => setOpenSettings(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    {
                        themeOptions.map((option, index) => (
                            <MenuItem key={index} onClick={() => setTheme(option.name)} className="theme-indicator">
                                {option.label}
                                <Box className="theme-dot" sx={{ backgroundColor: option.color }}></Box>
                            </MenuItem>
                        ))
                    }
                </Menu>
            </StyledToolbar>

            <AcercaDeModal showModal={displayConfirmationModal} hideModal={hideConfirmationModal} />
        </AppBar>
    );
}

export default UpdatedNavBar;
