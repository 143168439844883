import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import PersonaResponsableComboBox from '../PersonaResponsableComboBox';

const CrearSubevento = ({ show, handleClose, handleSave, riesgo }) => {
    const [subevento, setSubevento] = useState({
        subTema: '',
        codigoTolerancia: '',
        metodoMonitoreo: '',
        frecuenciaMonitoreo: '',
        codigoResponsable: '',
        codigoSeveridad: '',
        controlImpl: '',
        recursos: '',
        fechaInicio: '',
        fechaFinal: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSubevento({ ...subevento, [name]: value });
    };

    const handleResponsableChange = (value) => {
        setSubevento({ ...subevento, codigoResponsable: value });
    };

    const onSave = () => {
        handleSave({
            ...subevento,
            codigoCia: riesgo.CODIGO_CIA,
            codigoPeriodo: riesgo.CODIGO_PERIODO,
            codigoTipoObjetivo: riesgo.CODIGO_TIPO_OBJETIVO,
            codigoObjetivo: riesgo.CODIGO_OBJETIVO,
            codigoArea: riesgo.CODIGO_AREA,
            codigoRiesgo: riesgo.CODIGO_RIESGO
        });
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Crear Subevento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Form.Group controlId="subTema">
                                <Form.Label>Sub Tema</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="subTema"
                                    value={subevento.subTema}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="codigoTolerancia">
                                <Form.Label>Nivel de Tolerancia</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="codigoTolerancia"
                                    value={subevento.codigoTolerancia}
                                    onChange={handleChange}
                                >
                                    <option value="">Seleccione...</option>
                                    <option value="1">No Tolerable</option>
                                    <option value="2">Gestionable</option>
                                    <option value="3">Tolerable</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="metodoMonitoreo">
                                <Form.Label>Método Monitoreo</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="metodoMonitoreo"
                                    value={subevento.metodoMonitoreo}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="frecuenciaMonitoreo">
                                <Form.Label>Frecuencia Monitoreo</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="frecuenciaMonitoreo"
                                    value={subevento.frecuenciaMonitoreo}
                                    onChange={handleChange}
                                >
                                    <option value="">Seleccione...</option>
                                    <option value="Mensual">Mensual</option>
                                    <option value="Quincenal">Quincenal</option>
                                    <option value="Diaria">Diaria</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="codigoResponsable">
                                <Form.Label>Responsable</Form.Label>
                                <PersonaResponsableComboBox 
                                    value={subevento.codigoResponsable} 
                                    onChange={handleResponsableChange} 
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="codigoSeveridad">
                                <Form.Label>Severidad del Riesgo</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="codigoSeveridad"
                                    value={subevento.codigoSeveridad}
                                    onChange={handleChange}
                                >
                                    <option value="">Seleccione...</option>
                                    <option value="1">Alta</option>
                                    <option value="2">Media</option>
                                    <option value="3">Baja</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="controlImpl">
                                <Form.Label>Control Implementado</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="controlImpl"
                                    value={subevento.controlImpl}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="recursos">
                                <Form.Label>Recursos</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="recursos"
                                    value={subevento.recursos}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="fechaInicio">
                                <Form.Label>Fecha Inicio</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="fechaInicio"
                                    value={subevento.fechaInicio}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="fechaFinal">
                                <Form.Label>Fecha Final</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="fechaFinal"
                                    value={subevento.fechaFinal}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={onSave}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CrearSubevento;
