import React, { useState, useEffect } from 'react'
import { Form, Col, Row } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css'
// HTTP Requests
import Axios from 'axios';

function SearchObjetivo(props) {

    const [listaTipObj, setListaTipObj] = useState([]);

    const getDateFormat = (tmpDate) => {
        let firstDate = new Date(tmpDate);
        let options = { day: 'numeric', month: 'long', year: 'numeric', timeZone: "GMT" };
        return String(firstDate.toLocaleDateString('es-ES', options))
    }

    useEffect(() => {
        //obtiene los períodos de la empresa
        Axios.get("/api/get/periodos", { headers: { "x-access-token": localStorage.getItem('token') } })
            .then((response) => {
                console.log('token: ', localStorage.getItem('token'))
            });

        //obtiene los tipos de objetivos de la empresa
        Axios.get("/api/get/tiposObjetivos", { headers: { "x-access-token": localStorage.getItem('token') } })
            .then((response) => {

                console.log(response.data);
                setListaTipObj(response.data);
            });
    }, [])

    return (
        <>
            <Row className="mb-3">
                <Form.Group as={Col} md="2">
                    <Form.Label><b></b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label><b>Tipo de Objetivo</b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Select onChange={
                        (e) => {
                            if (e.target.value !== '') {
                                props.setTipObj(e.target.value)
                            }
                        }
                    } >
                        <option value=''></option>
                        {listaTipObj.map((value) => {
                            return (<option value={value.CODIGO} key={value.CODIGO} >{value.DESCRIPCION}</option>)
                        })}
                    </Form.Select>
                </Form.Group>
            </Row>
        </>
    )
}

export default SearchObjetivo