import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button } from 'react-bootstrap';
import '../../../../styles/RiesgoMonitorStyle.scss';
import BarraProgreso2 from '../../../../Components/BarraProgreso2'
import { AiFillEdit, AiFillDelete } from 'react-icons/ai';
import { toast } from 'react-toastify';
import PopUpBorrar from '../../../../Components/popUpBorrar'

const RiesgosMonitoreo = ({ codigoCia, codigoRiesgo, onSave }) => {
    const [data, setData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.get('/api/historialMonitoreo', {
                headers: { "x-access-token": localStorage.getItem('token') },
                params: {
                    codigoCia,
                    codigoRiesgo
                }
            });
            if (Array.isArray(response.data)) {
                setData(response.data);
            } else if (response.data && typeof response.data === 'object') {
                setData([response.data]); // Convertir objeto en arreglo
            } else {
                console.error('Unexpected response data:', response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        fetchData();
    }, [codigoCia, codigoRiesgo]);


    const getDateFormat = (tmpDate) => {
        let firstDate = new Date(tmpDate);
        let options = { day: '2-digit', month: '2-digit', year: '2-digit', timeZone: "GMT" };
        return String(firstDate.toLocaleDateString('es-ES', options))
    }

    const deleteInforme = (codigo, fi, ff) => {
        axios.delete('/api/delete/informe-monitor/' + String(codigoCia) + '/' + String(codigoRiesgo) + '/' + String(codigo),
            { headers: { "x-access-token": localStorage.getItem('token') } }).then((response) => {
                if (response.data.result === 'Error') {
                    toast.error('Error al eliminar informe')
                } else {
                    toast.info('Informe borrado con éxito: ')
                    fetchData();
                }
            });
        setDisplayConfirmationModal(false);
    };

    /*modal del popup borrar */
    const [id, setId] = useState(null);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState(null);

    // Handle the displaying of the modal based on type and id
    const showDeleteModal = (id) => {
        setId(id);
        setDeleteMessage(`¿Está seguro de borrar el informe?`);
        setDisplayConfirmationModal(true);
    };

    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    const [editableCell, setEditableCell] = useState(null); // Guarda la celda editable: { rowId, columnName }
    const [editedValue, setEditedValue] = useState("");

    const handleEditCell = (rowId, columnName, currentValue) => {
        setEditableCell({ rowId, columnName });
        setEditedValue(currentValue);
    };

    const handleSave = (i,e) => {
        setEditedValue(e)
        onSave(i,e); // Enviar el valor editado al padre
      };

    return (
        <div>
            <br />
            <div style={{ overflowY: 'auto', maxHeight: '328px', overflowX: 'auto' }}>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>OPCIONES</th>
                            <th>FECHA</th>
                            <th>OBSERVACIONES</th>
                            <th>Monitor</th>
                            <th>Avance (%)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data./*slice(0, showAll ? data.length : 3).*/map((monitoreo) => (
                            <tr key={monitoreo.CODIGO_MONITOREO}>
                                <td>
                                    { <Button className="optionButtons"
                                        size="sm" variant="warning" onClick={() => {
                                            handleEditCell(monitoreo.CODIGO_MONITOREO, "name", monitoreo.CODIGO_OBSERVACIONES)
                                        }}><AiFillEdit /> Editar</Button>
                                    }
                                    <Button className="optionButtons"
                                        size="sm" variant="danger" onClick={() => {
                                            showDeleteModal(monitoreo.CODIGO_MONITOREO);
                                        }}><AiFillDelete /> Eliminar</Button>
                                </td>
                                <td>{getDateFormat(monitoreo.FECHA_MONITOREO)}</td>
                                <td style={{ textAlign: 'left' }} >
                                    {editableCell?.rowId === monitoreo.CODIGO_MONITOREO && editableCell.columnName === "name" ? (
                                        <input
                                            type="text"
                                            value={editedValue}
                                            onChange={(e) => handleSave(monitoreo.CODIGO_MONITOREO, e.target.value)}
                                        />
                                    ) : (
                                        monitoreo.CODIGO_OBSERVACIONES || 'No hay observaciones'
                                    )}

                                </td>
                                <td style={{ textAlign: 'left' }}>{`${monitoreo.NOMBRE} ${monitoreo.APELLIDO}`}</td>
                                <td>
                                    <BarraProgreso2 avance={monitoreo.PORCENTAJE_AVANCE}
                                        codigoMonitoreo={monitoreo.CODIGO_MONITOREO} codigoRiesgo={monitoreo.CODIGO_RIESGO} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <PopUpBorrar showModal={displayConfirmationModal}
                    confirmModal={deleteInforme} hideModal={hideConfirmationModal}
                    id={id} message={deleteMessage} />
            </div>
        </div>
    );
};


export default RiesgosMonitoreo;
