import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

const EliminarRiesgoModal = ({ isOpen, onClose, riesgo, onDeleteSuccess }) => {
  
  const handleDelete = async () => {
    try {
      await axios.delete("/api/delete/riesgo/1/"+riesgo.CODIGO_PERIODO+"/"+riesgo.CODIGO_TIPO_OBJETIVO+"/"+riesgo.CODIGO_OBJETIVO+"/"+riesgo.CODIGO_AREA+"/"+riesgo.CODIGO_RIESGO, {
        headers: { "x-access-token": localStorage.getItem('token') }
      });
      toast.success('Riesgo eliminado exitosamente');
      onDeleteSuccess();
      onClose();
    } catch (error) {
      console.error('Error eliminando riesgo:', error);
      toast.error('Error eliminando riesgo');
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>Confirmar eliminación</DialogTitle>
      <DialogContent>
        <DialogContentText>
          ¿Está seguro que desea eliminar este riesgo?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleDelete} color="secondary">
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EliminarRiesgoModal;
