import React from 'react'
import { Modal, Button } from "react-bootstrap";
import logoSis from '../images/logo2.png'
//import { Form } from 'react-bootstrap';
//import { Modal, Box, Button, Stack } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  backgroundColor: 'white',
  boxShadow: 24,
  p: 4,
};

const AcercaDe = ({ showModal, hideModal, confirmModal, message }) => {
  return (
    <Modal show={showModal} onHide={hideModal} centered>
      <div style={style}>
        <Modal.Header>
          <Modal.Title>Acerca de...</Modal.Title>
        </Modal.Header>
        <Modal.Body><div  style={{textAlign:"center"}}>
         <img src={logoSis} alt="Logo" style={{ width: '240px' }} />
          <p>Todos los derechos reservados</p>
          <p>Está prohibida la reproducción parcial o total de este sistema.</p>
          </div></Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={hideModal}>
            Aceptar
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  )
}

export default AcercaDe;