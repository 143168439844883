import React, { useState } from 'react';
import { Box, Typography, TextField, Button, IconButton, Paper, List, ListItem, ListItemText } from '@mui/material';
import { Send } from '@mui/icons-material';
import { MdOutlinePersonPin } from 'react-icons/md';
import '../../styles/ChatAsistente.scss';

const initialOptions = [
    "Hola",
    "Cuál es tu nombre",
    "Cómo puedo reportar un riesgo",
    "Cómo puedo analizar un riesgo",
    "Cómo puedo mitigar un riesgo",
    "Cómo puedo consultar un riesgo",
    // Agrega más opciones iniciales aquí
];

const predefinedResponses = {
    "hola": "¡Hola! ¿Cómo puedo ayudarte hoy?",
    "hola ": "¡Hola! ¿Cómo puedo ayudarte hoy?",
    "hola!": "¡Hola! ¿Cómo puedo ayudarte hoy?",
    "cual es tu nombre": "Soy el asistente virtual de riesgos. ¿En qué puedo asistirte?",
    "cual es tu nombre ": "Soy el asistente virtual de riesgos. ¿En qué puedo asistirte?",
    "cual es tu nombre!": "Soy el asistente virtual de riesgos. ¿En qué puedo asistirte?",
    "como puedo reportar un riesgo": "Para reportar un riesgo, debes ir al apartado de 'Reportes' y llenar el formulario correspondiente.",
    "como puedo reportar un riesgo ": "Para reportar un riesgo, debes ir al apartado de 'Reportes' y llenar el formulario correspondiente.",
    "como puedo reportar un riesgo?": "Para reportar un riesgo, debes ir al apartado de 'Reportes' y llenar el formulario correspondiente.",
    "como reportar un riesgo": "Para reportar un riesgo, debes ir al apartado de 'Reportes' y llenar el formulario correspondiente.",
    "como reportar un riesgo ": "Para reportar un riesgo, debes ir al apartado de 'Reportes' y llenar el formulario correspondiente.",
    "como reportar un riesgo?": "Para reportar un riesgo, debes ir al apartado de 'Reportes' y llenar el formulario correspondiente.",
    "como analizar un riesgo": "Puedes analizar un riesgo utilizando nuestras herramientas de análisis en la sección de 'Análisis de Riesgos'.",
    "como analizar un riesgo ": "Puedes analizar un riesgo utilizando nuestras herramientas de análisis en la sección de 'Análisis de Riesgos'.",
    "como analizar un riesgo?": "Puedes analizar un riesgo utilizando nuestras herramientas de análisis en la sección de 'Análisis de Riesgos'.",
    "como puedo analizar un riesgo": "Puedes analizar un riesgo utilizando nuestras herramientas de análisis en la sección de 'Análisis de Riesgos'.",
    "como puedo analizar un riesgo ": "Puedes analizar un riesgo utilizando nuestras herramientas de análisis en la sección de 'Análisis de Riesgos'.",
    "como puedo analizar un riesgo?": "Puedes analizar un riesgo utilizando nuestras herramientas de análisis en la sección de 'Análisis de Riesgos'.",
    "como mitigar un riesgo": "Para mitigar un riesgo, revisa nuestras estrategias de mitigación en el apartado de 'Mitigación'.",
    "como mitigar un riesgo ": "Para mitigar un riesgo, revisa nuestras estrategias de mitigación en el apartado de 'Mitigación'.",
    "como mitigar un riesgo?": "Para mitigar un riesgo, revisa nuestras estrategias de mitigación en el apartado de 'Mitigación'.",
    "como puedo mitigar un riesgo": "Para mitigar un riesgo, revisa nuestras estrategias de mitigación en el apartado de 'Mitigación'.",
    "como puedo mitigar un riesgo ": "Para mitigar un riesgo, revisa nuestras estrategias de mitigación en el apartado de 'Mitigación'.",
    "como puedo mitigar un riesgo?": "Para mitigar un riesgo, revisa nuestras estrategias de mitigación en el apartado de 'Mitigación'.",
    "como consultar un riesgo": "Puedes consultar los riesgos en el tablero de 'Consulta de Riesgos'.",
    "como consultar un riesgo ": "Puedes consultar los riesgos en el tablero de 'Consulta de Riesgos'.",
    "como consultar un riesgo?": "Puedes consultar los riesgos en el tablero de 'Consulta de Riesgos'.",
    "como puedo consultar un riesgo": "Puedes consultar los riesgos en el tablero de 'Consulta de Riesgos'.",
    "como puedo consultar un riesgo ": "Puedes consultar los riesgos en el tablero de 'Consulta de Riesgos'.",
    "como puedo consultar un riesgo?": "Puedes consultar los riesgos en el tablero de 'Consulta de Riesgos'.",
    "que es un riesgo": "Un riesgo es la posibilidad de que ocurra un evento que afecte negativamente la consecución de objetivos.",
    "que es un riesgo ": "Un riesgo es la posibilidad de que ocurra un evento que afecte negativamente la consecución de objetivos.",
    "que es un riesgo?": "Un riesgo es la posibilidad de que ocurra un evento que afecte negativamente la consecución de objetivos.",
    "cuales son los tipos de riesgos": "Los tipos de riesgos incluyen riesgos financieros, operativos, estratégicos, de cumplimiento y de reputación.",
    "cuales son los tipos de riesgos ": "Los tipos de riesgos incluyen riesgos financieros, operativos, estratégicos, de cumplimiento y de reputación.",
    "cuales son los tipos de riesgos?": "Los tipos de riesgos incluyen riesgos financieros, operativos, estratégicos, de cumplimiento y de reputación.",
    "como se evalua un riesgo": "La evaluación de riesgos implica identificar, analizar y evaluar el impacto y la probabilidad del riesgo.",
    "como se evalua un riesgo ": "La evaluación de riesgos implica identificar, analizar y evaluar el impacto y la probabilidad del riesgo.",
    "como se evalua un riesgo?": "La evaluación de riesgos implica identificar, analizar y evaluar el impacto y la probabilidad del riesgo.",
    "que es la probabilidad de un riesgo": "La probabilidad de un riesgo es la posibilidad de que ocurra un evento riesgoso.",
    "que es la probabilidad de un riesgo ": "La probabilidad de un riesgo es la posibilidad de que ocurra un evento riesgoso.",
    "que es la probabilidad de un riesgo?": "La probabilidad de un riesgo es la posibilidad de que ocurra un evento riesgoso.",
    "que es el impacto de un riesgo": "El impacto de un riesgo es la consecuencia o efecto que tendría un riesgo si se materializa.",
    "que es el impacto de un riesgo ": "El impacto de un riesgo es la consecuencia o efecto que tendría un riesgo si se materializa.",
    "que es el impacto de un riesgo?": "El impacto de un riesgo es la consecuencia o efecto que tendría un riesgo si se materializa.",
    "como se priorizan los riesgos": "Los riesgos se priorizan en función de su probabilidad e impacto para determinar cuál abordar primero.",
    "como se priorizan los riesgos ": "Los riesgos se priorizan en función de su probabilidad e impacto para determinar cuál abordar primero.",
    "como se priorizan los riesgos?": "Los riesgos se priorizan en función de su probabilidad e impacto para determinar cuál abordar primero.",
    "que es un plan de respuesta a riesgos": "Es un plan que detalla las acciones a tomar para gestionar y mitigar los riesgos identificados.",
    "que es un plan de respuesta a riesgos ": "Es un plan que detalla las acciones a tomar para gestionar y mitigar los riesgos identificados.",
    "que es un plan de respuesta a riesgos?": "Es un plan que detalla las acciones a tomar para gestionar y mitigar los riesgos identificados.",
    "como se monitorean los riesgos": "Los riesgos se monitorean continuamente para evaluar su evolución y la efectividad de las respuestas implementadas.",
    "como se monitorean los riesgos ": "Los riesgos se monitorean continuamente para evaluar su evolución y la efectividad de las respuestas implementadas.",
    "como se monitorean los riesgos?": "Los riesgos se monitorean continuamente para evaluar su evolución y la efectividad de las respuestas implementadas.",
    "que es un analisis cualitativo de riesgos": "Es una evaluación subjetiva de los riesgos basada en la probabilidad y el impacto, usando descripciones verbales.",
    "que es un analisis cualitativo de riesgos ": "Es una evaluación subjetiva de los riesgos basada en la probabilidad y el impacto, usando descripciones verbales.",
    "que es un analisis cualitativo de riesgos?": "Es una evaluación subjetiva de los riesgos basada en la probabilidad y el impacto, usando descripciones verbales.",
    "que es un analisis cuantitativo de riesgos": "Es una evaluación objetiva que cuantifica los riesgos en términos numéricos, como costos y tiempos.",
    "que es un analisis cuantitativo de riesgos ": "Es una evaluación objetiva que cuantifica los riesgos en términos numéricos, como costos y tiempos.",
    "que es un analisis cuantitativo de riesgos?": "Es una evaluación objetiva que cuantifica los riesgos en términos numéricos, como costos y tiempos.",
    "cuales son las estrategias de mitigacion de riesgos": "Las estrategias incluyen evitar, transferir, aceptar y reducir los riesgos.",
    "cuales son las estrategias de mitigacion de riesgos ": "Las estrategias incluyen evitar, transferir, aceptar y reducir los riesgos.",
    "cuales son las estrategias de mitigacion de riesgos?": "Las estrategias incluyen evitar, transferir, aceptar y reducir los riesgos.",
    "que es la transferencia de riesgos": "La transferencia de riesgos implica pasar el riesgo a un tercero, como en el caso de los seguros.",
    "que es la transferencia de riesgos ": "La transferencia de riesgos implica pasar el riesgo a un tercero, como en el caso de los seguros.",
    "que es la transferencia de riesgos?": "La transferencia de riesgos implica pasar el riesgo a un tercero, como en el caso de los seguros.",
    "que es la aceptacion de riesgos": "La aceptación de riesgos es reconocer el riesgo y decidir no tomar ninguna acción para mitigarlo.",
    "que es la aceptacion de riesgos ": "La aceptación de riesgos es reconocer el riesgo y decidir no tomar ninguna acción para mitigarlo.",
    "que es la aceptacion de riesgos?": "La aceptación de riesgos es reconocer el riesgo y decidir no tomar ninguna acción para mitigarlo.",
    "que es la evitacion de riesgos": "La evitación de riesgos implica tomar medidas para eliminar el riesgo por completo.",
    "que es la evitacion de riesgos ": "La evitación de riesgos implica tomar medidas para eliminar el riesgo por completo.",
    "que es la evitacion de riesgos?": "La evitación de riesgos implica tomar medidas para eliminar el riesgo por completo.",
    "que es la reduccion de riesgos": "La reducción de riesgos es la implementación de acciones para disminuir la probabilidad o el impacto del riesgo.",
    "que es la reduccion de riesgos ": "La reducción de riesgos es la implementación de acciones para disminuir la probabilidad o el impacto del riesgo.",
    "que es la reduccion de riesgos?": "La reducción de riesgos es la implementación de acciones para disminuir la probabilidad o el impacto del riesgo.",
    "como se documenta un riesgo": "Un riesgo se documenta en un registro de riesgos, detallando su descripción, probabilidad, impacto y plan de respuesta.",
    "como se documenta un riesgo ": "Un riesgo se documenta en un registro de riesgos, detallando su descripción, probabilidad, impacto y plan de respuesta.",
    "como se documenta un riesgo?": "Un riesgo se documenta en un registro de riesgos, detallando su descripción, probabilidad, impacto y plan de respuesta.",
    "que es una matriz de riesgos": "Es una herramienta que ayuda a visualizar y priorizar riesgos en función de su probabilidad e impacto.",
    "que es una matriz de riesgos ": "Es una herramienta que ayuda a visualizar y priorizar riesgos en función de su probabilidad e impacto.",
    "que es una matriz de riesgos?": "Es una herramienta que ayuda a visualizar y priorizar riesgos en función de su probabilidad e impacto.",
    "que es un riesgo residual": "Es el riesgo que queda después de haber implementado las medidas de mitigación.",
    "que es un riesgo residual ": "Es el riesgo que queda después de haber implementado las medidas de mitigación.",
    "que es un riesgo residual?": "Es el riesgo que queda después de haber implementado las medidas de mitigación.",
    "que es un riesgo inherente": "Es el riesgo natural de una actividad antes de aplicar cualquier medida de mitigación.",
    "que es un riesgo inherente ": "Es el riesgo natural de una actividad antes de aplicar cualquier medida de mitigación.",
    "que es un riesgo inherente?": "Es el riesgo natural de una actividad antes de aplicar cualquier medida de mitigación.",
    "que es un riesgo secundario": "Es un nuevo riesgo que surge como resultado de la implementación de una respuesta a otro riesgo.",
    "que es un riesgo secundario ": "Es un nuevo riesgo que surge como resultado de la implementación de una respuesta a otro riesgo.",
    "que es un riesgo secundario?": "Es un nuevo riesgo que surge como resultado de la implementación de una respuesta a otro riesgo.",
    "que es un riesgo emergente": "Es un riesgo nuevo que aún no ha sido totalmente comprendido o identificado.",
    "que es un riesgo emergente ": "Es un riesgo nuevo que aún no ha sido totalmente comprendido o identificado.",
    "que es un riesgo emergente?": "Es un riesgo nuevo que aún no ha sido totalmente comprendido o identificado.",
    "como se comunica un riesgo": "Un riesgo se comunica a través de informes, reuniones y sistemas de seguimiento de riesgos.",
    "como se comunica un riesgo ": "Un riesgo se comunica a través de informes, reuniones y sistemas de seguimiento de riesgos.",
    "como se comunica un riesgo?": "Un riesgo se comunica a través de informes, reuniones y sistemas de seguimiento de riesgos.",
    "que es la gestion de riesgos": "Es el proceso de identificar, evaluar, priorizar y responder a los riesgos para minimizar su impacto.",
    "que es la gestion de riesgos ": "Es el proceso de identificar, evaluar, priorizar y responder a los riesgos para minimizar su impacto.",
    "que es la gestion de riesgos?": "Es el proceso de identificar, evaluar, priorizar y responder a los riesgos para minimizar su impacto.",
    "cuales son las etapas de la gestion de riesgos": "Las etapas incluyen identificación, análisis, evaluación, respuesta y monitoreo de riesgos.",
    "cuales son las etapas de la gestion de riesgos ": "Las etapas incluyen identificación, análisis, evaluación, respuesta y monitoreo de riesgos.",
    "cuales son las etapas de la gestion de riesgos?": "Las etapas incluyen identificación, análisis, evaluación, respuesta y monitoreo de riesgos.",
    "que es un propietario del riesgo": "Es la persona responsable de gestionar y supervisar un riesgo específico.",
    "que es un propietario del riesgo ": "Es la persona responsable de gestionar y supervisar un riesgo específico.",
    "que es un propietario del riesgo?": "Es la persona responsable de gestionar y supervisar un riesgo específico.",
    "que es un indicador de riesgo": "Es una señal o advertencia que indica que un riesgo puede materializarse.",
    "que es un indicador de riesgo ": "Es una señal o advertencia que indica que un riesgo puede materializarse.",
    "que es un indicador de riesgo?": "Es una señal o advertencia que indica que un riesgo puede materializarse.",
    "como se identifican los riesgos": "Los riesgos se identifican a través de métodos como entrevistas, análisis de procesos, revisión de documentos y lluvia de ideas.",
    "como se identifican los riesgos ": "Los riesgos se identifican a través de métodos como entrevistas, análisis de procesos, revisión de documentos y lluvia de ideas.",
    "como se identifican los riesgos?": "Los riesgos se identifican a través de métodos como entrevistas, análisis de procesos, revisión de documentos y lluvia de ideas.",
    "que herramientas se utilizan para identificar riesgos": "Las herramientas incluyen diagramas de causa-efecto, análisis FODA, listas de verificación y análisis de escenarios.",
    "que herramientas se utilizan para identificar riesgos ": "Las herramientas incluyen diagramas de causa-efecto, análisis FODA, listas de verificación y análisis de escenarios.",
    "que herramientas se utilizan para identificar riesgos?": "Las herramientas incluyen diagramas de causa-efecto, análisis FODA, listas de verificación y análisis de escenarios.",
    "que es un mapa de calor de riesgos": "Es una representación visual que muestra los riesgos en función de su probabilidad e impacto.",
    "que es un mapa de calor de riesgos ": "Es una representación visual que muestra los riesgos en función de su probabilidad e impacto.",
    "que es un mapa de calor de riesgos?": "Es una representación visual que muestra los riesgos en función de su probabilidad e impacto.",
    "que es la tolerancia al riesgo": "Es el nivel de riesgo que una organización está dispuesta a aceptar.",
    "que es la tolerancia al riesgo ": "Es el nivel de riesgo que una organización está dispuesta a aceptar.",
    "que es la tolerancia al riesgo?": "Es el nivel de riesgo que una organización está dispuesta a aceptar.",
    "que es la capacidad de riesgo": "Es la cantidad máxima de riesgo que una organización puede asumir sin sufrir consecuencias inaceptables.",
    "que es la capacidad de riesgo ": "Es la cantidad máxima de riesgo que una organización puede asumir sin sufrir consecuencias inaceptables.",
    "que es la capacidad de riesgo?": "Es la cantidad máxima de riesgo que una organización puede asumir sin sufrir consecuencias inaceptables.",
    "que es el apetito de riesgo": "Es la cantidad de riesgo que una organización está dispuesta a aceptar para alcanzar sus objetivos.",
    "que es el apetito de riesgo ": "Es la cantidad de riesgo que una organización está dispuesta a aceptar para alcanzar sus objetivos.",
    "que es el apetito de riesgo?": "Es la cantidad de riesgo que una organización está dispuesta a aceptar para alcanzar sus objetivos.",
    "que es un evento de riesgo": "Es una ocurrencia específica que puede afectar negativamente a un proyecto o a una organización.",
    "que es un evento de riesgo ": "Es una ocurrencia específica que puede afectar negativamente a un proyecto o a una organización.",
    "que es un evento de riesgo?": "Es una ocurrencia específica que puede afectar negativamente a un proyecto o a una organización.",
    "que es la cultura de riesgo": "Es el conjunto de valores, creencias y comportamientos en una organización relacionados con la gestión de riesgos.",
    "que es la cultura de riesgo ": "Es el conjunto de valores, creencias y comportamientos en una organización relacionados con la gestión de riesgos.",
    "que es la cultura de riesgo?": "Es el conjunto de valores, creencias y comportamientos en una organización relacionados con la gestión de riesgos.",
    "que es la evaluacion de impacto de riesgos": "Es el proceso de determinar las consecuencias potenciales de un riesgo.",
    "que es la evaluacion de impacto de riesgos ": "Es el proceso de determinar las consecuencias potenciales de un riesgo.",
    "que es la evaluacion de impacto de riesgos?": "Es el proceso de determinar las consecuencias potenciales de un riesgo.",
    "que es la evaluacion de probabilidad de riesgos": "Es el proceso de determinar la probabilidad de que un riesgo ocurra.",
    "que es la evaluacion de probabilidad de riesgos ": "Es el proceso de determinar la probabilidad de que un riesgo ocurra.",
    "que es la evaluacion de probabilidad de riesgos?": "Es el proceso de determinar la probabilidad de que un riesgo ocurra.",
    "que es una auditoria de riesgos": "Es una revisión formal de los procesos de gestión de riesgos para asegurar su efectividad.",
    "que es una auditoria de riesgos ": "Es una revisión formal de los procesos de gestión de riesgos para asegurar su efectividad.",
    "que es una auditoria de riesgos?": "Es una revisión formal de los procesos de gestión de riesgos para asegurar su efectividad.",
    "que es la gestion de crisis": "Es el proceso de manejar un evento inesperado que tiene un impacto negativo significativo.",
    "que es la gestion de crisis ": "Es el proceso de manejar un evento inesperado que tiene un impacto negativo significativo.",
    "que es la gestion de crisis?": "Es el proceso de manejar un evento inesperado que tiene un impacto negativo significativo.",
    "que es un plan de continuidad del negocio": "Es un plan que asegura que una organización puede seguir operando durante y después de una crisis.",
    "que es un plan de continuidad del negocio ": "Es un plan que asegura que una organización puede seguir operando durante y después de una crisis.",
    "que es un plan de continuidad del negocio?": "Es un plan que asegura que una organización puede seguir operando durante y después de una crisis.",
    "que es un riesgo operacional": "Es el riesgo de pérdida resultante de fallos en procesos internos, personas y sistemas.",
    "que es un riesgo operacional ": "Es el riesgo de pérdida resultante de fallos en procesos internos, personas y sistemas.",
    "que es un riesgo operacional?": "Es el riesgo de pérdida resultante de fallos en procesos internos, personas y sistemas.",
    "que es un riesgo financiero": "Es el riesgo de pérdidas financieras debido a factores como fluctuaciones en el mercado o incumplimientos de pago.",
    "que es un riesgo financiero ": "Es el riesgo de pérdidas financieras debido a factores como fluctuaciones en el mercado o incumplimientos de pago.",
    "que es un riesgo financiero?": "Es el riesgo de pérdidas financieras debido a factores como fluctuaciones en el mercado o incumplimientos de pago.",
    "que es un riesgo estrategico": "Es el riesgo asociado con decisiones estratégicas que pueden afectar la capacidad de una organización para alcanzar sus objetivos.",
    "que es un riesgo estrategico ": "Es el riesgo asociado con decisiones estratégicas que pueden afectar la capacidad de una organización para alcanzar sus objetivos.",
    "que es un riesgo estrategico?": "Es el riesgo asociado con decisiones estratégicas que pueden afectar la capacidad de una organización para alcanzar sus objetivos.",
    "que es un riesgo de cumplimiento": "Es el riesgo de sanciones legales o regulatorias debido a incumplimiento de leyes y regulaciones.",
    "que es un riesgo de cumplimiento ": "Es el riesgo de sanciones legales o regulatorias debido a incumplimiento de leyes y regulaciones.",
    "que es un riesgo de cumplimiento?": "Es el riesgo de sanciones legales o regulatorias debido a incumplimiento de leyes y regulaciones.",
    "que es un riesgo reputacional": "Es el riesgo de daño a la reputación de una organización debido a eventos negativos.",
    "que es un riesgo reputacional ": "Es el riesgo de daño a la reputación de una organización debido a eventos negativos.",
    "que es un riesgo reputacional?": "Es el riesgo de daño a la reputación de una organización debido a eventos negativos.",
    "que es un riesgo tecnologico": "Es el riesgo asociado con el uso de tecnología y sistemas de información.",
    "que es un riesgo tecnologico ": "Es el riesgo asociado con el uso de tecnología y sistemas de información.",
    "que es un riesgo tecnologico?": "Es el riesgo asociado con el uso de tecnología y sistemas de información.",
    "que es un riesgo ambiental": "Es el riesgo de daño ambiental debido a las actividades de una organización.",
    "que es un riesgo ambiental ": "Es el riesgo de daño ambiental debido a las actividades de una organización.",
    "que es un riesgo ambiental?": "Es el riesgo de daño ambiental debido a las actividades de una organización.",
    "que es un riesgo de seguridad": "Es el riesgo de amenazas a la seguridad física y cibernética.",
    "que es un riesgo de seguridad ": "Es el riesgo de amenazas a la seguridad física y cibernética.",
    "que es un riesgo de seguridad?": "Es el riesgo de amenazas a la seguridad física y cibernética.",
    "que es un analisis de impacto en el negocio (BIA)": "Es un análisis que identifica los efectos potenciales de una interrupción en las operaciones comerciales.",
    "que es un analisis de impacto en el negocio (BIA) ": "Es un análisis que identifica los efectos potenciales de una interrupción en las operaciones comerciales.",
    "que es un analisis de impacto en el negocio (BIA)?": "Es un análisis que identifica los efectos potenciales de una interrupción en las operaciones comerciales.",
    "que es un plan de recuperacion ante desastres (DRP)": "Es un plan que detalla cómo una organización recuperará sus operaciones después de un desastre.",
    "que es un plan de recuperacion ante desastres (DRP) ": "Es un plan que detalla cómo una organización recuperará sus operaciones después de un desastre.",
    "que es un plan de recuperacion ante desastres (DRP)?": "Es un plan que detalla cómo una organización recuperará sus operaciones después de un desastre.",
    "que es la gestion de riesgos empresariales (ERM)": "Es un enfoque holístico para gestionar todos los riesgos a los que se enfrenta una organización.",
    "que es la gestion de riesgos empresariales (ERM) ": "Es un enfoque holístico para gestionar todos los riesgos a los que se enfrenta una organización.",
    "que es la gestion de riesgos empresariales (ERM)?": "Es un enfoque holístico para gestionar todos los riesgos a los que se enfrenta una organización.",
    "que es un analisis de escenarios": "Es una técnica para evaluar cómo diferentes escenarios futuros podrían afectar a una organización.",
    "que es un analisis de escenarios ": "Es una técnica para evaluar cómo diferentes escenarios futuros podrían afectar a una organización.",
    "que es un analisis de escenarios?": "Es una técnica para evaluar cómo diferentes escenarios futuros podrían afectar a una organización.",
    "que es una evaluacion de riesgos de terceros": "Es la evaluación de los riesgos asociados con los proveedores y socios comerciales.",
    "que es una evaluacion de riesgos de terceros ": "Es la evaluación de los riesgos asociados con los proveedores y socios comerciales.",
    "que es una evaluacion de riesgos de terceros?": "Es la evaluación de los riesgos asociados con los proveedores y socios comerciales.",
    "que es un comite de riesgos": "Es un grupo de personas responsables de supervisar la gestión de riesgos en una organización.",
    "que es un comite de riesgos ": "Es un grupo de personas responsables de supervisar la gestión de riesgos en una organización.",
    "que es un comite de riesgos?": "Es un grupo de personas responsables de supervisar la gestión de riesgos en una organización.",
    "que es una politica de gestion de riesgos": "Es un documento que establece el enfoque de una organización para gestionar los riesgos.",
    "que es una politica de gestion de riesgos ": "Es un documento que establece el enfoque de una organización para gestionar los riesgos.",
    "que es una politica de gestion de riesgos?": "Es un documento que establece el enfoque de una organización para gestionar los riesgos.",
    "que es una evaluacion de riesgos ciberneticos": "Es el proceso de identificar y evaluar los riesgos asociados con los sistemas de información y tecnología.",
    "que es una evaluacion de riesgos ciberneticos ": "Es el proceso de identificar y evaluar los riesgos asociados con los sistemas de información y tecnología.",
    "que es una evaluacion de riesgos ciberneticos?": "Es el proceso de identificar y evaluar los riesgos asociados con los sistemas de información y tecnología.",
    "que es un analisis de riesgos de proyectos": "Es la evaluación de los riesgos que pueden afectar la finalización exitosa de un proyecto.",
    "que es un analisis de riesgos de proyectos ": "Es la evaluación de los riesgos que pueden afectar la finalización exitosa de un proyecto.",
    "que es un analisis de riesgos de proyectos?": "Es la evaluación de los riesgos que pueden afectar la finalización exitosa de un proyecto.",
    "que es una matriz de probabilidad e impacto": "Es una herramienta que clasifica los riesgos en función de su probabilidad e impacto.",
    "que es una matriz de probabilidad e impacto ": "Es una herramienta que clasifica los riesgos en función de su probabilidad e impacto.",
    "que es una matriz de probabilidad e impacto?": "Es una herramienta que clasifica los riesgos en función de su probabilidad e impacto.",
    "que es una brecha de riesgo": "Es la diferencia entre el nivel de riesgo actual y el nivel de riesgo aceptable.",
    "que es una brecha de riesgo ": "Es la diferencia entre el nivel de riesgo actual y el nivel de riesgo aceptable.",
    "que es una brecha de riesgo?": "Es la diferencia entre el nivel de riesgo actual y el nivel de riesgo aceptable.",
    "que es la gestion de riesgos de TI": "Es el proceso de identificar, evaluar y mitigar los riesgos asociados con la tecnología de la información.",
    "que es apetito al riesgo": "Es el nivel de riesgo que una organización está dispuesta a aceptar para alcanzar sus objetivos.",
};

const normalizeInput = (input) => {
    return input.toLowerCase().replace(/[^\w\s]/gi, '').trim();
};

const ChatAssistant = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [open, setOpen] = useState(false);

    const handleSend = (message) => {
        const userInput = message || input;
        if (userInput.trim()) {
            const normalizedInput = normalizeInput(userInput);
            setMessages([...messages, { text: userInput, type: 'user' }]);
            setInput('');

            const botResponse = predefinedResponses[normalizedInput] || "Lo siento, no entiendo tu pregunta.";
            setMessages(prevMessages => [...prevMessages, { text: botResponse, type: 'bot' }]);
        }
    };

    return (
        <Box className={`chat-container ${open ? 'open' : ''}`}>
            <IconButton className="chat-toggle" onClick={() => setOpen(!open)}>
                <MdOutlinePersonPin />
            </IconButton>
            {open && (
                <Paper elevation={3} className="chat-box">
                    <Typography variant="h6" className="chat-title">Asistente Virtual</Typography>
                    <Box className="chat-content">
                        {messages.map((msg, index) => (
                            <Typography key={index} className={`chat-message ${msg.type}`}>
                                {msg.text}
                            </Typography>
                        ))}
                        {messages.length === 0 && (
                            <List className="chat-options">
                                {initialOptions.map((option, index) => (
                                    <ListItem key={index} button onClick={() => handleSend(option)}>
                                        <ListItemText primary={option} />
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </Box>
                    <Box className="chat-input">
                        <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={input}
                            onChange={e => setInput(e.target.value)}
                            onKeyPress={e => e.key === 'Enter' && handleSend()}
                        />
                        <Button variant="contained" color="primary" onClick={() => handleSend()} sx={{ marginLeft: '8px', minWidth: '40px' }}>
                            <Send />
                        </Button>
                    </Box>
                </Paper>
            )}
        </Box>
    );
};

export default ChatAssistant;
