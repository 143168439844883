import React, { useState, useEffect } from 'react';
import axios from 'axios';

function BarraProgreso2({ avance, codigoMonitoreo, codigoRiesgo }) {

  const [porcentaje, setPorcentaje] = useState(avance);


  // Función para manejar el cambio en el slider
  const handleSliderChange = (event) => {
    const nuevoPorcentaje = event.target.value;

    axios.post("/api/update/porcentaje", { porcentaje: nuevoPorcentaje, codigoMonitoreo: codigoMonitoreo, codigoRiesgo: codigoRiesgo },
      { headers: { "x-access-token": localStorage.getItem('token') } }).then((response) => {
        setPorcentaje(nuevoPorcentaje);
      });
  };


  return (
    <div>
      <h6>{porcentaje}</h6>
      <input
        type="range"
        min="0"
        max="100"
        value={porcentaje}
        onChange={handleSliderChange}
        style={{ marginTop: '15px', width: '100%' }}
      />
    </div>
  );
};

export default BarraProgreso2;