import React from 'react';
import { Col, Row } from 'react-bootstrap';  
import './pageNotFound.css';

export const PageNotFound =  () => {
    return (
    <div className='centerNotFound'>
        <Col>
            <Row>
                <img src="https://svgshare.com/i/Ye7.svg" alt='' className='centerImageNotFound' />
            </Row>
            <Row>
            <div className='textNotFound'>
                <h3>
                    La página a la cual esta tratando de entrar no existe. Puede que se haya traslado, cambiado de nombre o este bajo mantenimiento. Regrese a la <a href="/">página principal</a>.
                </h3>
            </div>  
            </Row>
        </Col>
    </div>
    )
}