import React, { useState, useEffect } from 'react';
import './login.css';
import Alerts from '../../Components/Alerts';
import 'react-toastify/dist/ReactToastify.css'
import logoSis from '../../images/logo2.png'
import axios from 'axios';
import {
    Button, CssBaseline, TextField, Link, Paper, Box, Grid, Typography, Container, InputAdornment,
    IconButton, FormControl, InputLabel, OutlinedInput, Select, MenuItem
} from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';


export const Login = (props) => {

    const [user, setUser] = useState('');
    const [pass, setPass] = useState('');
    const [listaEmpresas, setListaEmpresas] = useState([]);
    const [empresa, setEmpresa] = useState('0')
    const [mostrar, setMostrar] = useState(false)

    function getPosition(string, subString, index) {
        return string.split(subString, index).join(subString).length;
    }

    const [alarmParams, setAlarmParams] = useState({
        flag: false,
        m: '',
        variant: 'success',
        time: 5000
    });

    useEffect(() => {
        axios.get("/api/get/empresas",
            { headers: { "x-access-token": localStorage.getItem('token'), } }).then(
                (response) => {
                    setListaEmpresas(response.data.result);
                }
            )
    }, [])


    const handleEmpresas = (param) => {
        if (param) {
            setListaEmpresas(listaEmpresas)
            setEmpresa(param)
        } else {
            setListaEmpresas([])
        }
    }

    const signIn = () => {
        if (empresa === '0') {
            setAlarmParams({
                flag: true,
                m: `Seleccione una empresa`,
                variant: 'warning',
                time: 5000
            })
        }
        else {
            axios.post('/login', {
                usuario: user,
                contra: pass,
                empresa: empresa
            }).then((response) => {
                if (!response.data.auth) {
                    setAlarmParams({
                        flag: true,
                        m: response.data.message,
                        variant: 'warning',
                        time: 5000
                    })
                } else {
                    setAlarmParams({
                        flag: true,
                        m: 'Éxito',
                        variant: 'success',
                        time: 5000
                    })
                    localStorage.clear();
                    localStorage.setItem("token", response.data.token);
                    window.location = String(window.location.href).substring(0, getPosition(window.location.href, '/', 3));
                }
            });
        }
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {

        } else {
            signIn();
        }
    };


    return (
        <Container component="main" maxWidth="lg" >
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" >
                <Grid container justifyContent="center">
                    <CssBaseline />
                    <Grid item sm={false} md={7}
                        sx={{
                            backgroundImage: `url(${logoSis})`,
                            backgroundRepeat: "no-repeat",
                            backgroundColor: (t) =>
                                t.palette.mode === "light"
                                    ? t.palette.grey[50]
                                    : t.palette.grey[900],
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                        }}
                    />
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        md={5}
                        component={Paper}
                        elevation={6}
                        square
                    >
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Typography component="h1" variant="h5">
                                Iniciar sesión
                            </Typography>
                            <Box
                                component="form"
                                noValidate
                                onSubmit={handleSubmit}
                                sx={{ mt: 1 }}
                            >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="usuario"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    onChange={(e) => {
                                        setUser(e.target.value);
                                    }}
                                />
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                                    <OutlinedInput
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => { setMostrar(!mostrar) }} edge="end">
                                                    {mostrar ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        margin="normal" required fullWidth name="password" label="Contraseña" type={mostrar ? "text" : "password"}
                                        id="password" autoComplete="current-password" onChange={(e) => { setPass(e.target.value); }}
                                    />
                                </FormControl>
                                <p />
                                <FormControl fullWidth>
                                    <InputLabel>Empresa</InputLabel>
                                    <Select
                                        labelId="emp"
                                        id="emp"
                                        value={empresa}
                                        label="Empresa"
                                        placeholder="Seleccione una empresa"
                                        onChange={(e) => { handleEmpresas(e.target.value); }}
                                    >
                                        {
                                            listaEmpresas.map((opcion) => {
                                                return (
                                                    <MenuItem  key={opcion.CODIGO_CIA} value={opcion.CODIGO_CIA}>
                                                        {opcion.NOMBRE}
                                                    </MenuItem >
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Ingresar
                                </Button>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Link href="/recuperar" variant="body2">
                                            Olvidé mi contraseña
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Alerts alarmParams={alarmParams} setAlarmParams={setAlarmParams} />
        </Container>
    )
};