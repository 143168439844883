import React, { useEffect, useState } from 'react';
import { Table, Form, Button, Row, Col, InputGroup } from 'react-bootstrap';
import { AiFillEdit, AiFillDelete } from 'react-icons/ai';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './home.css';

// HTTP Requests
import Axios from 'axios';

export const NotificacionesAdmin = () => {
    
    const [lista, setLista] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [titulo, setTitulo] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [tipo, setTipo] = useState('');
    const [orden, setOrden] = useState('');
    const [desde, setDesde] = useState('');
    const [hasta, setHasta] = useState('');
    const [codigo, setCodigo] = useState('');
    const [video, setVideo] = useState('');
    const [showHelp, setShowHelp] = useState(false);

    const getLista = () => {
        Axios.get('/api/get/notificacionesAdmin',
            {headers: {"x-access-token": localStorage.getItem('tokenAdminSys')}}).then((response) => {
                if(response.data.error){
                    toast.error('Ha ocurrido un error')
                } else {
                    setLista(response.data.result)
                }
            }
        ); 
    }

    useEffect(()=>{
        getLista();
    },[])

    const getDateFormat = (tmpDate) => {
        let firstDate = new Date(tmpDate);
        let options = { day: 'numeric',  month: 'long', year: 'numeric', timeZone: "GMT"};
        return String(firstDate.toLocaleDateString('es-ES', options))
    }

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            setValidated(true);
        } else {
            setValidated(false);
            submitNotificacion();
        }
        setValidated(true);
    };

    const deleteNotification = (id) => {
        Axios.post("/api/delete/notificacionAdmin", {codigo: id,},{headers:{"x-access-token": localStorage.getItem('tokenAdminSys')}})
        .then((response)=>{
            if(response.data.result){
                getLista();
                toast.success('exito');
                clearFields();
                setShowForm(false);
            }else{
                toast.error('Error al guardar nueva notificación');
            }
        });
    }

    function matchYoutubeUrl(url) {
        if (url.includes('https://www.youtube.com/embed/')){
            return true
        } else {
            return false;
        }
    }

    const setOptions = () => {
        var valid = true;
        let opts = {
            titulo: titulo,
            descripcion: descripcion,
            css: tipo,
            orden: orden,
            fi: desde
        }
        if(codigo){opts['codigo']=codigo}
        if(hasta){opts['ff']=hasta}
        if(video){
            if (matchYoutubeUrl(video)){
                opts['video']=video;
            } else {
                valid = false;
            }
        }
        if (valid === true){
            return opts;
        } else {
            return false;
        }
    }

    const submitNotificacion = ()=>{
        const opts = setOptions();
        if (opts){
            if (codigo > 0) {
                //update
                Axios.post("/api/update/notificacionAdmin", opts, {headers:{"x-access-token": localStorage.getItem('tokenAdminSys')}})
                .then((response)=>{
                    if(response.data.result){
                        getLista();
                        toast.success('exito');
                        clearFields();
                        setShowForm(false);
                    }else{
                        toast.error('Error al guardar nueva notificación')
                    }
                });
            } else {
                //create
                Axios.post("/api/insert/notificacionAdmin", opts, {headers:{"x-access-token": localStorage.getItem('tokenAdminSys')}})
                .then((response)=>{
                    if(response.data.result){
                        getLista();
                        toast.success('exito');
                        clearFields();
                        setShowForm(false);
                    }else{
                        toast.error('Error al guardar nueva notificación')
                    }
                });
            }
        } else {
            toast.error('Url del vídeo no valida');
        }
    }

    const clearFields = () => {
        setValidated(false);
        setTitulo('');
        setDescripcion('');
        setTipo('warning');
        setOrden('');
        setDesde('');
        setHasta('');
        setCodigo('');
        setVideo('');
    }

    return(
        <>
            <p/>
            <Button size='sm' variant='success' onClick={()=>{clearFields();setShowForm(true);}}>Agregar nueva notificación</Button>
            <p/>
            {
                (showForm)?
                <>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="mb-2">
                            <Form.Group as={Col} md="6" >
                                <Form.Label>Titulo</Form.Label>
                                <Form.Control
                                    required
                                    maxLength="1000"
                                    type="text"
                                    value={titulo}
                                    onChange={(e)=>{setTitulo(e.target.value)}}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    Ingrese titulo
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" >
                                <Form.Label>Tipo</Form.Label>
                                <Form.Select
                                    required
                                    type="text"
                                    value={tipo}
                                    onChange={(e)=>{setTipo(e.target.value)}}
                                >
                                    <option value='warning'>warning</option>
                                    <option value='info'>info</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid" >
                                    Ingrese tipo
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-2">
                            <Form.Group as={Col} md="6" >
                                <Form.Label>Fecha inicio</Form.Label>
                                <Form.Control
                                    required
                                    type="date"
                                    value={desde}
                                    onChange={(e)=>{setDesde(e.target.value)}}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    Ingrese fecha inicial
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" >
                                <Form.Label>Fecha fin</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={hasta}
                                    onChange={(e)=>{setHasta(e.target.value)}}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    Ingrese fecha final
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-2">
                            <Form.Group as={Col} md="6" >
                                <Form.Label>Orden</Form.Label>
                                <Form.Control
                                    required
                                    maxLength="10"
                                    type="number"
                                    value={orden}
                                    onChange={(e)=>{setOrden(e.target.value)}}
                                />
                                <Form.Control.Feedback type="invalid" >
                                    Ingrese orden
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" >
                                <Form.Label>URL del vídeo (YouTube)</Form.Label>
                                {(showHelp)?<p className="text-info ng-scope">Para obtener el link de YouTube debe seleccionar el vídeo que desea compartir. Presionar el botón compartir y luego el botón insertar. Se desplegará un cuadro y tendrá que buscar un link parecido a:<br/>https://www.youtube.com/embed/xxxxxxxxxxxxxx</p>:
                                <></>
                                }
                                <InputGroup>
                                <Button variant='secondary' onClick={()=>setShowHelp(!showHelp === true)}>¿ayuda?</Button>
                                <Form.Control
                                    type="url"
                                    maxLength="450"
                                    value={video}
                                    onChange={(e)=>{setVideo(e.target.value)}}
                                />
                                </InputGroup>
                            </Form.Group>
                            
                        </Row>
                        <Row className="mb-1">
                            <Form.Group as={Col} md="12" >
                                <Form.Label>Descripción</Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    required
                                    maxLength="4000"
                                    value={descripcion}
                                    onChange={(e)=>{setDescripcion(e.target.value)}} 
                                />
                                <Form.Control.Feedback type="invalid">
                                    Ingrese descipción
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Button type="submit">Guardar</Button>
                        <Button variant='secondary' onClick={()=>{setShowForm(false);clearFields();}}>cancelar</Button>
                    </Form>
                    <p/>
                </>:<></>
            }
            <Table striped bordered hover responsive >
                <thead>
                    <tr>
                        <th>
                            titulo
                        </th>
                        <th>
                            titulo
                        </th>
                        <th>
                            Descripción
                        </th>
                        <th>
                            Tipo de la notificación
                        </th>
                        <th>
                            Orden
                        </th>
                        <th>
                            Vigencia
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        lista.map((value)=>{
                            try {
                                return(
                                    <tr key={value.id_notificacion}>
                                        <td>
                                            <Button className="optionButtons"
                                                size="sm" variant="warning" onClick={()=>{
                                                    setCodigo(value.id_notificacion)
                                                    setShowForm(true);
                                                    setTipo(value.css);
                                                    setTitulo(value.titulo);
                                                    setDescripcion(value.descripcion);
                                                    setOrden(value.orden);
                                                    var secondDate, sd;
                                                    if(value.vigencia_del){
                                                        secondDate = new Date(value.vigencia_del);
                                                        secondDate.setDate(secondDate.getDate());
                                                        sd = secondDate.toISOString().substr(0,10); 
                                                        setDesde(sd);
                                                    } else {
                                                        setDesde('');
                                                    }
                                                    if(value.vigencia_al){
                                                        secondDate = new Date(value.vigencia_al);
                                                        secondDate.setDate(secondDate.getDate());
                                                        sd = secondDate.toISOString().substr(0,10);  
                                                        setHasta(sd);
                                                    } else {
                                                        setHasta('');
                                                    }
                                                    if(value.url){
                                                        setVideo(value.url)
                                                    } else {
                                                        setHasta('');
                                                    }
                                                    
                                                }}
                                            ><AiFillEdit/> Editar</Button>
                                            <Button className="optionButtons"
                                                size="sm" variant="danger" onClick={()=>{deleteNotification(value.id_notificacion);}}><AiFillDelete/> Eliminar</Button>
                                        </td>
                                        <td>
                                            {value.titulo}
                                        </td>
                                        <td>
                                            {value.descripcion}
                                        </td>
                                        <td>
                                            {value.css}
                                        </td>
                                        <td>
                                            {value.orden}
                                        </td>
                                        <td>
                                            {getDateFormat(value.vigencia_del)} - {(value.vigencia_al)?getDateFormat(value.vigencia_al):'indefinida'}
                                        </td>
                                    </tr>
                                )
                            } catch (error) {
                                return(<></>)
                            }
                            
                        })
                    }
                </tbody>
            </Table>
        </>
    ); 
}