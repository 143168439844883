import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const ComboBoxVariableMitigacion = ({ value, onChange }) => {

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="variable-mitigacion-label">Variable a Mitigar</InputLabel>
      <Select
        labelId="variable-mitigacion-label"
        id="variable-mitigacion-select"
        value={value}
        onChange={handleChange}
        label="Variable a Mitigar"
      >
        <MenuItem value="P">P</MenuItem>
        <MenuItem value="S">S</MenuItem>
      </Select>
    </FormControl>
  );
};

export default ComboBoxVariableMitigacion;
