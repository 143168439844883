import React from 'react';
import './home.css';
import { Anuncios } from './anuncios';
import { Notificaciones } from './notificaciones';
import UpdatedNavBar from '../NavBar/UpdatedNavBar';
import { Box, Stack } from '@mui/material';

export const Home = () => {
    return(
        <>
            <UpdatedNavBar/>
            <div className='container-home'>
                <h1>Bienvenido al sistema de evaluación y gestión de riesgos</h1>   
                <h5>Utilice las opciones que tiene habilitado en el menú según los perfiles acreditados a su usuario.</h5>
                <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                    <Box sx={{display: {xs:'block', md: 'none'}, width: {xs: '90%', md: '50%'}}}>
                        <Notificaciones/>
                    </Box>
                    <Anuncios/>
                    <Box sx={{display: {xs:'none', md: 'block'}, width: {xs: '90%', md: '50%'}}}>
                        <Notificaciones/>
                    </Box>
                </Stack>             
            </div>
        </>
    ); 
}
