import React, { useEffect, useState } from 'react'
import { Form, Col, Row } from 'react-bootstrap'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Axios from 'axios'

function BusquedaPeriodo(props) {

    const [dependencia, setDependencia] = useState('second')
    const [listaArea, setListaArea] = useState([])
    const [area, setArea] = useState('')
    const [listaPeriodo, setListaPeriodo] = useState([])
    const [periodo, setPeriodo] = useState('')

    const getDateFormat = (tmpDate) => {
        let firstDate = new Date(tmpDate);
        let options = { day: 'numeric',  month: 'long', year: 'numeric', timeZone: "GMT"};
        return String(firstDate.toLocaleDateString('es-ES', options))
    }

    const handleDep = (param) => {
        if(param !== ''){
            setDependencia(dependencia)
            setListaPeriodo([])
            setArea('')
            setPeriodo('')
            props.setListaRiesgos([])
            props.setAno('')
            Axios.get("/api/get/area/"+String(param),{headers:{"x-access-token": localStorage.getItem('token')}}).then(
                (response)=> {
                    setListaArea(response.data);
                    if(response.data.length === 0){
                        toast.error('No existen áreas de evaluación para esta dependencia. Puede crear una nueva área de evaluación en el apartado de dependencias.');
                    }
                }
            );
        } else {
            setListaArea([])
            setListaPeriodo([])
            setArea('')
            setPeriodo('')
            props.setListaRiesgos([])
            props.setAno('')
        }
    }

    const handleArea = (param) => {
        if(param !== ''){
            setArea(area)
            props.setListaRiesgos([])
            props.setAno('')
            setPeriodo('')
            Axios.get("/api/get/periodo/"+String(param),{headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=> {
                setListaPeriodo(response.data);
                if(response.data.length === 0){
                    toast.error('No existen períodos de evaluación para esta área. Puede crear uno nuevo en el apartado de dependencias.');
                }
            });
        } else {
            setListaPeriodo([])
            setArea('')
            props.setListaRiesgos([])
            props.setAno('')
            setPeriodo('')
        }
    }

    const handlePeriodo = (param) => {
        if(param !== ''){
            setPeriodo(param)
            props.setAno(listaPeriodo.find(item => String(item.CODIGO_PERIODO) === String(param))['PERIODO_INICIAL'])
            Axios.get("/api/get/admin/planes",{params:{id: param}, headers:{"x-access-token": localStorage.getItem('token')}})
            .then((response)=> {
                if(response.data.err){
                    props.setListaRiesgos([]);
                } else if(response.data.result.length === 0){
                    props.setListaRiesgos([]);
                    toast.error('No existen planes de acción para estos datos.');
                } else {
                    props.setListaRiesgos(response.data.result);
                } 
            });
        } else {
            props.setListaRiesgos([])
            props.setAno('')
            setPeriodo('')
        }
    }

    const refresh = () => {
        if (props.cont > 0){
            Axios.get("/api/get/admin/planes",{params:{id: periodo}, headers:{"x-access-token": localStorage.getItem('token')}})
            .then((response)=> {
                if(response.data.err){
                    props.setListaRiesgos([]);
                } else if(response.data.result.length === 0){
                    props.setListaRiesgos([]);
                    toast.error('No existen planes de acción para estos datos.');
                } else {
                    props.setListaRiesgos(response.data.result);
                } 
            });
        }
    }

    useEffect(()=>{
        refresh(props.cont)
    }, [props.cont])

    return (
        <Form onSubmit={()=>{}}>
            <Row className="mb-3">
                <Form.Group as={Col} md="2">
                    <Form.Label><b></b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label><b>Dependencia</b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Select onChange={(e) => handleDep(e.target.value)}>
                        <option value ='' ></option>
                        {
                            props.listaDep.map((value) => {
                                return(<option value={value.CODIGO_DEPENDENCIA} key={value.CODIGO} >{value.NOMBRE}</option>)
                            })
                        }
                    </Form.Select>
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="2">
                    <Form.Label><b></b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label><b>Área de evaluación</b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Select onChange={(e)=>handleArea(e.target.value)} >
                        <option value ='' ></option>
                        {
                            listaArea.map((value) => {
                                return(<option value={value.CODIGO_AREA} key={value.CODIGO} >{value.SIGLAS} - {value.NOMBRE}</option>)
                            })
                        }
                    </Form.Select>
                </Form.Group>                    
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="2">
                    <Form.Label><b></b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label><b>Período de evaluación</b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Select onChange={(e)=>handlePeriodo(e.target.value)} >
                        <option value=''></option>
                        {
                            listaPeriodo.map((value) => {
                                return(<option value={value.CODIGO_PERIODO} key={value.CODIGO_PERIODO} >Del {getDateFormat(value.PERIODO_INICIAL)} al {getDateFormat(value.PERIODO_FINAL)}</option>)
                            })
                        }
                    </Form.Select>
                </Form.Group>                    
            </Row>
        </Form>
    )
}

export default BusquedaPeriodo