import React, { useEffect, useState } from 'react';
import { NavBar } from '../NavBarAdmin/navBar';
import { Table, Button } from 'react-bootstrap';
import { AiFillEdit } from 'react-icons/ai';
import { EditarInformacionEntidad } from './editarInformacion';

// HTTP Requests
import Axios from 'axios';

export const LimiteUsuarios = () => {

    const [lista, setLista] = useState([]);
    const [show, setShow] = useState(false);
    const [nombre, setNombre] = useState('');
    const [vigencia, setVigencia] = useState('');
    const [limite, setLimite] = useState('');
    const [codigo, setCodigo] = useState('');

    const refresh = () => {
        Axios.get('/get/limiteUsuarios', {headers:{"x-access-token": localStorage.getItem('tokenAdminSys')}})
        .then((response)=>{
            setLista(response.data.result);
        });
    }

    useEffect(()=>{
        refresh();
    }, [])

    return(
        <>
            <NavBar/>
            <div className='container-usuario-administracion'>
                <h3 className="blog-title">Control de instituciones</h3>
                <p className="lead blog-description">En esta área puede llevar control del límite de usuarios de cada institución y la vigencia de cada una</p>
                <hr/>
                <p/>
                <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Opción</th>
                                <th>Nombre institución</th>
                                <th>Limite de usuarios</th>
                                <th>Vigencia</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                lista.map((value)=>{
                                    return(
                                        <tr key={value.codigo}>
                                            <td>
                                                <Button className='optionButtons' variant='info' size='sm' 
                                                    onClick={()=>{
                                                        setShow(true);
                                                        setNombre(value.nombre);
                                                        setLimite(value.limite);
                                                        setVigencia(value.fecha);
                                                        setCodigo(value.codigo);
                                                    }}
                                                ><AiFillEdit/> Editar</Button>
                                            </td>
                                            <td>
                                                {value.nombre}
                                            </td>
                                            <td>
                                                {(value.limite !== null)?value.limite:'Sin definir'}
                                            </td>
                                            <td>
                                                {(value.fecha)?value.fecha:'Sin definir'}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    <EditarInformacionEntidad nombre={nombre} setNombre={setNombre} setVigencia={setVigencia} vigencia={vigencia} 
                        setLimite={setLimite} limite={limite} refresh={()=>{refresh();}} codigo={codigo}
                        show={show} setShow={setShow}
                    />
            </div>
        </>
    ); 
}