import React, { useState, useEffect } from "react";
import {Table} from 'react-bootstrap';
import '../Dependencias/formulario.css'
import {Puesto} from './puesto'
import UpdatedNavBar from "../NavBar/UpdatedNavBar";

// HTTP Requests
import Axios from 'axios';

export const PuestoView = () => {

    /**
     * States de puestos
     */
    const [idPuesto, setIdPuesto] = useState('');
    const [abreviaturaPuesto, setAbreviaturaPuesto] = useState('');
    const [descrPuesto, setDescrPuesto] = useState('');

    /**
     * nombre usuario
     */
    const [usuarioWeb, setUsuarioWeb] = useState('');

    useEffect(()=> {
        Axios.get("/api/getuserweb",
        {headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=> {
            setUsuarioWeb(response.data.usuarioWeb);
        });
    }, [])

    /**
     * Bandera para área de evaluación
     */
    const [puestoFlag, setPuestoFlag] = useState(true);

    return(
        <>
            <UpdatedNavBar />
            <div className="container-dependencias">
                {
                    (puestoFlag) ?
                    <Puesto
                        descrPuesto={descrPuesto} setDescrPuesto={setDescrPuesto} 
                        abreviaturaPuesto={abreviaturaPuesto} setAbreviaturaPuesto={setAbreviaturaPuesto}
                        idPuesto={idPuesto} setIdPuesto={setIdPuesto}
                        setPuestoFlag={setPuestoFlag}
                        usuarioWeb={usuarioWeb}
                    /> : 
                    <>
                        <h2>Puestos</h2 >
                        <p className="lead blog-description">Puestos de la organización</p>
                        
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Descripción</th>
                                    <th>Abreviatura</th>
                                </tr> 
                                <tr className="segunda-fila">
                                    <td>{idPuesto}</td>
                                    <td>{descrPuesto}</td>
                                    <td>{abreviaturaPuesto}</td>
                                </tr>
                            </thead>
                        </Table>
                    </>
                }
            </div>
        
        </>
    )

}