import React, { useState, useEffect } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import Axios from 'axios';

function SearchObjetivoArea(props) {
    const [listaProbabilidad, setListaProbabilidad] = useState([]);
    const [listaTolerancia, setListaTolerancia] = useState([]);
    const [listaSeveridad, setListaSeveridad] = useState([]);
    const [listaMitigacion, setListaMitigacion] = useState([]);
    const [listaResponsable, setListaResponsable] = useState([]);
    const [listaValorMitigacion, setListaValorMitigacion] = useState([]); // Nueva lista

    const [selectedProbabilidad, setSelectedProbabilidad] = useState('');
    const [selectedTolerancia, setSelectedTolerancia] = useState('');
    const [selectedSeveridad, setSelectedSeveridad] = useState('');
    const [selectedMitigacion, setSelectedMitigacion] = useState('');
    const [selectedResponsable, setSelectedResponsable] = useState('');
    const [selectedValorMitigacion, setSelectedValorMitigacion] = useState(''); // Nuevo estado

    useEffect(() => {
        if (props.defaultValues) {
            setSelectedProbabilidad(props.defaultValues.codigoProbabilidad);
            setSelectedTolerancia(props.defaultValues.codigoTolerancia);
            setSelectedSeveridad(props.defaultValues.codigoSeveridad);
            setSelectedMitigacion(props.defaultValues.codigoMitigacion);
            setSelectedResponsable(props.defaultValues.codigoResponsable);
            setSelectedValorMitigacion(props.defaultValues.valorMitigacion); // Nuevo valor por defecto
        }
    }, [props.defaultValues]);

    useEffect(() => {
        // Obtiene los datos de probabilidad
        Axios.get("/api/getRiesgos_riesgo/Probabilidad", { headers: { "x-access-token": localStorage.getItem('token') } })
            .then((response) => {
                if (Array.isArray(response.data)) {
                    setListaProbabilidad(response.data);
                } else {
                    console.error("Data is not an array:", response.data);
                }
            }).catch((error) => {
                console.error("Error fetching Probabilidad data:", error);
            });

        // Obtiene los datos de tolerancia
        Axios.get("/api/getRiesgos_riesgo/Tolerancia", { headers: { "x-access-token": localStorage.getItem('token') } })
            .then((response) => {
                if (Array.isArray(response.data)) {
                    setListaTolerancia(response.data);
                } else {
                    console.error("Data is not an array:", response.data);
                }
            }).catch((error) => {
                console.error("Error fetching Tolerancia data:", error);
            });

        // Obtiene los datos de severidad
        Axios.get("/api/getRiesgos_riesgo/Severidad", { headers: { "x-access-token": localStorage.getItem('token') } })
            .then((response) => {
                if (Array.isArray(response.data)) {
                    setListaSeveridad(response.data);
                } else {
                    console.error("Data is not an array:", response.data);
                }
            }).catch((error) => {
                console.error("Error fetching Severidad data:", error);
            });

        // Obtiene los datos de mitigación
        Axios.get("/api/getRiesgos_riesgo/Mitigacion", { headers: { "x-access-token": localStorage.getItem('token') } })
            .then((response) => {
                if (Array.isArray(response.data)) {
                    setListaMitigacion(response.data);
                } else {
                    console.error("Data is not an array:", response.data);
                }
            }).catch((error) => {
                console.error("Error fetching Mitigación data:", error);
            });

        // Obtiene los datos de valor de mitigación
        Axios.get("/api/getRiesgos_riesgo/ValorMitigacion", { headers: { "x-access-token": localStorage.getItem('token') } }) // Nueva ruta
            .then((response) => {
                if (Array.isArray(response.data)) {
                    setListaValorMitigacion(response.data);
                } else {
                    console.error("Data is not an array:", response.data);
                }
            }).catch((error) => {
                console.error("Error fetching ValorMitigacion data:", error);
            });

        // Obtiene los datos de responsables
        Axios.get("/api/get/PersonaResponsable", { headers: { "x-access-token": localStorage.getItem('token') } })
            .then((response) => {
                if (Array.isArray(response.data)) {
                    setListaResponsable(response.data);
                } else {
                    console.error("Data is not an array:", response.data);
                }
            }).catch((error) => {
                console.error("Error fetching Responsable data:", error);
            });
    }, []);

    return (
        <>
            <Row className="mb-3">
                <Form.Group as={Col} md="2">
                    <Form.Label><b>Probabilidad</b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Select 
                        value={selectedProbabilidad}
                        onChange={(e) => {
                            setSelectedProbabilidad(e.target.value);
                            if (e.target.value !== '') {
                                props.setProbabilidad && props.setProbabilidad(e.target.value);
                            }
                        }}
                    >
                        <option value=''></option>
                        {listaProbabilidad.map((value) => (
                            <option value={value.CODIGO} key={value.CODIGO}>
                                {value.CODIGO} - {value.DESCRIPCION}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label><b>Tolerancia</b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Select 
                        value={selectedTolerancia}
                        onChange={(e) => {
                            setSelectedTolerancia(e.target.value);
                            if (e.target.value !== '') {
                                props.setTolerancia && props.setTolerancia(e.target.value);
                            }
                        }}
                    >
                        <option value=''></option>
                        {listaTolerancia.map((value) => (
                            <option value={value.CODIGO} key={value.CODIGO}>
                                {value.CODIGO} - {value.DESCRIPCION}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="2">
                    <Form.Label><b>Severidad</b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Select 
                        value={selectedSeveridad}
                        onChange={(e) => {
                            setSelectedSeveridad(e.target.value);
                            if (e.target.value !== '') {
                                props.setSeveridad && props.setSeveridad(e.target.value);
                            }
                        }}
                    >
                        <option value=''></option>
                        {listaSeveridad.map((value) => (
                            <option value={value.CODIGO} key={value.CODIGO}>
                                {value.CODIGO} - {value.DESCRIPCION}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label><b>Nivel (Capacidad de Mitigación)</b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Select 
                        value={selectedMitigacion}
                        onChange={(e) => {
                            setSelectedMitigacion(e.target.value);
                            if (e.target.value !== '') {
                                props.setMitigacion && props.setMitigacion(e.target.value);
                            }
                        }}
                    >
                        <option value=''></option>
                        {listaMitigacion.map((value) => (
                            <option value={value.CODIGO} key={value.CODIGO}>
                                {value.CODIGO} - {value.DESCRIPCION}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label><b>Valor Mitigación</b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Select 
                        value={selectedValorMitigacion}
                        onChange={(e) => {
                            setSelectedValorMitigacion(e.target.value);
                            if (e.target.value !== '') {
                                props.setValorMitigacion && props.setValorMitigacion(e.target.value);
                            }
                        }}
                    >
                        <option value=''></option>
                        {listaMitigacion.map((value) => (
                            <option value={value.VALOR} key={value.VALOR}>
                                {value.VALOR} - {value.CODIGO2}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Row>
        </>
    );
}

export default SearchObjetivoArea;
