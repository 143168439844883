import React, { useState } from 'react'
import SearchPeriodo from './SearchPeriodo'
import { Button, Table } from 'react-bootstrap';
import ModalDetalles from './ModalDetalles';
import Axios from 'axios';
import UpdatedNavBar from '../NavBar/UpdatedNavBar';
import { Stack, Typography } from '@mui/material';
import { toast } from 'react-toastify';

function MatrizContinuidad() {

    const [listaRiesgos, setListaRiesgos] = useState([])
    const [show, setShow] = useState(false);
    const [codigo, setCodigo] = useState('')
    const [titulo, setTitulo] = useState('');
    const [periodo, setPeriodo] = useState('')

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const searchRiesgo = (periodo) => {
        Axios.get("/api/getriesgosMC", { params: { periodo }, headers: { "x-access-token": localStorage.getItem('token') } }).then(
            (response) => {
                Axios.get('/api/get/cantidadmetodospendientes',
                    { params: { id: response.data.map((riesgo) => { return riesgo.CODIGO_RIESGO }).join() }, headers: { "x-access-token": localStorage.getItem('token') } })
                    .then((response2) => {
                        const newArray = response.data.map((riesgo) => {
                            let newElement = {
                                CODIGO_RIESGO: riesgo.CODIGO_RIESGO,
                                ID_RIESGO: riesgo.ID_RIESGO,
                                DESCRIPCION: riesgo.DESCRIPCION
                            }
                            const index = response2.data.result.findIndex(e => e.CODIGO_RIESGO === riesgo.CODIGO_RIESGO)
                            if (index > -1) {
                                newElement['pendientes'] = response2.data.result[index].cantidad
                            } else {
                                newElement['pendientes'] = 'Este riesgo no cuenta con métodos de mitigación o con subtemas pendientes por asignar'
                            }
                            return newElement
                        })
                        setListaRiesgos(newArray);
                    })
            });
    }

    return (
        <>
            <UpdatedNavBar />
            <div className="container-riesgos">
                {/**
                 * Titulos principales de la página
                 */}
                <Stack spacing={1}>
                    <Stack>
                        <Typography variant="h2" color='black' >Matriz de continuidad</Typography>
                        <Typography variant="h4" color='lightgray' >Sección especial para gestionar la información de la matriz de continuidad</Typography>
                        <hr />
                    </Stack>
                    <pre>
                        El reporte de "Matriz de continuidad" se forma por 7 columnas principales:
                        <aside>     1. Riesgo: descripción del riesgo</aside>
                        <aside>     2. Subtema: responden a la pregunta ¿Qué sucedería si el riesgo sucede? o ¿Cuál es la razón de este riesgo?</aside>
                        <aside>     3. Nivel de tolerancia: nivel de tolerancia luego de que se realizaron los métodos de monitoreo</aside>
                        <aside>     4. Frecuencia de monitero: frecuencia que tuvireon los métodos de monitoreo</aside>
                        <aside>     5. Método: descripción de lo que se realizó para mitigar el sub tema</aside>
                        <aside>     6. Responsable: responsable a cargo de llevar a cabo el método de mitigación</aside>
                        <aside>     7. Severidad del riesgo</aside>
                    </pre>
                    <SearchPeriodo setListaRiesgos={setListaRiesgos} searchRiesgo={searchRiesgo} setPeriodo={setPeriodo} />
                    <Table bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Detalles</th>
                                <th>Ref.</th>
                                <th>Descripción del riesgo</th>
                                <th>Cantidad de métodos pendientes por asignar subtemas</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listaRiesgos.map((riesgo, indice) => {
                                    return (
                                        <tr key={indice}>
                                            <td>
                                                <Button onClick={() => {
                                                    setCodigo(riesgo.CODIGO_RIESGO);
                                                    setTitulo(riesgo.ID_RIESGO);
                                                    handleShow()
                                                }} variant='info'>Detalle</Button>
                                            </td>
                                            <td>
                                                {riesgo.ID_RIESGO}
                                            </td>
                                            <td>
                                                {riesgo.DESCRIPCION}
                                            </td>
                                            <td style={(riesgo.pendientes !== 'Este riesgo no cuenta con métodos de mitigación o con subtemas pendientes por asignar') ? { backgroundImage: "linear-gradient(to bottom,#f2dede 0,#e7c3c3 100%)" } : {}} >
                                                {riesgo.pendientes}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    <ModalDetalles handleClose={handleClose} titulo={titulo} show={show} codigo={codigo} setCodigo={setCodigo}
                        searchRiesgo={searchRiesgo} periodo={periodo} />
                </Stack>

            </div>
        </>
    )
}

export default MatrizContinuidad