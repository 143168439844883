import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import Axios from 'axios'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

function CambiarLogo() {

    const [path, setPath] = useState('')
    const [fileElement, setFileElement] = useState('')
    const [periodo, setPeriodo] = useState('')
    const [mes, setMes] = useState('')
    const [periodos, setPeriodos] = useState([]);

    useEffect(() => {
        refreshLogo()

        Axios.get("/api/get/listaperiodos", { headers: { "x-access-token": localStorage.getItem('token') } }).then((response) => {
            setPeriodos(response.data.result);
        });
    }, [])

    const refreshLogo = () => {
        Axios.get('/api/get/image-url', { headers: { "x-access-token": localStorage.getItem('token') } })
            .then((response) => {
                setPath(response.data.result[0].path)
                setPeriodo(response.data.result[0].CODIGO_PERIODO)
                setMes(response.data.result[0].MES)
            })
    }

    const submitDocument = () => {
        try {
            if (fileElement.files.length > 0) {
                let file = fileElement.files[0];
                let formData = new FormData();
                formData.set('files', file);
                Axios.post('/api/post/update-image-logo', formData,
                    {
                        headers: {
                            "x-access-token": localStorage.getItem('token'),
                            "Content-Type": "multipart/form-data",
                        },
                    }).then((response) => {
                        if (response.data.error) {
                            toast.error(response.data.message);
                        } else {
                            toast.success(response.data.message);
                            refreshLogo()
                        }
                    });
            } else {
                toast.error(`Elija un documento para subir con formato png o jpg`)
            }
        } catch (error) { toast.error('Error') }
    }

    const handlePeriodo = (e) => {
        Axios.post("/api/update/parametros", { periodo: e, mes: mes }, { headers: { "x-access-token": localStorage.getItem('token') } }).then((response) => {
            setPeriodo(e);
        });
    };

    const handleMes = (e) => {
        Axios.post("/api/update/parametros", { periodo: periodo, mes: e }, { headers: { "x-access-token": localStorage.getItem('token') } }).then((response) => {
            setMes(e);
        });
    };


    return (
        <Row>
            <Col md={5}>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Período actual</Form.Label>
                        <Form.Select
                            required
                            value={periodo}
                            onChange={(e) => { handlePeriodo(e.target.value) }}
                        >
                            {
                                periodos.map((value) => {
                                    return (
                                        <option key={value.CODIGO_PERIODO} value={value.CODIGO_PERIODO}>
                                            {value.CODIGO_PERIODO}
                                        </option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Mes actual</Form.Label>
                        <Form.Select
                            required
                            value={mes}
                            onChange={(e) => { handleMes(e.target.value) }}
                        >
                            <option value='1'>Enero</option>
                            <option value='2'>Febrero</option>
                            <option value='3'>Marzo</option>
                            <option value='4'>Abril</option>
                            <option value='5'>Mayo</option>
                            <option value='6'>Junio</option>
                            <option value='7'>Julio</option>
                            <option value='8'>Agosto</option>
                            <option value='9'>Septiembre</option>
                            <option value='10'>Octubre</option>
                            <option value='11'>Noviembre</option>
                            <option value='12'>Diciembre</option>
                        </Form.Select>
                    </Form.Group>
                </Form>
            </Col>
            <Col md={1} className='d-flex justify-content-center align-items-center'>
               <div className='border-end' style={{height:'100%', width: '1px'}} />
            </Col>
            < Col md={6} >
                <Form>
                    <Form.Label>Logo de la empresa</Form.Label>
                    <img src={(path) ? 'https://monitorderiesgo.com/Pictures/' + path : ''} alt="Logo"></img>
                    <Form.Control id={'subida'} type="file"
                        onChange={() => {
                            try {
                                setFileElement(document.getElementById('subida'));
                            } catch (error) {
                                setFileElement('');
                            }
                        }}
                    />
                    <Box>
                        <Button size="sm" variant="success" onClick={submitDocument}>Cambiar</Button>
                    </Box>
                </Form>
            </Col>
        </Row>
    )
}

export default CambiarLogo