import React, { useState } from 'react';  
import './login.css';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Form, Col, Button } from 'react-bootstrap';
import logoSis from '../../images/logo.png'

// HTTP Requests
import Axios from 'axios';

export const LoginAdminSystem =  (props) => {

    function getPosition(string, subString, index) {
        return string.split(subString, index).join(subString).length;
    }
    /**
     * States
     */
    const [usuario, setUsuario] = useState('');
    const [contra, setContra] = useState('');
    const [validateForm, setValidateForm] = useState(false);

    /**
     * Functions
     */

    const login = () => {
        Axios.post('/loginAdminSystem', {
            usuario: usuario,
            contra: contra
        }).then((response) => {            
            if (!response.data.auth){
                toast.error(response.data.message);
            } else {
                toast.success('exito')
                localStorage.clear();
                localStorage.setItem("tokenAdminSys", response.data.token);
                window.location = String(window.location.href).substring(0,getPosition(window.location.href,'/',3));
            }
        });
    };

    const enterPressed = (e) => {

        if (e.keyCode === 13) {
            submitForm(e);
        }

    }

    /**
     * Funciones y variables para validar los forms
     */

    const submitForm = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            setValidateForm(true);
        } else {
            login();
        }
        setValidateForm(true);
    };

    /**
     * View
     */
    return(
        <> 
            <div className="main_box">
                <img src={logoSis} className='logo' alt='' />
                <div className="main_box--main">
                    <div className="main_box--main--title">
                        <h2>
                            <b>
                                Iniciar sesión (administrador de sistema)
                            </b>
                        </h2>
                    </div>
                    <div className="main_box--main--login">
                        <Form noValidate validated={validateForm}  onSubmit={submitForm} >
                            <Form.Group className="mb-3">
                                <Col>
                                    <Form.Label><b>Usuario</b></Form.Label>
                                    <Form.Control
                                        type='email'
                                        placeholder='micorreo@mail.com' 
                                        name='usuario' 
                                        required
                                        autoComplete='false' 
                                        onKeyDown={enterPressed} 
                                        onChange={(e) => {
                                            setUsuario(e.target.value);
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Ingrese su usuario por favor
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Col>
                                    <Form.Label><b>Contraseña</b></Form.Label>
                                    <Form.Control
                                        type = 'password'
                                        placeholder='Contraseña' 
                                        name='contra' 
                                        required
                                        onKeyDown={enterPressed} 
                                        onChange={(e) => {
                                            setContra(e.target.value);
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Ingrese su contraseña por favor
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Button variant='success' size='sm' type='submit' >
                                Iniciar sesión
                            </Button>
                            <Button
                                className='btn recover'
                                size='sm'
                                type='button'
                                href="/recuperar"
                            >
                                Olvide mi contraseña
                            </Button>
                        </Form>
                    </div>
                </div>    
            </div>
        </> 
    ); 
};