import React, { useState, useEffect } from 'react'
import UpdatedNavBar from '../NavBar/UpdatedNavBar'
import { Stack, Typography } from '@mui/material'
import BusquedaPeriodo from './BusquedaPeriodo'
import Axios from 'axios'
import AdvertenciaPeriodo from '../../Components/Riesgos/AdvertenciaPeriodo'
import TablaPlanesAccion from './TablaPlanesAccion'
import ModalEditarPlan from './ModalEditarPlan'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PlanesAccion() {
    
    const [listaRiesgos, setListaRiesgos] = useState([])
    const [listaDep, setListaDep] = useState([])
    const [ano, setAno] = useState('')
    const [editar, seteditar] = useState(false)
    const [cont, setCont] = useState(0)
    const [id, setId] = useState('')
    const [personas, setPersonas] = useState([])
    const [riesgoInfo, setRiesgoInfo] = useState({id: '', nombre: ''})

    const handleEditarO = (id) => {
        seteditar(true)
        setId(id)
    }
    const handleEditarC = () => {
        seteditar(false)
        setId('')
    }

    useEffect(()=>{
        Axios.get("/api/get/mi-entidad", 
        {headers:{"x-access-token": localStorage.getItem('token'),}}).then(
            (responseGet)=> {
                setListaDep(responseGet.data);
                Axios.get('/api/get/lista-personas-activas-entidad', {headers:{"x-access-token": localStorage.getItem('token'),}})
                .then((response) => {
                    if(response.data.err){
                        toast.error('Error al obtener lista de empleados')
                    } else {
                        //toast.info('exito al obtener lista de empleados')
                        setPersonas(response.data.result)
                    }
                })
            }
        );
         
    },[])

    return (
        <>
            <UpdatedNavBar/>
            <div className="container-riesgos">
                <Stack spacing={2}>
                    <Stack>
                        <Typography variant="h2" color='black' >Planes de acción</Typography>
                        <Typography variant="h4" color='lightgray' >Área de gestión de planes de acción</Typography>
                        <hr/>
                    </Stack>
                    <Stack>
                        <BusquedaPeriodo cont={cont} setAno={setAno} listaDep={listaDep} setListaRiesgos={setListaRiesgos} />
                        <hr/>
                    </Stack>
                    {
                        (ano) && <AdvertenciaPeriodo fechaPeriodo={ano}/>
                    }
                    {
                        (listaRiesgos.length !== 0) && <TablaPlanesAccion setRiesgoInfo={setRiesgoInfo} handleEditarO={handleEditarO} listaRiesgos={listaRiesgos}/>
                    }
                </Stack>
            </div>
            <ModalEditarPlan setCont={setCont} cont={cont} id={id} personas={personas} setRiesgoInfo={setRiesgoInfo} riesgoInfo={riesgoInfo} handleClose={handleEditarC} show={editar}/>
        </>
    )
}

export default PlanesAccion