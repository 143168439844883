import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Col } from 'react-bootstrap';
import { Modal, Box, Button, Stack } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const ObjetivoModalEditar = ({ isOpen, onClose, codigoObjetivo, descripcion, abreviatura, onInsertSuccess }) => {
    const [descrObj, setDescripcion] = useState('');
    const [abreviaturaObj, setAbreviatura] = useState('');

    useEffect(() => {
        setDescripcion(descripcion);
        setAbreviatura(abreviatura);
    }, [descripcion, abreviatura]);

    const handleSubmit = () => {
        axios.put('/api/update/objetivo/' + codigoObjetivo, {
            descrObj: descrObj,
            abreviaturaObj: abreviaturaObj,
        }, {
            headers: { "x-access-token": localStorage.getItem('token') }
        })
            .then(response => {
                onInsertSuccess();  // Llama a la función para recargar los datos de la tabla
                onClose();
            })
            .catch(error => console.error(error));
    };

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control value={descrObj} onChange={(e) => { setDescripcion(e.target.value) }} placeholder="Descripción del objetivo" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Abreviatura</Form.Label>
                        <Form.Control maxLength="10" value={abreviaturaObj} onChange={(e) => { setAbreviatura(e.target.value); }} placeholder="Abreviatura del objetivo" onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()} />
                    </Form.Group>
                </Form>
                <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                    <Button variant="contained" onClick={handleSubmit}>Aceptar</Button>
                    <Button variant="outlined" onClick={onClose}>Cancelar</Button>
                </Stack>
            </Box>
        </Modal>
    );
};

export default ObjetivoModalEditar;
