import React, { useState, useEffect } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import Axios from 'axios';

function SearchDependencia(props) {
    const [listaDependencias, setListaDependencias] = useState([]);

    useEffect(() => {
        // Obtiene los períodos de la empresa
        Axios.get("/api/get/dependencia", { headers: { "x-access-token": localStorage.getItem('token') } })
            .then((response) => {
                setListaDependencias(response.data);
            });

    }, [props.tipObj, props.periodo]);

    return (
        <>
            <Row className="mb-3">
                <Form.Group as={Col} md="2">
                    <Form.Label><b></b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label><b>Dependencia</b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Select onChange={
                        (e) => {
                            if (e.target.value !== '') {
                                props.setDependencia(e.target.value)
                            }
                        }
                    }>
                        <option value=''></option>
                        {listaDependencias.map((value) => {
                            return (<option value={value.CODIGO} key={value.CODIGO}>{value.NOMBRE}</option>)
                        })}
                    </Form.Select>
                </Form.Group>
            </Row>
        </>
    )
}

export default SearchDependencia;
