import React from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Alerts(props) {
    
    /**
     * Parametros alarma
     
    const [alarmParams, setAlarmParams] = useState({
        flag: false,
        m: '',
        variant: 'success',
        time: 5000
    });
    */
    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {   
            return;
        }
        props.setAlarmParams({
            flag: false,
            m: props.alarmParams.m,
            variant: props.alarmParams.variant,
            time: props.alarmParams.time
        });
    };
    
    return (
        <Snackbar open={props.alarmParams.flag} autoHideDuration={props.alarmParams.time} onClose={handleClose}>
            <Alert onClose={handleClose} severity={props.alarmParams.variant} sx={{ width: '100%' }}>
                {props.alarmParams.m}
            </Alert>
        </Snackbar>
    );
}

export default Alerts