import React, { useState } from 'react'
import UpdatedNavBar from '../NavBar/UpdatedNavBar'
import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { Form, Row, Col } from 'react-bootstrap'
import Axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Perfil() {

    const [vieja, setVieja] = useState('')
    const [nueva, setNueva] = useState('')
    const [nuevaRep, setNuevaRep] = useState('')

    const [viejoCorreo, setViejoCorreo] = useState('')
    const [nuevoCorreo, setNuevoCorreo] = useState('')
    const [nuevoCorreoRep, setNuevoCorreoRep] = useState('')

    const actualizarContraseña = () => {
        if (nueva && nuevaRep && nueva !== nuevaRep) {
            toast.info('Ha ocurrido un error al enviar su solicitud.');
        } else if (!nueva || !nuevaRep || !vieja) {
            toast.info('Llene todos los campos.');
        } else {
            Axios.post('/api/post/actualizar-contra-usuario-perfil',
                { vieja: vieja, nueva: nueva },
                { headers: { "x-access-token": localStorage.getItem('token') } })
                .then((response) => {
                    if (response.data.result) {
                        toast.success('Contraseña actualizada con exito.');
                    } else {
                        toast.info(response.data.msg)
                    }
                })
        }
    }

    const actualizarCorreo = () => {
        if (nuevoCorreo && nuevoCorreoRep && nuevoCorreo !== nuevoCorreoRep) {
            toast.info('Ha ocurrido un error al enviar su solicitud.');
        } else if (!nuevoCorreo || !nuevoCorreoRep || !viejoCorreo) {
            toast.info('Llene todos los campos.');
        } else {
            Axios.post('/api/post/actualizar-correo',
                { viejoCorreo: viejoCorreo, nuevoCorreo: nuevoCorreo },
                { headers: { "x-access-token": localStorage.getItem('token') } })
                .then((response) => {
                    if (response.data.result) {
                        toast.success('Correo actualizado con éxito.');
                    } else {
                        toast.info(response.data.msg)
                    }
                })
        }
    }


    return (
        <>
            <UpdatedNavBar />
            <div className="container-reportes">
                <h2>Perfil</h2 >
                <p className="lead blog-description">
                    Sección para cambio de contraseña y correo electrónico
                </p>
                <hr />
                <Row>
                    <Col md={5}>
                    <Form.Label>Actualizar contraseña</Form.Label>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField label='Contraseña actual' sx={{ width: { xs: '100%', md: '50%' } }} onChange={(e) => setVieja(e.target.value)} value={vieja} type='password' />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <TextField label='Contraseña nueva' sx={{ width: { xs: '100%', md: '50%' } }} onChange={(e) => setNueva(e.target.value)} value={nueva} type='password' />
                                    {
                                        nueva.length < 8 && <Typography variant='span' color={'red'}>La contraseña nueva debe ser de al menos 8 caracteres</Typography>
                                    }
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <TextField label='Confirmar contraseña nueva' sx={{ width: { xs: '100%', md: '50%' } }} onChange={(e) => setNuevaRep(e.target.value)} value={nuevaRep} type='password' />
                                    {
                                        (nueva && nuevaRep && nueva !== nuevaRep) && <Typography variant='span' color={'red'}>Las contraseñas no coinciden</Typography>
                                    }
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant='contained' size='small' color='success' onClick={actualizarContraseña}>
                                    Actualizar contraseña
                                </Button>
                            </Grid>
                        </Grid>
                    </Col>
                    <Col md={1} className='d-flex justify-content-center align-items-center'>
                        <div className='border-end' style={{ height: '100%', width: '1px' }} />
                    </Col>
                    < Col md={6} >
                    <Form.Label>Cambiar correo electrónico</Form.Label>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField label='Correo actual' sx={{ width: { xs: '100%', md: '50%' } }} onChange={(e) => setViejoCorreo(e.target.value)} value={viejoCorreo} type='email' />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <TextField label='correo nuevo' sx={{ width: { xs: '100%', md: '50%' } }} onChange={(e) => setNuevoCorreo(e.target.value)} value={nuevoCorreo} type='email' />
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <TextField label='Repita el correo nuevo' sx={{ width: { xs: '100%', md: '50%' } }} onChange={(e) => setNuevoCorreoRep(e.target.value)} value={nuevoCorreoRep} type='email' />
                                    {
                                        (nuevoCorreo && nuevoCorreoRep && nuevoCorreo !== nuevoCorreoRep) && <Typography variant='span' color={'red'}>El correo ingresado no coincide</Typography>
                                    }
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant='contained' size='small' color='success' onClick={actualizarCorreo}>
                                    Cambiar correo electrónico
                                </Button>
                            </Grid>
                        </Grid>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Perfil