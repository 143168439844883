import React, { useState } from 'react';
import SearchObjetivo from './searchObjetivo';
import UpdatedNavBar from '../NavBar/UpdatedNavBar';
import { Stack, Typography, Button } from '@mui/material';
import TablaDatosTipoObjetivo from './Componentes/TablaDatosObjetivo';
import ObjetivoModal from './Componentes/ModalInsertarObjetivos';
import { AiOutlinePlus } from 'react-icons/ai';
import '../Dependencias/formulario.css';

export const ObjetivosView = () => {
    const [periodo, setPeriodo] = useState('');
    const [tipObj, setTipObj] = useState('');
    const [reloadTrigger, setReloadTrigger] = useState(0);

    /* manejo de modal */
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const reloadData = () => {
        setReloadTrigger(prev => prev + 1);
    };

    return (
        <>
            <UpdatedNavBar />
            <div className="container-dependencias">
                <Stack spacing={1}>
                    <Stack>
                        <h2>Objetivos</h2 >
                        <p className="lead blog-description">Objetivos de la institución</p>
                        <hr />
                    </Stack>

                    <SearchObjetivo setPeriodo={setPeriodo} setTipObj={setTipObj} />

                    <div className='md-col-3'>
                        <Button
                            size='sm'
                            style={{
                                backgroundColor: '#28a745', /* Color verde personalizado */
                                borderColor: '#28a745', /* Color del borde personalizado */
                                color: 'white', /* Color del texto */
                                padding: '0.25rem 0.5rem', /* Ajuste del padding */
                                fontSize: '0.8rem' /* Ajuste del tamaño de fuente */
                            }}
                            variant="success"
                            className="nueva-dependencia"
                            onClick={openModal}
                        >
                            <AiOutlinePlus /> Nuevo objetivo
                        </Button>
                    </div>
                    <TablaDatosTipoObjetivo TipoObjetivo={tipObj} CodigoPeriodo={periodo} reloadTrigger={reloadTrigger} />
                </Stack>
            </div>
            <ObjetivoModal
                isOpen={isModalOpen}
                onClose={closeModal}
                tipoObjetivo={tipObj}
                onInsertSuccess={reloadData}
            />
        </>
    );
};