import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Table, OverlayTrigger, Tooltip, InputGroup } from 'react-bootstrap';
import { AiOutlinePlus, AiFillEdit, AiFillDelete, AiTwotoneAppstore, AiOutlineSearch } from 'react-icons/ai';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import '../Dependencias/formulario.css'
import PopUpBorrar from '../../Components/popUpBorrar'

// HTTP Requests
import Axios from 'axios';

toast.configure()

export const Puesto = (props) => {

    /**
     * Lista de puestos
     */
    const [listaPrinPuesto, setListaPrinPuesto] = useState([]);
    const [listaPuesto, setListaPuesto] = useState([]);


    const [listaEliminar, setListaEliminar] = useState([]);
    
    const [showState, setShowState] = useState(false);
    const [showStateUpdate, setShowStateUpdate] = useState(false);
    const [codigoEntidad, setCodigoEntidad] = useState('');

    /*modal del popup borrar */
    const [id, setId] = useState(null);
    const [nombreArea, setNombreArea] = useState(null);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState(null);

    // Handle the displaying of the modal based on type and id
    const showDeleteModal = (id,nombre) => {
        setId(id);
        setNombreArea(nombre)
        setDeleteMessage(`¿Está seguro de borrar el área ${nombre} ?`);
        setDisplayConfirmationModal(true);
    };

    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    const busquedaPuesto = (listaInfo) => {
        const input1 = document.getElementById('input-1').value;
        let tmpArray = []
        if (String(input1) === ''){
            return listaInfo
        } else {
            const indexes = listaInfo.map(
                    (element, index) => {
                            let flag = false;

                            if (String(input1) !== ''){
                                if(element.CODIGO.toLowerCase().includes(input1.toLowerCase())){
                                    flag = true;
                                } else {
                                    return -1
                                }
                            }

                            if(flag){
                                return index
                            }
                            
                        }
                    ).filter(value => value >= 0)
            for (let i of indexes) {
                tmpArray.push(listaInfo[i])
            }
            return tmpArray
        }
    }

    const refreshPuesto = (param) => {
        Axios.get("/api/get/puestos",{headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=> {
            setListaPrinPuesto(response.data);
            setListaPuesto(response.data);
        });
    }

    const submitPuesto = () => {
        if(props.abreviaturaPuesto !== '' && props.descrPuesto !== ''){
            Axios.post("/api/insert/puesto", {
                descrPuesto: props.descrPuesto, 
                abreviaturaPuesto: props.abreviaturaPuesto, 
            },{headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=>{
                if(response.data.result){
                    props.setDescrPuesto('')
                    props.setAbreviaturaPuesto('')
                    setShowState(false);
                    refreshPuesto(codigoEntidad)
                }else{
                    toast.error(response.data.error);
                }
            });
        } else {
            toast.error('Llene todos los campos')
        }
    };

 
    useEffect(() => {
        Axios.get('/api/various/getEntity', 
            {headers:{"x-access-token": localStorage.getItem('token')}}).then((response) =>{
                setCodigoEntidad(response.data[0].codigo_entidad);
                refreshPuesto(response.data[0].codigo_entidad)
        });
    }, []);

  
    const deletePuesto = (codigo) => {
        Axios.delete('/api/delete/puesto/' + String(codigo), {headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=>{
            if (response.data.result === 'Error'){
              toast.error('Error al eliminar puesto de trabajo: ' + nombreArea)
            } else {
                toast.info('Registro eliminado: ' + nombreArea)
                refreshPuesto(codigoEntidad)
            }
        });
    };
  
    
    const updatePuesto = () => {
        if(props.descrPuesto !== '' && props.siglasPuesto !== ''){
            Axios.put('/api/update/puesto/'+String(props.idPuesto), {
                descrPuesto: props.descrPuesto, 
                abreviaturaPuesto: props.abreviaturaPuesto, 
                },{headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=>{
                    if (response.data.result === 'Error'){
                    toast.error('Error al actualizar el tipo de objetivo: ' + props.descrPuesto)
                    } else {
                        refreshPuesto(codigoEntidad)
                    }
            });
        } else {
            toast.error('Ingrese todos los campos')
        }
    };

    return (
        <div className="container">
            {/**
             * Titulos principales de la página
             */}
            <h2>Puestos de trabajo</h2 >
            <p className="lead blog-description">Puestos de la organización</p>
            
            {/**
             * Botón para crear nueva dependencia
             */}
            <Button size='sm' variant="success" className="nueva-dependencia" onClick={() => {
                props.setIdPuesto('');
                props.setDescrPuesto('');
                props.setAbreviaturaPuesto('');
                setShowState(true);}}>
                <AiOutlinePlus/> Nuevo puesto de trabajo
            </Button>
            {/** 
             * Primer Modal
             * Este modal es para el formulario de una nueva dependencia
            */}
            <Modal show={showState} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Agregar puesto de trabajo
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Descripción</Form.Label>
                            <Form.Control onChange={(e)=> {props.setDescrPuesto(e.target.value)}} placeholder="Descripción" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Abreviatura</Form.Label>
                            <Form.Control maxLength="10" onChange={(e)=> {props.setAbreviaturaPuesto(e.target.value);}} placeholder="Abreviatura" onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {submitPuesto();}}>Guardar</Button>
                    <Button variant='secondary' onClick={()=>{setShowState(false);}}>Cancelar</Button>
                </Modal.Footer>
            </Modal>
            <p/>
            {/**
             * Esta tabla es para hacer display de todas las PuestoS existentes
             */}
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Opción</th>
                        <th>Descripción</th>
                        <th>Abreviatura</th>
                    </tr>
                    <tr>
                        <td>Herramientas de búsqueda</td>
                        <td>
                            <InputGroup className="search-input">
                                <Button 
                                    variant="outline-secondary"
                                    onClick={()=>{
                                        setListaPuesto(busquedaPuesto(listaPrinPuesto));
                                    }}
                                >
                                    <AiOutlineSearch/>
                                </Button>
                                <Form.Control
                                    onChange={()=>{
                                        setListaPuesto(busquedaPuesto(listaPrinPuesto));
                                    }}
                                    id="input-1"
                                    type="text"
                                    placeholder="búsqueda por código"
                                />
                            </InputGroup>
                        </td>
                        <td>
                            <InputGroup className="search-input">
                                <Button 
                                    variant="outline-secondary"
                                    onClick={()=>{
                                        setListaPuesto(busquedaPuesto(listaPrinPuesto));
                                    }}
                                >
                                    <AiOutlineSearch/>
                                </Button>
                                <Form.Control
                                    onChange={()=>{
                                        setListaPuesto(busquedaPuesto(listaPrinPuesto));
                                    }}
                                    id="input-2"
                                    type="text"
                                    placeholder="búsqueda por descripción"
                                />
                            </InputGroup>
                        </td>
                    </tr> 
                </thead>
                <tbody>
                    {
                    listaPuesto.map((value) => {
                        return (
                        <tr key={value.CODIGO}>
                            <td>
                            <Button className="segundo-boton" size="sm" variant="info" onClick={() => {
                                props.setIdPuesto(value.CODIGO);
                                props.setDescrPuesto(value.DESCRIPCION);
                                props.setAbreviaturaPuesto(value.ABREVIATURA);
                                setShowStateUpdate(true);
                                }}>
                                    <AiFillEdit/>    Editar
                            </Button>
                            {
                                (props.usuarioWeb === value.USUARIO_CREACION)?
                                    <Button className="segundo-boton" size="sm" variant="danger" onClick={() => {
                                        showDeleteModal(value.CODIGO,value.DESCRIPCION)
                                        }}
                                    >
                                        <AiFillDelete/>    Eliminar
                                    </Button> : 
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip id="button-tooltip-1">No tiene autorización para eliminar este puesto</Tooltip>}
                                    >
                                        <span>
                                            <Button className="segundo-boton" size="sm" variant="danger" disabled>
                                                <AiFillDelete/>    Eliminar
                                            </Button>
                                        </span>
                                    </OverlayTrigger>
                                    
                                
                            }
                            </td>
                            <td>{value.DESCRIPCION}</td>
                            <td>{value.ABREVIATURA}</td>
                        </tr>
                        ); 
                    })}
                </tbody>
            </Table>
            <Modal show={showStateUpdate} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Editar puesto de trabajo
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Descripción</Form.Label>
                            <Form.Control onChange={(e)=> {props.setDescrPuesto(e.target.value)}} placeholder="Descripción" defaultValue={props.descrPuesto}/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Abreviatura</Form.Label>
                            <Form.Control maxLength="10" onChange={(e)=> {props.setAbreviaturaPuesto(e.target.value);}} placeholder="Abreviatura" defaultValue={props.abreviaturaPuesto} onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {updatePuesto();setShowStateUpdate(false);}}>Guardar</Button>
                    <Button variant='secondary' onClick={()=>{
                        setShowStateUpdate(false);
                        props.setDescrPuesto('');
                        props.setAbreviaturaPuesto('');
                        props.setIdPuesto('');}}>Cancelar</Button>
                </Modal.Footer>
            </Modal>  
             
            
            <PopUpBorrar showModal={displayConfirmationModal}
                confirmModal={deletePuesto} hideModal={hideConfirmationModal}
                id={id} message={deleteMessage} />              
        </div>
    );
}