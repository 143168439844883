import React from "react";
import { Button } from 'react-bootstrap';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineCloudDownload } from "react-icons/ai";

// HTTP Requests
import Axios from 'axios';

export const DownloadButton = (props) => {

    const download = () => {
        try {
            let formData = new FormData();
            formData.set('fileName', props.path);
            formData.set('codigo_entidad', props.path);
            Axios.get(props.downloadURL,
                {
                    params: {
                        fileName: props.path
                    },
                    headers: {
                        "x-access-token": localStorage.getItem('token')
                    },
                    responseType: 'blob',
                }).then((response) => {
                    if(response.status === 500){
                        toast.error('error al descargar archivo')
                    } else if(response.headers['content-length'] === '0'){
                        toast.error('Ha ocurrido un error al generar su reporte, se ha enviado un correo a soporte. Tendrá pronta respuesta, gracias.')
                    } else {
                        //Create a Blob from the PDF Stream
                        const file = new Blob(
                            [response.data], 
                            {type: response.data.type}
                        );
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        const link = document.createElement('a');
                        link.href = fileURL;
                        link.setAttribute('download',props.original);
                        // Append to html link element page
                        document.body.appendChild(link);
                        // Start download
                        link.click();
                        //Open the URL on new Window
                        window.open(fileURL);
                        // Clean up and remove the link
                        link.parentNode.removeChild(link);
                    }
                }
            ); 
        } catch (error) {}
    }

    return(
        <Button className="optionButtons" size="sm" variant="success" onClick={()=>{download();}}>
            <AiOutlineCloudDownload/> Descargar
        </Button>
    );
}