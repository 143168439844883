import React, { useState, useEffect } from 'react';
import './home.css';
import { Card, Accordion } from 'react-bootstrap';
import { MdNotifications } from 'react-icons/md';

// HTTP Requests
import Axios from 'axios';
import { Box } from '@mui/material';

export const Notificaciones = (props) => {

    const [listaNotificaciones, setListaNotificaciones] = useState([]);
    const [listaPlanes, setListaPlanes] = useState([]);
    const [listaActividades, setListaActividades] = useState([]);

    useEffect(()=>{
        Axios.get('/api/getPendingRisk',{headers:{"x-access-token": localStorage.getItem('token'),},}).then(
            (response)=> {
                setListaNotificaciones(response.data.result);
            }
        );
        Axios.get('/get/actividadesactivas',{headers:{"x-access-token": localStorage.getItem('token'),},}).then(
            (response)=> {
                setListaPlanes(response.data);
                let id = response.data.map((element) => {return element.id_plan_responsable})
                Axios.get('/get/actividadesactivasdetalles',{headers:{"x-access-token": localStorage.getItem('token'), "ids": id},}).then(
                    (response2)=> {
                        setListaActividades(response2.data);
                    }
                );
            }
        );
    }, [])

    const getDateFormat = (tmpDate) => {
        let firstDate = new Date(tmpDate);
        let options = { day: 'numeric',  month: 'long', year: 'numeric', timeZone: "GMT"};
        return String(firstDate.toLocaleDateString('es', options))
    }

    const freq = {
        24: 'semanal',
        25: 'quincenal',
        26: 'mensual',
        27: 'trimestral',
        28: 'semestral',
    }

    return(
        <>
        <Box>
            <Accordion className='accordion-notificacions'>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        
                        {
                            (listaNotificaciones.length > 0) ? 
                            <><MdNotifications color='red'/> Riesgos pendientes por chequear ({listaNotificaciones.length}) </>:
                            <>Riesgos pendientes por chequear (0)</>
                        } 
                    </Accordion.Header>
                    <Accordion.Body>
                        {
                            listaNotificaciones.map((value)=>{
                                return(
                                    <Card key={value.CODIGO_RIESGO} className='carta-notificacion'>
                                        <Card.Body>
                                            <Card.Title style={{color: '#FF0000'}}>Riesgo pendiente en confirmar eliminación</Card.Title>
                                            <hr/>
                                            <Card.Text>
                                                <b>Dependencia: </b>{`${value.DEPENDENCIA}.`}<br/>
                                                <b>Área de evaluación: </b>{`${value.AREA}.`}<br/>
                                                <b>Descripción: </b>{`${value.DESCRIPCION}.`}<br/>
                                                <b>Periodo: </b>{` del ${getDateFormat(value.periodo_inicial)} al ${getDateFormat(value.periodo_final)}.`}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                )
                            })
                        }
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        {
                            (listaPlanes.length > 0) ? 
                            <><MdNotifications color='red'/> Actividades vigentes ({listaPlanes.length})</> :
                            <>Actividades vigentes (0)</>
                        }  
                    </Accordion.Header>
                    <Accordion.Body>
                        <Accordion className='accordion-notificacions'>
                            {
                                listaPlanes.map((value)=>{
                                    const indexes = listaActividades.map((element, index) => {
                                        if (String(element.id_plan_responsable) === String(value.id_plan_responsable)){
                                            return index;
                                        }
                                    }).filter(element => element >= 0)
                                    return(
                                        <Accordion.Item key={value.id_plan_responsable} eventKey={value.id_plan_responsable} >
                                            <Accordion.Header>
                                                {value.ID_RIESGO} - {value.DESCRIPCION} {(indexes.length>0)?<>({indexes.length} actividades)</>:<>(0 actividades)</>}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <b>Método: </b>{`${value.metodo}.`}<br/>
                                                <b>fecha inicio: </b>{(value.fecha_inicio)?getDateFormat(value.fecha_inicio):'sin definir'}<br/>
                                                <b>fecha fin: </b>{(value.fecha_fin)?getDateFormat(value.fecha_fin):'sin definir'}<br/>
                                                <b>Frecuencia: </b>{(freq[value.codigo_frecuencia])?freq[value.codigo_frecuencia]:'sin definir'}<br/>
                                                {
                                                    (indexes.length>0) ? 
                                                    <>
                                                        <hr/><b>Última actividad</b><br/>{listaActividades[indexes[0]].descripcion} - documento generado: {listaActividades[indexes[0]].documento_generado}<br/>fecha trabajada: {getDateFormat(listaActividades[indexes[0]].fecha_creacion)}<br/>
                                                    </> : <></>
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                })
                            }
                        </Accordion>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Box>
        </>
    )
} 