import React, { useState, useEffect } from "react";
import './rolUsuario.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Button, Modal, Row, Col } from "react-bootstrap";

// HTTP Requests
import Axios from 'axios';

export const UpdateRol = (props) => {

    const [validated, setValidated] = useState(false);
    const [rolNombre, setRolNombre] = useState('');
    const [instNombre, setInstNombre] = useState('');


    const cancelNew = () => {
        props.setFin('');
        setValidated(false);
        props.setShowNew(false);
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            setValidated(true);
        } else {
            if (checkDates(props.inicio, props.fin)){
                submitPersona();
            } else {
                toast.error('La fecha de inicio no puede ser más reciente a la final')
            }
        }
        setValidated(true);
      };

      const submitPersona = () => {
        Axios.post("/api/update/rolUsuario/admin", {
            inicio: props.inicio,
            fin: props.fin,
            id: props.id
        },{headers:{"x-access-token": localStorage.getItem('tokenAdminSys')}}).then((response) => {
            if(response.data.auth === false){} else {
                if (response.data.error){toast.error('Error al actualizar')} else {
                    //Lista de usuarios con sus roles
                    Axios.get('/api/get/rolesUsuarios', 
                    {headers:{"x-access-token": localStorage.getItem('tokenAdminSys')}}).then((response)=>{
                        if(response.data.auth === false){} else{
                            props.setListaPrin(response.data);
                            props.setListaSec(response.data);
                        }
                    });
                    toast.success('Rol actualizado');
                    setValidated(false);
                    props.setInicio('');
                    props.setFin('');
                    props.setShowNew(false);
                }        
            }    
        });
    };

    const checkDates = (date1, date2) => {
        try {
            const instancedate1 = new Date(date1);
            const instancedate2 = new Date(date2);
            if (instancedate1.valueOf() < instancedate2.valueOf()){
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        } 
    }
    
    useEffect(()=>{
        try {
            setRolNombre(props.roles.find(Element => Element.CODIGO_ROL === props.rol)['NOMBRE'])
        } catch (error) {
            setRolNombre('')
        }
        try {
            setInstNombre(props.instituciones.find(Element => Element.CODIGO === props.institucion)['NOMBRE'])
        } catch (error) {
            setInstNombre('')
        }
    }, [props.rol])

    return(
        <Modal show={props.showNew} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
                <Modal.Title>ASIGNAR NUEVO ROL A USUARIO</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                            <Form.Label>Institución</Form.Label>
                            <Form.Control
                                disabled
                                placeholder={instNombre}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md="12" controlId="validationCustom02">
                            <Form.Label>Persona</Form.Label>
                            <Form.Control
                                disabled
                                placeholder={props.nombre}
                            />
                        </Form.Group>
                    </Row>
                    <p/>
                    <Row>
                        <Form.Group as={Col} md="12" controlId="validationCustom02">
                            <Form.Label>Rol</Form.Label>
                            <Form.Control
                                disabled
                                placeholder={rolNombre}
                            /> 
                        </Form.Group>
                    </Row>
                    <p/>
                    <Row>
                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label>Fecha de inicio de vigencia</Form.Label>
                            <Form.Control
                                required
                                type='date'
                                onChange={(e)=>{props.setInicio(e.target.value)}}
                                value={props.inicio}
                            />
                            <Form.Control.Feedback type="invalid">
                                Indique fecha de inicio de vigencia    
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label>Fecha de fin de vigencia</Form.Label>
                            <Form.Control
                                required
                                type='date'
                                onChange={(e)=>{props.setFin(e.target.value)}}
                                value={props.fin}
                            />
                            <Form.Control.Feedback type="invalid">
                                Indique fecha de fin de vigencia
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <hr/>
                    <Button type="submit" variant="success" size='sm'>Guardar</Button>
                    <Button variant="secondary" size='sm' onClick={()=>{cancelNew();}}>Cancelar</Button>
                </Form>                
            </Modal.Body>
        </Modal>
    )

}