import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { Stack } from 'react-bootstrap'

function AdvertenciaPeriodo(props) {
    
    const [flag, setFlag] = useState(false)

    useEffect(()=>{
        const anio = Date().split(' ')
        setFlag((anio[3]) === String(props.fechaPeriodo.substring(0,4))?true:false)
    },[props.fechaPeriodo])
    
    return (
        <Box sx={{textAlign: 'center', width: '100%'}}>
            <Stack sx={{justifyContent: 'center', alignItems:'center'}} spacing={1} direction='column' >
                <Typography variant={(flag)?'h6':'h5'} color={(flag)?'black':'red'} sx={{fontWeight: 'bold'}} >
                    Este período pertenece al del año {props.fechaPeriodo.substring(0,4)} {(flag)?'.':
                    '. La información que cambie seguirá afectando los pdfs que imprima de este período.'}
                </Typography>
            </Stack>
        </Box>
    )
}

export default AdvertenciaPeriodo