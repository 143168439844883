import { Box } from '@mui/material';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EditarRol(props) {

    const [validated, setValidated] = useState(false);
    const [activo, setActivo] = useState(0)
    const [fi, setFi] = useState('')
    const [ff, setFf] = useState('')

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            setValidated(true);
        } else {
            Axios.post('/api/update/adminusuarioactualizarrol', {
                fi: fi,
                ff: ff,
                activo: activo,
                id: props.rol.CODIGO_ROL_USUARIO
            },
            {headers:{"x-access-token": localStorage.getItem('token')}})
            .then((response)=>{
                if(response.data.result){
                    toast.success('Se ha editado con exito.')
                    props.refresh()
                    props.cerrarEditar()
                } else {
                    toast.error('Ha habido un error al editar.')
                }
            })
        }
        setValidated(true);
    };

    useEffect(()=>{
        if(props.rol.FECHA_FIN_VIGENCIA !== ''){
            setFf(props.rol.FECHA_FIN_VIGENCIA.substring(0, 10))
        }
        if(props.rol.FECHA_INI_VIGENCIA !== ''){
            setFi(props.rol.FECHA_INI_VIGENCIA.substring(0,10))
        }
        if(props.rol.ACTIVO === 1){
            setActivo(1)
            document.getElementById("activo").checked = true;
        }
    },[props.rol])

    return (
        <Box borderRadius={3} border={1} p={2} >
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                        <Form.Label>Rol a editar: {props.nombre}</Form.Label>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                        <Form.Label>Fecha de inicio de vigencia</Form.Label>
                        <Form.Control value={fi} type="date" required onChange={(e)=>setFi(e.target.value)}/>
                        <Form.Control.Feedback type="invalid">
                            Ingrese fecha de inicio de vigencia.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom04">
                        <Form.Label>Fecha de fin de vigencia</Form.Label>
                        <Form.Control value={ff} type="date" required onChange={(e)=>setFf(e.target.value)}/>
                        <Form.Control.Feedback type="invalid">
                            Ingrese fecha de fin de vigencia.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom05">
                        <Form.Label>Activo</Form.Label>
                            <Form.Check id="activo" value={1} label={(activo)?'Rol activado':"Rol desactivado"}
                                onClick={(e)=>{
                                    if(activo === 0){
                                        setActivo(1); 
                                    } else {
                                        setActivo(0); 
                                    }
                                }}
                            />
                    </Form.Group>
                </Row>
                <Button variant='success' size='small' type="submit">Guardar</Button>
                <Button variant='secondary' size='small' onClick={props.cerrarEditar}>Cancelar</Button>
            </Form>
        </Box>
    )
    
}

export default EditarRol