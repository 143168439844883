// Eventos.jsx
import React, { useState, useEffect } from 'react';
import { Table, Button, Row, Col ,Dropdown, DropdownButton} from 'react-bootstrap';
import CrearSubevento from './CrearSubevento';
import EditarSubevento from './EditarSubevento';
import ReporteRiesgo from './ReporteRiesgo'; // Asegúrate de importar ReporteRiesgo
import ReportePlanDeTrabajo from './ReportePlanTrabajo';
import axios from 'axios';

const Eventos = ({ riesgo }) => {
    const [eventos, setEventos] = useState([]);
    const [showCrear, setShowCrear] = useState(false);
    const [showEditar, setShowEditar] = useState(false);
    const [eventoActual, setEventoActual] = useState(null);

    const fetchEventos = async () => {
        try {
            const response = await axios.get(`/api/get/eventos/${riesgo.CODIGO_CIA}/${riesgo.CODIGO_PERIODO}/${riesgo.CODIGO_TIPO_OBJETIVO}/${riesgo.CODIGO_OBJETIVO}/${riesgo.CODIGO_AREA}/${riesgo.CODIGO_RIESGO}`, {
                headers: { "x-access-token": localStorage.getItem('token') }
            });
            setEventos(response.data.result || []);
        } catch (error) {
            console.error("Error fetching eventos", error);
            setEventos([]);
        }
    };

    useEffect(() => {
        if (riesgo) {
            fetchEventos();
        }
    }, [riesgo]);

    const handleCrear = async (evento) => {
        try {
            await axios.post('/api/createEventoSubRiesgo', evento, {
                headers: { "x-access-token": localStorage.getItem('token') }
            });
            fetchEventos(); // Refrescar la lista de eventos después de crear uno nuevo
        } catch (error) {
            console.error("Error creating evento", error);
        }
    };

    const handleEditar = async (eventoEditado) => {
        try {
            await axios.put('/api/updateEvento', eventoEditado, {
                headers: { "x-access-token": localStorage.getItem('token') }
            });
            fetchEventos(); // Refrescar la lista de eventos después de editar uno
        } catch (error) {
            console.error("Error editing evento", error);
        }
    };

    const getNivelTolerancia = (codigoTolerancia) => {
        switch (codigoTolerancia) {
            case 1:
                return { text: 'No Tolerable', color: '#3FA535' };
            case 2:
                return { text: 'Gestionable', color: '#FFED00' };
            case 3:
                return { text: 'Tolerable', color: '#CD1719' };
            default:
                return { text: 'Desconocido', color: 'gray' };
        }
    };

    
    const getSeveridadRiesgo = (codigoSeveridad) => {
        switch (codigoSeveridad) {
            case 1:
                return { text: 'Baja', color: '#3FA535' };
            case 2:
                return { text: 'Media', color: '#FFED00' };
            case 3:
                return { text: 'Alta', color: '#CD1719' };
            default:
                return { text: 'Desconocido', color: 'gray' };
        }
    };

    const getDateFormat = (tmpDate) => {
        let firstDate = new Date(tmpDate);
        let options = { day: '2-digit', month: '2-digit', year: '2-digit', timeZone: "GMT" };
        return String(firstDate.toLocaleDateString('es-ES', options))
    }

    return (
        <div style={{ overflowX: 'auto' }}>
            <br />
            <Row className="mb-3">
                <Col>
                    <Button variant="success" onClick={() => setShowCrear(true)}>
                        Crear Evento
                    </Button>
                </Col>
                <Col>
            <DropdownButton id="dropdown-basic-button" title="Reportes">
                <Dropdown.Item>
                    <ReporteRiesgo riesgo={riesgo} eventos={eventos} />
                </Dropdown.Item>
                <Dropdown.Item>
                    <ReportePlanDeTrabajo riesgo={riesgo} eventos={eventos} />
                </Dropdown.Item>
            </DropdownButton>
        </Col>
            </Row>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th style={{ minWidth: '80px' }}>Acciones</th>
                        <th style={{ minWidth: '120px' }}>Sub Tema</th>
                        <th style={{ minWidth: '80px' }}>Nivel de Tolerancia</th>
                        <th style={{ minWidth: '150px' }}>Método Monitoreo</th>
                        <th style={{ minWidth: '120px' }}>Frecuencia Monitoreo</th>
                        <th style={{ minWidth: '100px' }}>Responsable</th>
                        <th style={{ minWidth: '100px' }}>Severidad del Riesgo</th>
                        <th style={{ minWidth: '150px' }}>Control Implementado</th>
                        <th style={{ minWidth: '150px' }}>Recursos</th>
                        <th style={{ minWidth: '100px' }}>Fecha Inicio</th>
                        <th style={{ minWidth: '100px' }}>Fecha Final</th>
                    </tr>
                </thead>
                <tbody>
                    {eventos.length > 0 ? (
                        eventos.map((evento) => {
                            const nivelTolerancia = getNivelTolerancia(evento.CODIGO_TOLERANCIA);
                            const severidadRiesgo = getSeveridadRiesgo(evento.CODIGO_SEVERIDAD);

                            return (
                                <tr key={evento.CODIGO_EVENTO}>
                                    <td>
                                        <Button variant="primary" size="sm" onClick={() => { setEventoActual(evento); setShowEditar(true); }}>Editar</Button>
                                    </td>
                                    <td>{evento.SUB_TEMA || ''}</td>
                                    <td style={{ backgroundColor: nivelTolerancia.color }}>{nivelTolerancia.text}</td>
                                    <td>{evento.METODO_MONITOREO || ''}</td>
                                    <td>{evento.FRECUENCIA_MONITOREO || ''}</td>
                                    <td>{evento.RESPONSABLE || ''}</td>
                                    <td style={{ backgroundColor: severidadRiesgo.color }}>{severidadRiesgo.text}</td>
                                    <td>{evento.CONTROL_IMPL || ''}</td>
                                    <td>{evento.RECURSOS || ''}</td>
                                    <td>{getDateFormat(evento.FECHA_INICIO) || ''}</td>
                                    <td>{getDateFormat(evento.FECHA_FINAL) || ''}</td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan="11" className="text-center">No hay eventos disponibles</td>
                        </tr>
                    )}
                </tbody>
            </Table>

            <CrearSubevento show={showCrear} handleClose={() => setShowCrear(false)} handleSave={handleCrear} riesgo={riesgo} />
            {eventoActual && (
                <EditarSubevento show={showEditar} handleClose={() => setShowEditar(false)} handleSave={handleEditar} subevento={eventoActual} />
            )}
        </div>
    );
};

export default Eventos;
