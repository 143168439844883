import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';

const PersonaResponsableComboBox = ({ onChange, value }) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/get/PersonaResponsable', {
          headers: { "x-access-token": localStorage.getItem("token") },
        });
        const data = Array.isArray(response.data) ? response.data : response.data.data; // Ajusta esto según la estructura real
        const formattedData = data.map(item => ({
          value: item.CODIGO,
          label: item.NOMBRE,
        }));
        setOptions(formattedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (value) {
      const selected = options.find(option => option.value === value);
      setSelectedOption(selected || null);
    }
  }, [value, options]);

  const handleChange = (selected) => {
    setSelectedOption(selected);
    onChange(selected ? selected.value : '');
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      options={options}
      isClearable
      placeholder="Selecciona una persona responsable..."
    />
  );
};

export default PersonaResponsableComboBox;
