import React, { useState, useEffect } from "react";
import {Table} from 'react-bootstrap';
import './formulario.css'
import {AreaEvaluacion} from './areasEvaluacion'
import {Dependencias} from './dependencia'
import UpdatedNavBar from "../NavBar/UpdatedNavBar";

// HTTP Requests
import Axios from 'axios';

export const DependenciasView = () => {

    /**
     * States de dependencias
     */
    const [nombreDep, setNombreDep] = useState('');
    const [descrDep, setDescrDep] = useState('');
    const [siglasDep, setSiglasDep] = useState('');
    const [idDep, setIdDep] = useState('');

    /**
     * nombre usuario
     */
    const [usuarioWeb, setUsuarioWeb] = useState('');

    useEffect(()=> {
        Axios.get("/api/getuserweb",
        {headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=> {
            setUsuarioWeb(response.data.usuarioWeb);
        });
    }, [])

    /**
     * Bandera para área de evaluación
     */
    const [dependenciaFlag, setDependenciaFlag] = useState(true);

    return(
        <>
            <UpdatedNavBar />
            <div className="container-dependencias">
                {
                    (dependenciaFlag) ?
                    <Dependencias
                        nombreDep={nombreDep} setNombreDep={setNombreDep} 
                        descrDep={descrDep} setDescrDep={setDescrDep} 
                        siglasDep={siglasDep} setSiglasDep={setSiglasDep}
                        idDep={idDep} setIdDep={setIdDep}
                        setDependenciaFlag={setDependenciaFlag}
                        usuarioWeb={usuarioWeb}
                    /> : 
                    <>
                        <h2>Dependencia</h2 >
                        <p className="lead blog-description">Dependencias de la organización</p>
                        
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Descripción</th>
                                    <th>Siglas</th>
                                </tr> 
                                <tr className="segunda-fila">
                                    <td>{nombreDep}</td>
                                    <td>{descrDep}</td>
                                    <td>{siglasDep}</td>
                                </tr>
                            </thead>
                        </Table>
                        {/**
                         * Botón para crear nueva dependencia
                         */}
                        <hr/>
                    </>
                }
            </div>
        
        </>
    )

}
