import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Tab, Tabs, Row, Col } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { DownloadAndUpdate } from "../../../../Components/donwloadAndUpdate";
import { UploadFile } from "../../../../Components/uploadFile";
import RiesgosInforme from './RiesgoInforme';

const EditarRiesgoModal = ({ isOpen, onClose, riesgo, codigoCia, codigoPeriodo, codigoTipoObjetivo, codigoObjetivo, codigoArea, descripcionPeriodo, descripcionTipObj, descripcionObjetivo, descripcionArea }) => {
    const [formData, setFormData] = useState({
        CODIGO_RIESGO: '',
        DESCRIPCION: '',
        CODIGO_PROBABILIDAD: '',
        CODIGO_SEVERIDAD: '',
        CODIGO_FRECUENCIA: '',
        ADAPTABILIDAD: '',
        CODIGO_MITIGACION: '',
        CODIGO_TOLERANCIA: '',
        DESC_CONTINT_MITIGACION: '',
        DESC_FRECUENCIA_MONITOREO: '',
        DESC_METODO_MONITOREO: '',
        DESC_MITIGACION: '',
        CODIGO_ENCARGADO: '',
        ESTADO_SUPERVISION: '',
        EVENTOS: '',
        FECHA_FIN_MONITOREO: '',
        FECHA_INI_MONITOREO: '',
        FECHA_ULTIMA_SUPERVISION: '',
        NIVEL_CAPACIDAD_MITIGACION: '',
        NOMBRE_DOCUMENTO: '',
        NOMBRE_ARCHIVO: '',
        NOMBRE_DOCUMENTO_2: '',
        NOMBRE_ARCHIVO_2: '',
        OBSERVACIONES: '',
        OBSERVACIONES_INFORME: '',
        COMENTARIOS_SUPERVISOR: '',
        RESPONSABLE_DELEGADO: '',
        CODIGO_SUPERVISOR: '',
        VARIABLE_MITIGACION: '' // Nuevo campo para variable a mitigar
    });

    const [listas, setListas] = useState({
        probabilidad: [],
        tolerancia: [],
        severidad: [],
        mitigacion: [],
        capacidadMitigacion: []
    });

    const fetchRiesgoById = async () => {
        if (riesgo) {
            try {
                const response = await axios.get('/api/getRiesgoById', {
                    headers: { "x-access-token": localStorage.getItem('token') },
                    params: {
                        codigoCia: riesgo.CODIGO_CIA,
                        codigoPeriodo: riesgo.CODIGO_PERIODO,
                        codigoTipoObjetivo: riesgo.CODIGO_TIPO_OBJETIVO,
                        codigoObjetivo: riesgo.CODIGO_OBJETIVO,
                        codigoArea: riesgo.CODIGO_AREA,
                        codigoRiesgo: riesgo.CODIGO_RIESGO
                    }
                });
                const data = response.data;
                setFormData({
                    CODIGO_RIESGO: data.CODIGO_RIESGO || '',
                    DESCRIPCION: data.DESCRIPCION || '',
                    CODIGO_PROBABILIDAD: data.CODIGO_PROBABILIDAD || '',
                    CODIGO_SEVERIDAD: data.CODIGO_SEVERIDAD || '',
                    CODIGO_FRECUENCIA: data.CODIGO_FRECUENCIA || '',
                    ADAPTABILIDAD: data.ADAPTABILIDAD || '',
                    CODIGO_MITIGACION: data.CODIGO_MITIGACION || '',
                    CODIGO_TOLERANCIA: data.CODIGO_TOLERANCIA || '',
                    DESC_CONTINT_MITIGACION: data.DESC_CONTINT_MITIGACION || '',
                    DESC_FRECUENCIA_MONITOREO: data.DESC_FRECUENCIA_MONITOREO || '',
                    DESC_METODO_MONITOREO: data.DESC_METODO_MONITOREO || '',
                    DESC_MITIGACION: data.DESC_MITIGACION || '',
                    CODIGO_ENCARGADO: data.CODIGO_ENCARGADO || '',
                    ESTADO_SUPERVISION: data.ESTADO_SUPERVISION || '',
                    EVENTOS: data.EVENTOS || '',
                    FECHA_FIN_MONITOREO: data.FECHA_FIN_MONITOREO || '',
                    FECHA_INI_MONITOREO: data.FECHA_INI_MONITOREO || '',
                    FECHA_ULTIMA_SUPERVISION: data.FECHA_ULTIMA_SUPERVISION || '',
                    NIVEL_CAPACIDAD_MITIGACION: data.NIVEL_CAPACIDAD_MITIGACION || '',
                    NOMBRE_DOCUMENTO: data.NOMBRE_DOCUMENTO || '',
                    NOMBRE_ARCHIVO: data.NOMBRE_ARCHIVO || '',
                    NOMBRE_DOCUMENTO_2: data.NOMBRE_DOCUMENTO_2 || '',
                    NOMBRE_ARCHIVO_2: data.NOMBRE_ARCHIVO_2 || '',
                    OBSERVACIONES: data.OBSERVACIONES || '',
                    OBSERVACIONES_INFORME: data.OBSERVACIONES_INFORME || '',
                    COMENTARIOS_SUPERVISOR: data.COMENTARIOS_SUPERVISOR || '',
                    RESPONSABLE_DELEGADO: data.RESPONSABLE_DELEGADO || '',
                    CODIGO_SUPERVISOR: data.CODIGO_SUPERVISOR || '',
                    VARIABLE_MITIGACION: data.VARIABLE_MITIGACION || '', // Añadir variable mitigación
                    DESCRIPCION_TIPO_OBJETIVO: data.DESCRIPCION_TIPO_OBJETIVO || '',
                    DESCRIPCION_OBJETIVO: data.DESCRIPCION_OBJETIVO || '',
                    DESCRIPCION_AREA: data.DESCRIPCION_AREA || '',
                });
                console.log("Datos obtenidos2:", response.data);
            } catch (error) {
                console.error("Error fetching data", error);
            }
        }
    };

    const fetchListas = async () => {
        try {
            const responses = await Promise.all([
                axios.get("/api/getRiesgos_riesgo/Probabilidad", { headers: { "x-access-token": localStorage.getItem('token') } }),
                axios.get("/api/getRiesgos_riesgo/Tolerancia", { headers: { "x-access-token": localStorage.getItem('token') } }),
                axios.get("/api/getRiesgos_riesgo/Severidad", { headers: { "x-access-token": localStorage.getItem('token') } }),
                axios.get("/api/getRiesgos_riesgo/Mitigacion", { headers: { "x-access-token": localStorage.getItem('token') } }),
                axios.get("/api/getRiesgos_riesgo/ValorMitigacion", { headers: { "x-access-token": localStorage.getItem('token') } })
            ]);
            setListas({
                probabilidad: Array.isArray(responses[0].data) ? responses[0].data : [],
                tolerancia: Array.isArray(responses[1].data) ? responses[1].data : [],
                severidad: Array.isArray(responses[2].data) ? responses[2].data : [],
                mitigacion: Array.isArray(responses[3].data) ? responses[3].data : [],
                capacidadMitigacion: Array.isArray(responses[4].data) ? responses[4].data : []
            });
            console.log("Lista de capacidad de mitigación:", responses[4].data);
        } catch (error) {
            console.error("Error fetching listas data", error);
        }
    };

    useEffect(() => {
        if (isOpen) {
            fetchRiesgoById();
            fetchListas();
        }
    }, [isOpen]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        if (['FECHA_INI_MONITOREO', 'FECHA_FIN_MONITOREO', 'ESTADO_SUPERVISION'].includes(name)) {
            setFormData({ ...formData, FECHA_ULTIMA_SUPERVISION: new Date().toISOString().split('T')[0] });
        }
    };

    const [cambioInforme, setCambioInforme] = useState(''); // Controla si se está 
    const [codigoInforme, setCodigoInforme] = useState(''); // Controla si se está 
    const handleSave = async () => {
        try {
            await axios.put('/api/update/riesgo', {
                codigoCia,
                codigoPeriodo,
                codigoTipoObjetivo,
                codigoObjetivo,
                codigoArea,
                codigoRiesgo: formData.CODIGO_RIESGO,
                ...formData,
                CODIGO_ENCARGADO: parseInt(formData.CODIGO_ENCARGADO) || null,
                RESPONSABLE_DELEGADO: parseInt(formData.RESPONSABLE_DELEGADO) || null,
                CODIGO_SUPERVISOR: parseInt(formData.CODIGO_SUPERVISOR) || null,
                codigoInforme,
                cambioInforme,
                tipo: 'responsable'
            }, {
                headers: { "x-access-token": localStorage.getItem('token') }
            });

            console.log("Datos del formulario guardados correctamente:", formData);
            onClose(); // Cerrar el modal después de guardar
        } catch (error) {
            console.error("Error al guardar los datos del formulario:", error);
        }
    };

    const handleValor = (codInforme,editedValue) => {
        setCambioInforme(editedValue);
        setCodigoInforme(codInforme);
        //handleSave();
    };

    
    return (
        <Modal show={isOpen} onHide={onClose} size="lg" dialogClassName="modal-dialog" style={{ marginTop: '50px' }}>
            <Modal.Header closeButton>
                <Modal.Title>Detalles del Riesgo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="mb-3 justify-content-center text-center">
                    <Col xs="auto" className="mx-2">
                        <Form.Label><strong>Período:</strong> {codigoPeriodo}</Form.Label>
                    </Col>
                    <Col xs="auto" className="mx-2">
                        <Form.Label><strong>Tipo Objetivo:</strong> {formData.DESCRIPCION_TIPO_OBJETIVO} </Form.Label>
                    </Col>
                    <Col xs="auto" className="mx-2">
                        <Form.Label><strong>Objetivo:</strong> {formData.DESCRIPCION_OBJETIVO} </Form.Label>
                    </Col>
                    <Col xs="auto" className="mx-2">
                        <Form.Label><strong>Área:</strong> {formData.DESCRIPCION_AREA}</Form.Label>
                    </Col>
                    <Col xs="auto" className="mx-2">
                        <Form.Label><strong>Código Riesgo:</strong> {codigoTipoObjetivo + "-" + formData.CODIGO_RIESGO} </Form.Label>
                    </Col>

                </Row>

                <Tabs defaultActiveKey="informe" id="detalle-riesgo-tabs">
                    <Tab eventKey="informe" title="Informe">
                        <br />
                        <Form.Group controlId="NOMBRE_DOCUMENTO">
                            <Form.Label>Nombre Informe</Form.Label>
                            <Form.Control type="text" name="NOMBRE_DOCUMENTO" value={formData.NOMBRE_DOCUMENTO} onChange={handleInputChange} />
                        </Form.Group>

                        {
                            (String(formData.NOMBRE_ARCHIVO).trim() === '') ?
                                <UploadFile inputId={'input' + formData.CODIGO_RIESGO} codigo={formData.CODIGO_RIESGO}
                                    URL={'/upload-informe-file'} refresh={() => { }} maxSize={50000000}
                                />
                                :
                                <DownloadAndUpdate inputId={'input' + formData.CODIGO_RIESGO} codigo={formData.CODIGO_RIESGO}
                                    both={true} original={formData.NOMBRE_ARCHIVO} path={formData.NOMBRE_ARCHIVO} downloadURL={'/download-activity-file'}
                                    mimeType={'application/pdf'} URL={'/update-informe-file'} refresh={() => { }} maxSize={50000000}
                                />
                        }
                        <Form.Group controlId="OBSERVACIONES_INFORME">
                            <br />
                            <Form.Label>Observaciones informe</Form.Label>
                            <Form.Control as="textarea" rows={3} name="OBSERVACIONES_INFORME" value={formData.OBSERVACIONES_INFORME} onChange={handleInputChange} />
                        </Form.Group>
                    </Tab>

                    <Tab eventKey="historico-informe" title="Histórico Informe">
                        <RiesgosInforme codigoCia={riesgo.CODIGO_CIA} codigoRiesgo={formData.CODIGO_RIESGO} onSave={handleValor} />
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditarRiesgoModal;
