import React, { useState } from 'react';
import UpdatedNavBar from '../NavBar/UpdatedNavBar';
import { Stack, Button } from '@mui/material';
import TablaDatosTipoObjetivo from './Componentes/TablaDatosTipoobjetivo';
import ObjetivoModal from './Componentes/ModalInsertarObjetivos';
import { AiOutlinePlus } from 'react-icons/ai';
import '../Dependencias/formulario.css';
import SearchPeriodo from './searchPeriodo'; // Asegúrate de importar el componente correctamente

export const RiesgosRiesgoPeriodoView = () => {
    const [periodo, setPeriodo] = useState('');
    const [descripcionPeriodo, setDescripcionPeriodo] = useState('');
    const [tipObj, setTipObj] = useState('');
    const [descripcionTipObj, setDescripcionTipObj] = useState('');
    const [selectedObjective, setSelectedObjective] = useState('');
    const [descripcionObjetivo, setDescripcionObjetivo] = useState('');
    const [selectedArea, setSelectedArea] = useState('');
    const [descripcionArea, setDescripcionArea] = useState('');
    const [reloadTrigger, setReloadTrigger] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const reloadData = () => {
        setReloadTrigger(prev => prev + 1);
    };

    return (
        <>
            <UpdatedNavBar />
            <div className="container-dependencias">
                <Stack spacing={1}>
                    <Stack>
                        <h2>Asignación de Riesgos</h2>
                        <p className="lead blog-description">Asignación de Riesgos</p>
                        <hr />
                    </Stack>

                    <SearchPeriodo 
                        setPeriodo={(value, description) => {
                            setPeriodo(value);
                            setDescripcionPeriodo(description);
                        }} 
                        setTipObj={(value, description) => {
                            setTipObj(value);
                            setDescripcionTipObj(description);
                        }} 
                        tipObj={tipObj} 
                        periodo={periodo} 
                        setSelectedObjective={(value, description) => {
                            setSelectedObjective(value);
                            setDescripcionObjetivo(description);
                        }}
                        setSelectedArea={(value, description) => {
                            setSelectedArea(value);
                            setDescripcionArea(description);
                        }} 
                    />

                    <div className='md-col-3'>
                        <Button
                            size='sm'
                            style={{
                                backgroundColor: '#28a745',
                                borderColor: '#28a745',
                                color: 'white',
                                padding: '0.25rem 0.5rem',
                                fontSize: '0.8rem'
                            }}
                            variant="success"
                            className="nueva-dependencia"
                            onClick={openModal}
                        >
                            <AiOutlinePlus /> Crear Riesgo
                        </Button>
                    </div>
                    <TablaDatosTipoObjetivo 
                        TipoObjetivo={tipObj} 
                        CodigoPeriodo={periodo} 
                        CodigoObjetivo={selectedObjective} 
                        CodigoArea={selectedArea} 
                        reloadTrigger={reloadTrigger} 
                        descripcionTipObj={descripcionTipObj}
                        descripcionArea={descripcionArea}
                        descripcionObjetivo={descripcionObjetivo}
                    />
                </Stack>
            </div>
            <ObjetivoModal
                isOpen={isModalOpen}
                onClose={closeModal}
                periodo={periodo}
                tipoObjetivo={tipObj}
                codigoObjetivo={selectedObjective}
                codigoArea={selectedArea}
                onInsertSuccess={reloadData}
                descripcionPeriodo={descripcionPeriodo}
                descripcionTipoObjetivo={descripcionTipObj}
                descripcionObjetivo={descripcionObjetivo}
                descripcionArea={descripcionArea}
            />
        </>
    );
};
