
import React, { useState, useEffect } from 'react';
import SinacigAcc from './SinacigAcc.jsx';

// HTTP Requests
import Axios from 'axios';
import UpdatedNavBar from '../NavBar/UpdatedNavBar';

function ReportesView() {

    const [periodos, setPeriodos] = useState([]);

    useEffect(() => {
        Axios.get("/api/get/periodos-area", { headers: { "x-access-token": localStorage.getItem('token') } })
            .then((responseGet1) => {
                setPeriodos(responseGet1.data);
            });
    }, [])

    return (
        <>
            <UpdatedNavBar />
            <div className="container-reportes">
                <h2>Reportes</h2 >
                <SinacigAcc periodos={periodos} />
            </div>
        </>
    );
}

export default ReportesView