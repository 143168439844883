import React, { useState, useEffect } from "react";
import './formulario.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Button, Modal, Row, Col, InputGroup, ListGroup  } from "react-bootstrap";

// HTTP Requests
import Axios from 'axios';

export const CrearPeriodos = (props) => {

    const [validated, setValidated] = useState(false);
    const [anio, setAnio] = useState('');
    const [listaAreas, setListaAreas] = useState([]);
    const [areas, setAreas] = useState([]);
    const [repetidas, setRepetidas] = useState([])


    const cancelNew = () => {
        setRepetidas([])
        setValidated(false);
        setAnio('');
        setAreas([]);
        props.setNewPeriodo(false);
    };

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            setValidated(true);
        } else {
            submitPeriodos();
        }
        setValidated(true);
    };

    const submitPeriodos = () => {
        const params = {
            areas: areas,
            anio: anio  
        };
        Axios.post('/api/post/variosPeriodos', params, {headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=>{
            if (response.data.error) {
                toast.error('Error al copiar los datos.');
            } else {
                toast.info(response.data.msg)
            }
        })
    }

    return(
        <Modal show={props.showNewPeriodo} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
                <Modal.Title>Crear periodos nuevos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    (repetidas.length === 0)?
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row>
                            <Form.Group as={Col} md="2">
                                <Form.Label>Año del periodo:</Form.Label>
                                <Form.Control required type="number" min="1900" max="3099" step="1" onChange={(e)=>{setAnio(e.target.value)}}/>
                            </Form.Group>
                            <Form.Group as={Col} md="10" controlId="validationCustom02">
                                <Form.Label>Seleccione las áreas que se le asignará el nuevo periodo:</Form.Label>
                                <InputGroup>
                                <Button variant='outline-secondary' 
                                    onClick={()=>{
                                        let newArray = []
                                        listaAreas.forEach((value) => {
                                            newArray.push(value.CODIGO);
                                        })
                                        setAreas(newArray);
                                    }}
                                >Seleccionar todas las áreas</Button>
                                <Form.Select
                                    value={areas}
                                    
                                    onChange={(e)=>{
                                        let value = Array.from(e.target.selectedOptions, option => option.value);
                                        setAreas(value);
                                    }}
                                    multiple
                                >
                                    {
                                        listaAreas.map((value) => {
                                            return(
                                                <option key={value.CODIGO} value={value.CODIGO}>
                                                {value.padre} - {value.NOMBRE} ({value.SIGLAS})
                                                </option>
                                            )
                                        })
                                    }
                                </Form.Select>
                                </InputGroup> 
                            </Form.Group>
                        </Row>
                        <hr/>
                        <p className="text-info ng-scope">Por defecto los periodos se crearan del 1 de enero al 31 de diciembre del año que usted ingrese.</p>
                        <ListGroup as='ol' numbered>

                        </ListGroup>
                        <Button type="submit" variant="success" size='sm'>Guardar</Button>
                        <Button variant="secondary" size='sm' onClick={()=>{cancelNew();}}>Cancelar</Button>
                    </Form>:
                    <>
                        <h5>Estas áreas ya contaban con este período creado:</h5>
                        <ListGroup variant="flush" as='ol' numbered>
                            {
                                repetidas.map((area, indice)=>{
                                    return(
                                        <ListGroup.Item as='li' key={indice} > {area.NOMBRE} ({area.SIGLAS})</ListGroup.Item>
                                    )
                                })
                            }
                        </ListGroup>
                        <p/>
                        <Button variant="info" size='sm' onClick={()=>{cancelNew();}}>Regresar</Button>
                    </>
                }
                                
            </Modal.Body>
        </Modal>
    )

}