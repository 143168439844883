import React, { useState, useEffect } from "react";
import './instituciones.css'
import { NavBar } from '../NavBarAdmin/navBar';
import { Table, Button, InputGroup, Form } from "react-bootstrap";
import { AiFillEdit, AiOutlinePlus, AiFillDelete, AiOutlineRollback, AiOutlineSearch } from 'react-icons/ai';
import { BsCheckCircle, BsXCircle } from 'react-icons/bs'
import { FaTrashRestoreAlt } from 'react-icons/fa';
import { CrearEntidad } from "./crearEntidad";
import { CrearDependencia } from "./crearDependencia";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// HTTP Requests
import Axios from 'axios';

export const Instituciones = () => {

    const [lista, setLista] = useState([]);
    const [listaSec, setListaSec] = useState([]);
    const [listaD, setListaD] = useState([]);
    const [listaSecD, setListaSecD] = useState([]);
    const [showD, setShowD] = useState(false);
    const [showE, setShowE] = useState(false);
    const [eView, setEView] = useState(true);
    const [nombre, setNombre] = useState('');
    const [siglas, setSiglas] = useState('');
    const [codigo, setCodigo] = useState('');
    const [estado, setEstado] = useState('');
    const [modalTitulo, setModalTitulo] = useState('');
    const [modalNombre, setModalNombre] = useState('');
    const [modalSiglas, setModalSiglas] = useState('');
    const [modalCodigo, setModalCodigo] = useState('');

    useEffect(() => {
        refreshEntidades()
    }, []);

    const getDependencias = (id) => {
        Axios.get('/api/get/DependenciasAdmin/' + String(id),
            { headers: { "x-access-token": localStorage.getItem('tokenAdminSys') } }).then(
                (response) => {
                    if (response.data.error) { } else {
                        setListaD(response.data.result);
                        setListaSecD(response.data.result);
                    }
                }
            );
    }

    const deleteEntidad = (id, secondFunction, param) => {
        Axios.get('/api/get/deleteEntidadAdmin/' + String(id),
            { headers: { "x-access-token": localStorage.getItem('tokenAdminSys') } }).then(
                (response) => {
                    if (response.data.error) { toast.error('Ha ocurrido un error durante el proceso') } else {
                        toast.success('Se ha eliminado con exito')
                        if (param) {
                            secondFunction(param)
                        } else {
                            secondFunction()
                        }
                    }
                }
            );
    }

    const refreshEntidades = () => {
        Axios.get('/api/get/InstitucionesAdmin', { headers: { "x-access-token": localStorage.getItem('tokenAdminSys') } }).then(
            (response) => {
                if (response.data.error) { } else {
                    setLista(response.data.result);
                    setListaSec(response.data.result);
                }
            }
        );
    }

    const restoreEntidad = (id, refresh) => {
        const opts = {
            codigo: id,
        }
        Axios.post('/api/restore/entidad', opts, { headers: { "x-access-token": localStorage.getItem('tokenAdminSys') } })
            .then(
                (response) => {
                    if (response.data.error) {
                        toast.error('Error al restaurar entidad');
                    } else if (response.data.result) {
                        toast.success('Elemento restaurado');
                        refresh();
                    }
                }
            )
    }

    const busquedaEntidad = (listaInfo) => {
        const input1 = document.getElementById('input-1').value;
        const input2 = document.getElementById('input-2').value;
        const input3 = document.getElementById('input-1-sign').value;
        let tmpArray = []
        if (String(input1) === '' && String(input2) === '' && String(input3) === '') {
            return listaInfo
        } else {
            const indexes = listaInfo.map(
                (element, index) => {
                    let flag = false;

                    if (String(input1) !== '') {
                        if (element.NOMBRE.toLowerCase().includes(input1.toLowerCase())) {
                            flag = true;
                        } else {
                            return -1
                        }
                    }

                    if (String(input2) !== '') {
                        if (element.SIGLAS.toLowerCase().includes(input2.toLowerCase())) {
                            flag = true;
                        } else {
                            return -1
                        }
                    }

                    if (String(input3) !== '') {
                        if (element.ESTADO === input3) {
                            flag = true;
                        } else {
                            return -1
                        }
                    }

                    if (flag) {
                        return index
                    }

                }
            ).filter(value => value >= 0)
            for (let i of indexes) {
                tmpArray.push(listaInfo[i])
            }
            return tmpArray
        }
    }

    const busquedaDependencias = (listaInfo) => {
        const input1 = document.getElementById('input-4').value;
        const input2 = document.getElementById('input-5').value;
        const input3 = document.getElementById('input-1-sign').value;
        let tmpArray = []
        if (String(input1) === '' && String(input2) === '' && String(input3) === '') {
            return listaInfo
        } else {
            const indexes = listaInfo.map(
                (element, index) => {
                    let flag = false;

                    if (String(input1) !== '') {
                        if (element.NOMBRE.toLowerCase().includes(input1.toLowerCase())) {
                            flag = true;
                        } else {
                            return -1
                        }
                    }

                    if (String(input2) !== '') {
                        if (element.SIGLAS.toLowerCase().includes(input2.toLowerCase())) {
                            flag = true;
                        } else {
                            return -1
                        }
                    }

                    if (String(input3) !== '') {
                        if (element.ESTADO === input3) {
                            flag = true;
                        } else {
                            return -1
                        }
                    }

                    if (flag) {
                        return index
                    }

                }
            ).filter(value => value >= 0)
            for (let i of indexes) {
                tmpArray.push(listaInfo[i])
            }
            return tmpArray
        }
    }

    if (eView) {
        return (
            <>
                <NavBar />
                <div className="container-usuario-administracion">
                    <h3 className="blog-title">Institución</h3>
                    <p className="lead blog-description">Administración de instituciones</p>
                    <hr />
                    <Button size="sm" variant="success" onClick={() => {
                        setModalCodigo('');
                        setModalNombre('');
                        setModalSiglas('');
                        setModalTitulo('Crear nueva entidad');
                        setShowE(true);
                    }}><AiOutlinePlus /> Agregar una nueva institución</Button>
                    <p />
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Opción</th>
                                <th>Nombre</th>
                                <th>Siglas</th>
                                <th>Estado</th>
                            </tr>
                            <tr>
                                <td>Herramientas de busqueda</td>
                                <td>
                                    <InputGroup className="search-input">
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => {
                                                setListaSec(busquedaEntidad(lista));
                                            }}
                                        >
                                            <AiOutlineSearch />
                                        </Button>
                                        <Form.Control
                                            onChange={() => {
                                                setListaSec(busquedaEntidad(lista));
                                            }}
                                            id="input-1"
                                            type="text"
                                            placeholder="busqueda por nombre"
                                        />
                                    </InputGroup>
                                </td>
                                <td>
                                    <InputGroup className="search-input">
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => {
                                                setListaSec(busquedaEntidad(lista));
                                            }}
                                        >
                                            <AiOutlineSearch />
                                        </Button>
                                        <Form.Control
                                            onChange={() => {
                                                setListaSec(busquedaEntidad(lista));
                                            }}
                                            id="input-2"
                                            type="text"
                                            placeholder="busqueda por siglas"
                                        />
                                    </InputGroup>
                                </td>
                                <td>
                                    <InputGroup className="search-input">
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => {
                                                setListaSec(busquedaEntidad(lista));
                                            }}
                                        >
                                            <AiOutlineSearch />
                                        </Button>
                                        <Form.Select
                                            variant="outline-secondary"
                                            id="input-1-sign"
                                            onChange={() => {
                                                setListaSec(busquedaEntidad(lista));
                                            }}
                                        >
                                            <option value='' ></option>
                                            <option value='0' >{'no vigente'}</option>
                                            <option value='1' >{'vigente'}</option>
                                        </Form.Select>
                                    </InputGroup>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listaSec.map((value) => {
                                    return (
                                        <tr key={value.CODIGO}>
                                            <td>
                                                {
                                                    /**
                                                    <Button className="optionButtons"
                                                        size="sm" variant="info" onClick={()=>{
                                                        setEstado(value.ESTADO);
                                                        setNombre(value.NOMBRE);
                                                        setSiglas(value.SIGLAS);
                                                        setCodigo(value.CODIGO);
                                                        setEView(false);
                                                        getDependencias(value.CODIGO);
                                                    }}><BsBuilding/> Direcciones</Button>    
                                                     */
                                                }
                                                <Button className="optionButtons"
                                                    size="sm" variant="warning" onClick={() => {
                                                        setModalNombre(value.NOMBRE);
                                                        setModalSiglas(value.SIGLAS);
                                                        setModalCodigo(value.CODIGO);
                                                        setModalTitulo('Editar entidad');
                                                        setShowE(true);
                                                    }}><AiFillEdit /> Editar</Button>
                                                {
                                                    (String(value.ESTADO) === '1') ?
                                                        <Button className="optionButtons"
                                                            size="sm" variant="danger" onClick={() => {
                                                                deleteEntidad(value.CODIGO, refreshEntidades);
                                                            }}><AiFillDelete /> Eliminar</Button> :
                                                        <Button className="optionButtons"
                                                            size="sm" variant="info" onClick={() => {
                                                                restoreEntidad(value.CODIGO, refreshEntidades);
                                                            }}><FaTrashRestoreAlt /> Restaurar</Button>
                                                }
                                            </td>
                                            <td>{value.NOMBRE}</td>
                                            <td>{value.SIGLAS}</td>
                                            <td>
                                                {
                                                    (String(value.ESTADO) === '1') ?
                                                        <p className="text-success"><BsCheckCircle /> Vigente</p> :
                                                        <p className="text-danger"><BsXCircle /> No vigente</p>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    <CrearEntidad tipo={modalTitulo} modalCodigo={modalCodigo} modalNombre={modalNombre}
                        modalSiglas={modalSiglas} showE={showE} setShowE={setShowE} refresh={refreshEntidades} />
                </div>
            </>
        );
    } else {
        return (
            <>
                <NavBar />
                <div className="container-usuario-administracion">
                    <h3 className="blog-title">Institución</h3>
                    <p className="lead blog-description">Administración de instituciones</p>
                    <hr />
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Siglas</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{nombre}</td>
                                <td>{siglas}</td>
                                <td>{
                                    (estado === '1') ?
                                        <p className="text-success"><BsCheckCircle /> Vigente</p> :
                                        <p className="text-danger"><BsXCircle /> No vigente</p>
                                }
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <hr />
                    <Button size="sm" variant="success" onClick={() => {
                        setModalCodigo('');
                        setModalNombre('');
                        setModalSiglas('');
                        setModalTitulo('Crear nueva dependencia');
                        setShowD(true);
                    }}><AiOutlinePlus /> Agregar una nueva dependencia</Button>
                    <Button size="sm" variant="secondary" onClick={() => { setEView(true) }}><AiOutlineRollback /> Regresar a entidades</Button>
                    <p />
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Opción</th>
                                <th>Nombre</th>
                                <th>Siglas</th>
                                <th>Estado</th>
                            </tr>
                            <tr>
                                <td>Herramientas de busqueda</td>
                                <td>
                                    <InputGroup className="search-input">
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => {
                                                setListaSecD(busquedaDependencias(listaD));
                                            }}
                                        >
                                            <AiOutlineSearch />
                                        </Button>
                                        <Form.Control
                                            onChange={() => {
                                                setListaSecD(busquedaDependencias(listaD));
                                            }}
                                            id="input-4"
                                            type="text"
                                            placeholder="busqueda por nombre"
                                        />
                                    </InputGroup>
                                </td>
                                <td>
                                    <InputGroup className="search-input">
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => {
                                                setListaSecD(busquedaDependencias(listaD));
                                            }}
                                        >
                                            <AiOutlineSearch />
                                        </Button>
                                        <Form.Control
                                            onChange={() => {
                                                setListaSecD(busquedaDependencias(listaD));
                                            }}
                                            id="input-5"
                                            type="text"
                                            placeholder="busqueda por siglas"
                                        />
                                    </InputGroup>
                                </td>
                                <td>
                                    <InputGroup className="search-input">
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => {
                                                setListaSecD(busquedaDependencias(listaD));
                                            }}
                                        >
                                            <AiOutlineSearch />
                                        </Button>
                                        <Form.Select
                                            variant="outline-secondary"
                                            id="input-1-sign"
                                            onChange={() => {
                                                setListaSecD(busquedaDependencias(lista));
                                            }}
                                        >
                                            <option value='' ></option>
                                            <option value='0' >{'no vigente'}</option>
                                            <option value='1' >{'vigente'}</option>
                                        </Form.Select>
                                    </InputGroup>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listaSecD.map((value) => {
                                    try {
                                        return (
                                            <tr key={value.CODIGO}>
                                                <td>
                                                    <Button className="optionButtons"
                                                        size="sm" variant="warning" onClick={() => {
                                                            setModalNombre(value.NOMBRE);
                                                            setModalSiglas(value.SIGLAS);
                                                            setModalCodigo(value.CODIGO);
                                                            setModalTitulo('Editar dependencia');
                                                            setShowD(true);
                                                        }}><AiFillEdit /> Editar</Button>
                                                    {
                                                        (String(value.ESTADO) === '1') ?
                                                            <Button className="optionButtons"
                                                                size="sm" variant="danger" onClick={() => {
                                                                    deleteEntidad(value.CODIGO, getDependencias, codigo);
                                                                }}><AiFillDelete /> Eliminar</Button> :
                                                            <Button className="optionButtons"
                                                                size="sm" variant="info" onClick={() => {
                                                                    restoreEntidad(value.CODIGO, () => { getDependencias(codigo) });
                                                                }}><FaTrashRestoreAlt /> Restaurar</Button>
                                                    }
                                                </td>
                                                <td>{value.NOMBRE}</td>
                                                <td>{value.SIGLAS}</td>
                                                <td>
                                                    {
                                                        (String(value.ESTADO) === '1') ?
                                                            <p className="text-success"><BsCheckCircle /> Vigente</p> :
                                                            <p className="text-danger"><BsXCircle /> No vigente</p>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    } catch (error) {
                                        return (<></>)
                                    }
                                })
                            }
                        </tbody>
                    </Table>
                    <CrearDependencia tipo={modalTitulo} modalCodigo={modalCodigo} modalNombre={modalNombre}
                        modalSiglas={modalSiglas} showD={showD} setShowD={setShowD} refresh={() => { getDependencias(codigo) }} codigoPadre={codigo}
                    />
                </div>
            </>
        );
    }

}