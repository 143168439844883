import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import axios from 'axios';

const RiesgoComboBox = ({ onChange }) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const selectRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/getRiesgos_riesgo/sugerenciasNombres', {
          headers: { "x-access-token": localStorage.getItem("token") },
        });
        const data = Array.isArray(response.data) ? response.data : response.data.data; // Ajusta esto según la estructura real
        const formattedData = data.map(item => ({
          value: item.CODIGO,
          label: item.NOMBRE,
          nombreTilde: item.NOMBRETILDE,
        }));
        setOptions(formattedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (selected) => {
    setSelectedOption(selected);
    onChange(selected ? selected.label : '');
  };

  const handleInputChange = (input) => {
    setInputValue(input);
  };

  const handleCreateOption = (inputValue) => {
    // No se crea automáticamente una nueva opción aquí
    onChange(inputValue); // Solo se pasa el valor al onChange
  };

  const handleMenuClose = () => {
    selectRef.current.blur(); // Desenfoca el Select
  };

  return (
    <Select
      ref={selectRef}
      value={selectedOption}
      onChange={handleChange}
      onCreateOption={handleCreateOption}
      options={options}
      isClearable
      placeholder="Selecciona un riesgo o ingresa uno nuevo..."
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onBlur={handleMenuClose}
    />
  );
};

export default RiesgoComboBox;
