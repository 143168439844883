import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button } from 'react-bootstrap';
import '../../../../styles/RiesgoMonitorStyle.scss';
import BarraProgreso from '../../../../Components/BarraProgresoInforme'
import { AiFillEdit, AiFillDelete } from 'react-icons/ai';
import { toast } from 'react-toastify';
import PopUpBorrar from '../../../../Components/popUpBorrar'

const RiesgosInforme = ({ codigoCia, codigoRiesgo, onSave }) => {
    const [data, setData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.get('/api/historialInforme', {
                headers: { "x-access-token": localStorage.getItem('token') },
                params: {
                    codigoCia,
                    codigoRiesgo
                }
            });
            if (Array.isArray(response.data)) {
                setData(response.data);
            } else if (response.data && typeof response.data === 'object') {
                setData([response.data]); // Convertir objeto en arreglo
            } else {
                console.error('Unexpected response data:', response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [codigoCia, codigoRiesgo]);


    const getDateFormat = (tmpDate) => {
        let firstDate = new Date(tmpDate);
        let options = { day: '2-digit', month: '2-digit', year: '2-digit', timeZone: "GMT" };
        return String(firstDate.toLocaleDateString('es-ES', options))
    }

    const deleteInforme = (codigo, fi, ff) => {
        axios.delete('/api/delete/informe-responsable/' + String(codigoCia) + '/' + String(codigoRiesgo) + '/' + String(codigo),
            { headers: { "x-access-token": localStorage.getItem('token') } }).then((response) => {
                if (response.data.result === 'Error') {
                    toast.error('Error al eliminar informe')
                } else {
                    toast.info('Informe borrado con éxito: ')
                    fetchData();
                }
            });
        setDisplayConfirmationModal(false);
    };

    /*modal del popup borrar */
    const [id, setId] = useState(null);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState(null);

    // Handle the displaying of the modal based on type and id
    const showDeleteModal = (id) => {
        setId(id);
        setDeleteMessage(`¿Está seguro de borrar el informe?`);
        setDisplayConfirmationModal(true);
    };

    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    const [editableCell, setEditableCell] = useState(null); // Guarda la celda editable: { rowId, columnName }
    const [editedValue, setEditedValue] = useState("");

    const handleEditCell = (rowId, columnName, currentValue) => {
        setEditableCell({ rowId, columnName });
        setEditedValue(currentValue);
    };

    const handleSave = (i,e) => {
        setEditedValue(e)
        onSave(i,e); // Enviar el valor editado al padre
      };
      
    return (
        <div>
            <h6>Histórico de informes</h6>
            <div style={{ overflowY: 'auto', maxHeight: '300px', overflowX: 'auto' }}>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>OPCIONES</th>
                            <th>FECHA</th>
                            <th>OBSERVACIONES</th>
                            <th>Avance (%)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data./*slice(0, showAll ? data.length : 3).*/map((informe) => (
                            <tr key={informe.CODIGO_INFORME}>
                            <td>
                                { <Button className="optionButtons"
                                    size="sm" variant="warning" onClick={() => {
                                        handleEditCell(informe.CODIGO_INFORME, "name", informe.OBSERVACIONES)
                                    }}><AiFillEdit /> Editar</Button>
                                }
                                <Button className="optionButtons"
                                    size="sm" variant="danger" onClick={() => {
                                        showDeleteModal(informe.CODIGO_INFORME);
                                    }}><AiFillDelete /> Eliminar</Button>
                            </td>
                                <td>{getDateFormat(informe.FECHA_INFORME)}</td>
                                
                                <td style={{ textAlign: 'left' }} >
                                    {editableCell?.rowId === informe.CODIGO_INFORME && editableCell.columnName === "name" ? (
                                        <input
                                            type="text"
                                            value={editedValue}
                                            onChange={(e) => handleSave(informe.CODIGO_INFORME, e.target.value)}
                                        />
                                    ) : (
                                        informe.OBSERVACIONES || 'No hay observaciones'
                                    )}

                                </td>
                                <td>
                                    <BarraProgreso avance={informe.PORCENTAJE_AVANCE}
                                        codigoMonitoreo={informe.CODIGO_INFORME} codigoRiesgo={informe.CODIGO_RIESGO} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <PopUpBorrar showModal={displayConfirmationModal}
                    confirmModal={deleteInforme} hideModal={hideConfirmationModal}
                    id={id} message={deleteMessage} />
            </div>
        </div>
    );
};

export default RiesgosInforme;
