import React, { useState } from "react";
import './usuariosAdministracion.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Button, Modal, Row, Col } from "react-bootstrap";

// HTTP Requests
import Axios from 'axios';

export const CrearUsuarioAdministrador = (props) => {

    const [validated, setValidated] = useState(false);
    const [pn, setPN] = useState('');
    const [sn, setSN] = useState('');
    const [mn, setMN] = useState('');
    const [pa, setPA] = useState('');
    const [sa, setSA] = useState('');
    const [ca, setCA] = useState('');
    const [fecha, setFecha] = useState('');
    const [checkBoxFlag, setCheckBoxFlag] = useState(true);
    const [sexo, setSexo] = useState('');
    const [correo, setCorreo] = useState('');
    const [usuario, setUsuario] = useState('');
    const [checkBoxFlag2, setCheckBoxFlag2] = useState(true);
    const [estado, setEstado] = useState('');
    const [institucion, setInstitucion] = useState('');
    const [dependencias, setDependencias] = useState([]);
    const [dependencia, setDependencia] = useState('');
    const [checkBoxFlag3, setCheckBoxFlag3] = useState(true);
    const [maestro, setMaestro] = useState('');

    const getDependencias = (code) => {
        if(code){
            Axios.get('/api/get/dependenciasAdminSystem/'+String(code),{headers:{"x-access-token": localStorage.getItem('tokenAdminSys')}}).then(
                (response)=>{
                    if (response.data.error){
                        toast.error('Ha ocurrido un error al enviar su solicitud');
                    } else if(response.data.result) {
                        setDependencias(response.data.result);
                    }
                }
            );
        }
    }

    const submitNewUser = () => {
        Axios.post('/api/post/newUserAdminSystem', {
            pn: pn,
            sn: sn,
            mn: mn,
            pa: pa,
            sa: sa,
            ca: ca,
            fecha: fecha,
            sexo: sexo,
            correo: correo,
            usuario: usuario.toUpperCase(),
            password: usuario.toLowerCase(),
            estado: estado,
            institucion: institucion,
            dependencia: dependencia,
            maestro: maestro,
        },{headers:{"x-access-token": localStorage.getItem('tokenAdminSys')}}).then(
            (response)=>{
                if (response.data.error){
                    if (response.data.mensaje){
                        toast.error(response.data.mensaje);
                    } else if(String(response.data.error) === 'ORA-00001' ){
                        toast.error('Error, usuario web ya existe, asigne uno nuevo.');
                    } else {
                        toast.error('Error al crear nuevo responsable');
                    }
                } else if (response.data.result) {
                    toast.success('Usuario nuevo creado');
                    closeModal();
                } 
            }
        );
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            setValidated(true);
        } else {
            submitNewUser();
        }
        setValidated(true);
    };

    const closeModal = () => {
        props.setShowNew(false);
        setCheckBoxFlag(true);
        setCheckBoxFlag2(true);
        setCheckBoxFlag3(true);
        setValidated(false);
    }

    return(
        <Modal show={props.showNew} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
                <Modal.Title>Crear nuevo usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                            <Form.Label>Primer nombre</Form.Label>
                            <Form.Control required maxLength="50" type="text"  placeholder="Primer nombre" onChange={(e)=>{setPN(e.target.value)}} />
                            <Form.Control.Feedback type="invalid"> Ingrese el primer nombre </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom02">
                            <Form.Label>Segundo nombre</Form.Label>
                            <Form.Control maxLength="50" type="text"  placeholder="Segundo nombre" onChange={(e)=>{setSN(e.target.value)}} />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom03">
                            <Form.Label>Más nombres</Form.Label>
                            <Form.Control maxLength="50" type="text"  placeholder="Tercer nombre" onChange={(e)=>{setMN(e.target.value)}} />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom04">
                            <Form.Label>Primer apellido</Form.Label>
                            <Form.Control required maxLength="50" type="text"  placeholder="Primer apellido" onChange={(e)=>{setPA(e.target.value)}} />
                            <Form.Control.Feedback type="invalid"> Ingrese el primer nombre </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom05">
                            <Form.Label>Segundo apellido</Form.Label>
                            <Form.Control maxLength="50" type="text"  placeholder="Segundo apellido" onChange={(e)=>{setSA(e.target.value)}} />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom06">
                            <Form.Label>Apellido de casada</Form.Label>
                            <Form.Control maxLength="50" type="text"  placeholder="Apellido de casada" onChange={(e)=>{setCA(e.target.value)}} />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom07">
                            <Form.Label>Fecha de nacimiento</Form.Label>
                            <Form.Control required type="date"  onChange={(e)=>{setFecha(e.target.value)}} />
                            <Form.Control.Feedback type="invalid"> Ingrese el primer nombre </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom08">
                            <Form.Label>Sexo</Form.Label>
                            <Form.Check required={checkBoxFlag} id="sexo" value={'1'} label="Masculino" 
                                onClick={(e)=>{
                                    setSexo(e.target.value);
                                    document.getElementById("sexo1").checked = false;
                                    if(!document.getElementById("sexo1").checked && !document.getElementById("sexo").checked){
                                        setCheckBoxFlag(true);
                                    } else {
                                        setCheckBoxFlag(false);
                                    }
                            }} 
                            />
                            <Form.Check required={checkBoxFlag} id="sexo1" value={'2'} label="Femenino" 
                                onClick={(e)=>{
                                    setSexo(e.target.value);
                                    document.getElementById("sexo").checked = false;
                                    if(!document.getElementById("sexo1").checked && !document.getElementById("sexo").checked){
                                        setCheckBoxFlag(true);
                                    } else {
                                        setCheckBoxFlag(false);
                                    }
                                }}
                            />
                            {
                                (checkBoxFlag&&validated)?
                                <p style={{'width': '100%', 'margin-top': '0.25rem', 'font-size': '.875em','color': '#dc3545'}}>Ingrese el sexo de la persona</p>:
                                <></>
                            }
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom09">
                            <Form.Label>Correo electronico</Form.Label>
                            <Form.Control required type="email"  placeholder="Correo electronico" onChange={(e)=>{setCorreo(e.target.value)}} />
                            <Form.Control.Feedback type="invalid"> Ingrese el correo electronico </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom10">
                            <Form.Label>Usuario web</Form.Label>
                            <Form.Control required maxLength="250" type="text"  placeholder="usuario web" onChange={(e)=>{setUsuario(e.target.value)}} />
                            <Form.Control.Feedback type="invalid"> Ingrese el usuario web </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom12">
                            <Form.Label>Estado</Form.Label>
                            <Form.Check required={checkBoxFlag2} id="estado1" value={1} label="Valido" 
                                onClick={(e)=>{
                                    setEstado(e.target.value);
                                    document.getElementById("estado2").checked = false;
                                    if(!document.getElementById("estado2").checked && !document.getElementById("estado1").checked){
                                        setCheckBoxFlag2(true);
                                    } else {
                                        setCheckBoxFlag2(false);
                                    }
                                }}
                            />
                            <Form.Check required={checkBoxFlag2} id="estado2" value={0} label="No valido"
                                onClick={(e)=>{
                                    setEstado(e.target.value);
                                    document.getElementById("estado1").checked = false;
                                    if(!document.getElementById("estado2").checked && !document.getElementById("estado1").checked){
                                        setCheckBoxFlag2(true);
                                    } else {
                                        setCheckBoxFlag2(false);
                                    }
                                }}
                            />
                            {
                                (checkBoxFlag2&&validated)?
                                <p style={{'width': '100%', 'margin-top': '0.25rem', 'font-size': '.875em','color': '#dc3545'}}>Ingrese el estado de la persona</p>:
                                <></>
                            }
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom15">
                            <Form.Label>Maestro</Form.Label>
                            <Form.Check required={checkBoxFlag3} id="maestro1" value={0} label="Requiere autorización"
                                onClick={(e)=>{
                                    setMaestro(e.target.value);
                                    document.getElementById("maestro2").checked = false;
                                    document.getElementById("maestro3").checked = false;
                                    if(!document.getElementById("maestro1").checked && !document.getElementById("maestro2").checked && !document.getElementById("maestro3").checked){
                                        setCheckBoxFlag3(true);
                                    } else {
                                        setCheckBoxFlag3(false);
                                    }
                                }}
                            />
                            <Form.Check required={checkBoxFlag3} id="maestro2" value={1} label="Elimina sus propios riesgos"
                                onClick={(e)=>{
                                    setMaestro(e.target.value);
                                    document.getElementById("maestro1").checked = false;
                                    document.getElementById("maestro3").checked = false;
                                    if(!document.getElementById("maestro1").checked && !document.getElementById("maestro2").checked && !document.getElementById("maestro3").checked){
                                        setCheckBoxFlag3(true);
                                    } else {
                                        setCheckBoxFlag3(false);
                                    }
                                }}
                            />
                            <Form.Check required={checkBoxFlag3} id="maestro3" value={2} label="Autoriza y elimina riesgos de toda la institución"
                                onClick={(e)=>{
                                    setMaestro(e.target.value);
                                    document.getElementById("maestro2").checked = false;
                                    document.getElementById("maestro1").checked = false;
                                    if(!document.getElementById("maestro1").checked && !document.getElementById("maestro2").checked && !document.getElementById("maestro3").checked){
                                        setCheckBoxFlag3(true);
                                    } else {
                                        setCheckBoxFlag3(false);
                                    }
                                }}
                            />
                            {
                                (checkBoxFlag3&&validated)?
                                <p style={{'width': '100%', 'margin-top': '0.25rem', 'font-size': '.875em','color': '#dc3545'}}>Ingrese el valor que desea darle</p>:
                                <></>
                            }

                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom13">
                            <Form.Label>Institución</Form.Label>
                            <Form.Select required 
                                onChange={(e)=>{
                                    if (e.target.value !==''){
                                        getDependencias(e.target.value)
                                        setInstitucion(e.target.value)
                                    } else {
                                        setDependencias([]);
                                        setInstitucion('');
                                    } 
                                    setDependencia('');
                                }}
                            >
                                <option value=''/>
                                {
                                    props.instituciones.map((value)=>{
                                        return(
                                            <option key={value.CODIGO} value={value.CODIGO}>
                                                {value.NOMBRE}
                                            </option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="validationCustom14">
                            <Form.Label>Dependencia</Form.Label>
                            <Form.Select
                                onChange={(e)=>{
                                    setDependencia(e.target.value)
                                }}
                            >
                                <option value=''/>
                                {
                                    dependencias.map((value)=>{
                                        return(
                                            <option key={value.CODIGO} value={value.CODIGO}>
                                                {value.NOMBRE}
                                            </option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <hr/>
                    <Button type="submit" variant="success" size='sm'>Guardar</Button>
                    <Button variant="secondary" size='sm' onClick={()=>{closeModal();}}>Cancelar</Button>
                </Form>                
            </Modal.Body>
        </Modal>
    )

}