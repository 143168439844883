import React, { useState, useEffect } from 'react';
import './autorizacion.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Table } from 'react-bootstrap';
import { BiMessage } from 'react-icons/bi';
// HTTP Requests
import Axios from 'axios';
import UpdatedNavBar from '../NavBar/UpdatedNavBar';

export const Autorizacion = () => {

    const [listaNotificaciones, setListaNotificaciones] = useState([]);
    const [show, setShow] = useState(false);
    const [idRiesgo, setID] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [code, setCode] = useState('');

    const refresh = () => {
        Axios.get('/api/getPendingRisk', { headers: { "x-access-token": localStorage.getItem('token'), }, }).then(
            (response) => {
                setListaNotificaciones(response.data.result);
            }
        );
    }
    useEffect(() => {
        refresh()
    }, [])

    const updateRequestRisk = (codigo, estado) => {
        Axios.post("/api/updaterequeststate", { codigo: codigo, estado: estado },
            { headers: { "x-access-token": localStorage.getItem('token') } }).then(
                (response) => {
                    if (response.data.result) {
                        toast.success((String(estado) === '4') ? 'Se autorizo exitosamente' : 'Se denegó solicitud con exito');
                        refresh()
                        setShow(false)
                    } else {
                        toast.error('No se logró responder a solicitud');
                    }
                }
            );

    }

    const getDateFormat = (tmpDate) => {
        let firstDate = new Date(tmpDate);
        let options = { day: 'numeric', month: 'numeric', year: 'numeric', timeZone: "GMT" };
        return String(firstDate.toLocaleDateString('es', options))
    }

    return (
        <>
            <UpdatedNavBar />
            <div className='container-home'>
                <h1>Autorización</h1>
                <h5>Listado de solicitudes de autorización para eliminar.</h5>
                <pre>
                    <BiMessage /> R = Responder solicitud
                </pre>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Opción</th>
                            <th>Dependencia</th>
                            <th>Área de evaluación</th>
                            <th>Período</th>
                            <th>ID riesgo</th>
                            <th>Descripción</th>
                            <th>Estado</th>
                            <th>Usuario Dueño</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            listaNotificaciones.map((value) => {
                                return (
                                    <tr key={value.CODIGO_RIESGO}>
                                        <td>
                                            <Button
                                                variant='info'
                                                onClick={() => {
                                                    setShow(true);
                                                    setID(value.ID_RIESGO);
                                                    setDescripcion(value.DESCRIPCION);
                                                    setCode(value.CODIGO_RIESGO)
                                                }}
                                                size='sm'
                                            >
                                                <BiMessage /> R
                                            </Button>
                                        </td>
                                        <td>{value.DEPENDENCIA}</td>
                                        <td>{value.AREA}</td>
                                        <td>{getDateFormat(value.periodo_inicial)} - {getDateFormat(value.periodo_final)}</td>
                                        <td>{value.ID_RIESGO}</td>
                                        <td>{value.DESCRIPCION}</td>
                                        <td>Solicitud de autorización para eliminar</td>
                                        <td>{value.NOMBRE}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
                <Modal show={show} >
                    <Modal.Header>
                        <Modal.Title>Solicitud de Autorización</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Se solicita eliminar el riesgo identificado <b>{idRiesgo}</b><br />Descripción: <b>{descripcion}</b>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button size="sm" variant="success" onClick={() => { updateRequestRisk(code, 4) }}>
                            Autorizar
                        </Button>
                        <Button size="sm" variant="danger" onClick={() => { updateRequestRisk(code, 5) }}>
                            Rechazar
                        </Button>
                        <Button size="sm" variant="secondary" onClick={() => { setShow(false) }}>
                            Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
}