import React, { useState, useEffect } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import Axios from 'axios';

function SearchObjetivoArea(props) {
    const [listaPeriodo, setListaPeriodo] = useState([]);
    const [listaTipObj, setListaTipObj] = useState([]);
    const [listaPrin, setListaPrin] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Obtiene los períodos de la empresa
        Axios.get("/api/get/periodos", { headers: { "x-access-token": localStorage.getItem('token') } })
            .then((response) => {
                setListaPeriodo(response.data);
            });

        // Obtiene los tipos de objetivos de la empresa
        Axios.get("/api/get/tiposObjetivos", { headers: { "x-access-token": localStorage.getItem('token') } })
            .then((response) => {
                setListaTipObj(response.data);
            });

        // Llamada adicional para obtener listaPrin
        if (props.tipObj && props.periodo) {
            console.log("tipo: "+props.tipObj+"periodo:"+props.periodo)
            Axios.get("/api/get/periodo-objetivo" +  props.tipObj + "/" +  props.periodo, { headers: { "x-access-token": localStorage.getItem('token') } })
            .then((response) => {
                console.log("response "+ response.data)
                if (Array.isArray(response.data)) {
                    setListaPrin(response.data);
                } else {
                    setListaPrin([]);
                }
            })
           
                .catch((error) => {
                    console.error("Error fetching data", error);
                    setListaPrin([]); // Establecer listaPrin como un array vacío en caso de error
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [props.tipObj, props.periodo]);

    return (
        <>
            <Row className="mb-3">
                <Form.Group as={Col} md="2">
                    <Form.Label><b></b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label><b>Período de evaluación</b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Select onChange={
                        (e) => {
                            if (e.target.value !== '') {
                                props.setPeriodo(e.target.value)
                            }
                        }
                    }>
                        <option value=''></option>
                        {listaPeriodo.map((value) => {
                            return (<option value={value.CODIGO_PERIODO} key={value.CODIGO_PERIODO}>Del {value.FECINI} al {value.FECFIN} de {value.CODIGO_PERIODO}</option>)
                        })}
                    </Form.Select>
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="2">
                    <Form.Label><b></b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label><b>Tipo de Objetivo</b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Select onChange={
                        (e) => {
                            if (e.target.value !== '') {
                                props.setTipObj(e.target.value)
                            }
                        }
                    }>
                        <option value=''></option>
                        {listaTipObj.map((value) => {
                            return (<option value={value.CODIGO} key={value.CODIGO}>{value.DESCRIPCION}</option>)
                        })}
                    </Form.Select>
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="2">
                    <Form.Label><b></b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label><b> Objetivos</b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Select onChange={
                        (e) => {
                            if (e.target.value !== '') {
                                console.log("valor"+e.target.value);
                                props.setSelectedObjective(e.target.value)
                            }
                        }
                    }>
                        <option value=''></option>
                        {listaPrin.map((item) => {
                            return (<option value={item.CODIGO_OBJETIVO} key={item.CODIGO_OBJETIVO}>{item.DESCRIPCION}</option>)
                        })}
                    </Form.Select>
                </Form.Group>
            </Row>
        </>
    )
}

export default SearchObjetivoArea;
