import {createRoot} from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import './styles/global.scss';
import Axios from 'axios';

Axios.defaults.baseURL = 'https://monitorderiesgo.com'
//Axios.defaults.baseURL = 'http://localhost:3001'


// IMPORTANT: use correct ID of your root element
// this is the ID of the div in your index.html file
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <App />
);

/*
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);
*/
