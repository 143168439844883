import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Modal,
  Box,
  Button,
  FormControl,
  Stack,
  Grid,
  Typography,
  Card,
  CardContent,
  InputAdornment,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import SearchObjetivoArea from "./SearchCatalogos";
import RiesgoComboBox from "./ComboBoxNombresRiesgos";
import FrecuenciaComboBox from "./ComboBoxFrecuencia";
import ComboBoxVariableMitigacion from "./ComboBoxVariableMitigacion"; // Asegúrate de importar el componente
import InformacionGeneralRiesgo from "../Componentes/InformacionGeneralRiesgo";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "80vh",
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ObjetivoModal = ({
  isOpen,
  onClose,
  onInsertSuccess,
  descripcionPeriodo,
  periodo, // Asegúrate de recibir el periodo aquí
  tipoObjetivo: initialTipoObjetivo,
  codigoObjetivo,
  codigoArea,
  descripcionTipoObjetivo,
  descripcionObjetivo,
  descripcionArea,
}) => {
  const [formData, setFormData] = useState({
    descripcion: "",
    codigoProbabilidad: "",
    codigoFrecuencia: "",
    codigoSeveridad: "",
    codigoResponsable: "",
    fechaIniMonitoreo: "",
    fechaFinMonitoreo: "",
    descMetodoMonitoreo: "",
    observaciones: "",
    codigoRiesgo: "",
    valorMitigacion: "",
    variableMitigacion: "",
    codigoPeriodo: periodo || "",
    codigoTipoObjetivo: initialTipoObjetivo || "",
    codigoObjetivo: codigoObjetivo || "",
    codigoArea: codigoArea || "",
  });

  const [tipoObjetivo, setTipoObjetivo] = useState(initialTipoObjetivo || "");

  useEffect(() => {
    if (isOpen) {
      setTipoObjetivo(initialTipoObjetivo || "");
      setFormData((prevFormData) => ({
        ...prevFormData,
        codigoPeriodo: periodo,
        codigoTipoObjetivo: initialTipoObjetivo || "",
        codigoObjetivo: codigoObjetivo || "",
        codigoArea: codigoArea || "",
      }));
    }
  }, [isOpen, periodo, initialTipoObjetivo, codigoObjetivo, codigoArea]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const fetchCodigoRiesgoSugerido = async () => {
    try {
      const response = await axios.get(
        `/api/getRiesgos_riesgo/Correlativo/1/${tipoObjetivo}`,
        {
          headers: { "x-access-token": localStorage.getItem("token") },
        }
      );
      setFormData((prevFormData) => ({
        ...prevFormData,
        codigoRiesgo: response.data.count,
      }));
    } catch (error) {
      console.error("Error fetching codigoRiesgo sugerido:", error);
      toast.error("Error fetching codigoRiesgo sugerido");
    }
  };

  useEffect(() => {
    if (isOpen && tipoObjetivo) {
      fetchCodigoRiesgoSugerido();
    }
  }, [isOpen, tipoObjetivo]);

  const handleCodigoRiesgoChange = async (e) => {
    const value = e.target.value;
    setFormData({ ...formData, codigoRiesgo: value });
    if (value) {
      try {
        const response = await axios.get(
          `/api/checkCodigoRiesgo/${value}/${tipoObjetivo}`,
          {
            headers: { "x-access-token": localStorage.getItem("token") },
          }
        );
        if (response.data.exists) {
          toast.error("Código de riesgo ya en uso");
        }
      } catch (error) {
        console.error("Error checking codigoRiesgo:", error);
        toast.error("Error checking codigoRiesgo");
      }
    }
  };

  const handleSubmit = () => {
    const formattedFormData = {
      ...formData,
      codigoProbabilidad: parseInt(formData.codigoProbabilidad),
      codigoFrecuencia: parseInt(formData.codigoFrecuencia),
      codigoSeveridad: parseInt(formData.codigoSeveridad),
      codigoResponsable: parseInt(formData.codigoResponsable),
      fechaIniMonitoreo: formData.fechaIniMonitoreo
        ? formData.fechaIniMonitoreo.split("T")[0]
        : null,
      fechaFinMonitoreo: formData.fechaFinMonitoreo
        ? formData.fechaFinMonitoreo.split("T")[0]
        : null,
      valorMitigacion: parseInt(formData.valorMitigacion),
    };

    console.log(formattedFormData); // Imprimir los datos para depuración

    axios
      .post(
        "/api/insert/riesgo",
        {
          codigoCia: 1,
          codigoPeriodo: parseInt(formData.codigoPeriodo),
          codigoTipoObjetivo: tipoObjetivo,
          codigoObjetivo: parseInt(formData.codigoObjetivo),
          codigoArea: parseInt(formData.codigoArea),
          ...formattedFormData,
        },
        {
          headers: { "x-access-token": localStorage.getItem("token") },
        }
      )
      .then((response) => {
        onInsertSuccess();
        onClose();
      })
      .catch((error) => {
        console.error(error);
        if (error.response) {
          console.error("Response data:", error.response.data);
        }
      });
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ mb: 2, textAlign: "center" }}
        >
          Crear Riesgo
        </Typography>

        <FormControl fullWidth>
          <Grid container justifyContent="center" spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={4}>
              <Card sx={{ backgroundColor: "#e3f2fd" }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Período
                  </Typography>
                  <Typography variant="body1">
                    {descripcionPeriodo} de {periodo}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2, textAlign: "center" }}
          >
            Información General del Riesgo
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InformacionGeneralRiesgo
                setPeriodo={(periodo) =>
                  setFormData({ ...formData, codigoPeriodo: periodo })
                }
                setTipObj={(tipoObjetivo) => {
                  setFormData({ ...formData, codigoTipoObjetivo: tipoObjetivo });
                  setTipoObjetivo(tipoObjetivo);
                }}
                setSelectedObjective={(codigoObjetivo) =>
                  setFormData({ ...formData, codigoObjetivo })
                }
                setSelectedArea={(codigoArea) =>
                  setFormData({ ...formData, codigoArea })
                }
                periodo={formData.codigoPeriodo}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="codigoRiesgo"
                label="Código Riesgo"
                value={formData.codigoRiesgo}
                onChange={handleCodigoRiesgoChange}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{`${tipoObjetivo}-`}</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <ComboBoxVariableMitigacion
                onChange={(value) =>
                  setFormData({ ...formData, variableMitigacion: value })
                }
                value={formData.variableMitigacion}
              />
            </Grid>
            <Grid item xs={4}>
              <FrecuenciaComboBox
                onChange={(value) =>
                  setFormData({ ...formData, codigoFrecuencia: value })
                }
                value={formData.codigoFrecuencia}
              />
            </Grid>
            <Grid item xs={12}>
              <RiesgoComboBox
                onChange={(value) =>
                  setFormData({ ...formData, descripcion: value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ mb: 2, textAlign: "center" }}
              >
                Apetito al Riesgo
              </Typography>
              <SearchObjetivoArea
                setProbabilidad={(value) =>
                  setFormData({ ...formData, codigoProbabilidad: value })
                }
                setTolerancia={(value) =>
                  setFormData({ ...formData, codigoTolerancia: value })
                }
                setSeveridad={(value) =>
                  setFormData({ ...formData, codigoSeveridad: value })
                }
                setMitigacion={(value) =>
                  setFormData({ ...formData, codigoMitigacion: value })
                }
                setValorMitigacion={(value) =>
                  setFormData({ ...formData, valorMitigacion: value })
                }
                setResponsable={(value) =>
                  setFormData({ ...formData, codigoResponsable: value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ mb: 2, textAlign: "center" }}
              >
                Comentarios u Observaciones
              </Typography>
              <TextField
                name="observaciones"
                label="Observaciones"
                value={formData.observaciones}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button variant="contained" onClick={handleSubmit}>
              Guardar
            </Button>
            <Button variant="outlined" onClick={onClose}>
              Cancelar
            </Button>
          </Stack>
        </FormControl>
      </Box>
    </Modal>
  );
};

export default ObjetivoModal;
