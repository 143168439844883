import React, { useState } from "react";
import './admin.css';
import { Tabs, Tab } from "react-bootstrap";
import { Stack, Typography } from "@mui/material";
//import AdminUsuarios from "./AdminUsuarios";
import RolesViews from "./RolesViews";
import UpdatedNavBar from "../NavBar/UpdatedNavBar";
import CambiarLogo from "./CambiarLogo";

function RolesView() {

    const [key, setKey] = useState('logo');
    const [refresh, setRefresh] = useState(0)

    return (
        <>
            <UpdatedNavBar />
            <div className="container-dependencias">
                <Stack spacing={1}>
                    {/**
                     * Titulos principales de la página
                     */}
                    <Typography variant="h2" color='black' >Roles por usuario</Typography>
                    <Typography variant="h4" color='lightgray' >Aquí podrá administrar los roles de cada usuario</Typography>
                    <hr />
                    <RolesViews refresh={refresh} />
                    {/*<Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3" >
                        
                        <Tab eventKey="logo" title="Parámetros de la empresa">
                            <CambiarLogo />
                        </Tab>
                        <Tab eventKey="roles" title="Roles">
                            <RolesViews refresh={refresh}/>
                        </Tab>
                    </Tabs>*/}
                </Stack>
            </div>
        </>
    )
}

export default RolesView