import React, { useState, useEffect } from 'react'
import { Form, Col, Row } from 'react-bootstrap';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
// HTTP Requests
import Axios from 'axios';

function SearchPeriodo(props) {

    const [listaDep, setListaDep] = useState([]);
    const [listaArea, setListaArea] = useState([]);
    const [listaPeriodo, setListaPeriodo] = useState([]);

    const getDateFormat = (tmpDate) => {
        let firstDate = new Date(tmpDate);
        let options = { day: 'numeric',  month: 'long', year: 'numeric', timeZone: "GMT"};
        return String(firstDate.toLocaleDateString('es-ES', options))
    }

    /**
     * Search Riesgo
     */
    

    useEffect(()=>{
        //Get enntidad a la que pertenece el usuario
        Axios.get("/api/various/getEntity",{headers:{"x-access-token": localStorage.getItem('token')}})
        .then((response)=>{
            //Get lista de dependencias
            Axios.get("/api/get/dependencia", 
            {headers:{"x-access-token": localStorage.getItem('token'),}}).then(
                (responseGet)=> {
                    setListaDep(responseGet.data); 
                }
            );
        });
    },[])

    return (
        <>
            <Row className="mb-3">
                <Form.Group as={Col} md="2">
                    <Form.Label><b></b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label><b>Dependencia</b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Select onChange={(e)=>{
                            setListaPeriodo([]);
                            props.setListaRiesgos([]); 
                            if (e.target.value === ''){
                                setListaArea([]);   
                            } else{
                                Axios.get("/api/get/area/"+String(e.target.value),{headers:{"x-access-token": localStorage.getItem('token')}}).then(
                                    (response)=> {
                                        setListaArea(response.data);
                                        if(response.data.length === 0){
                                            toast.error('No existen áreas de evaluación para esta dependencia. Puede crear una nueva área de evaluación en el apartado de dependencias.');
                                        }
                                    }
                                );
                            }
                            
                        }} >
                        <option value ='' ></option>
                        {listaDep.map((value) => {
                            return(<option value={value.CODIGO_DEPENDENCIA} key={value.CODIGO} >{value.NOMBRE}</option>)
                        })}
                    </Form.Select>
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="2">
                    <Form.Label><b></b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label><b>Área de evaluación</b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Select onChange={(e)=>{
                        props.setListaRiesgos([]);
                        if (e.target.value === ''){
                            setListaPeriodo([]);
                        } else {
                            Axios.get("/api/get/periodo/"+String(e.target.value),{headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=> {
                                setListaPeriodo(response.data);
                                if(response.data.length === 0){
                                    toast.error('No existen períodos de evaluación para esta área. Puede crear uno nuevo en el apartado de dependencias.');
                                }
                            });
                        }
                    }} >
                        <option value ='' ></option>
                        {listaArea.map((value) => {
                            return(<option value={value.CODIGO_AREA} key={value.CODIGO} >{value.SIGLAS} - {value.NOMBRE}</option>)
                        })}
                    </Form.Select>
                </Form.Group>                    
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="2">
                    <Form.Label><b></b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label><b>Período de evaluación</b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Select onChange={
                            (e) => {
                                if (e.target.value !== '') {
                                    props.setPeriodo(e.target.value)
                                    props.searchRiesgo(e.target.value);
                                } else {
                                    props.setListaRiesgos([]);
                                }
                            }
                        } >
                        <option value=''></option>
                        {listaPeriodo.map((value) => {
                            return(<option value={value.CODIGO_PERIODO} key={value.CODIGO_PERIODO} >Del {getDateFormat(value.PERIODO_INICIAL)} al {getDateFormat(value.PERIODO_FINAL)}</option>)
                        })}
                    </Form.Select>
                </Form.Group>                    
            </Row>
        </>
    )
}

export default SearchPeriodo