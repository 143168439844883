// EditarSubevento.jsx
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import PersonaResponsableComboBox from '../PersonaResponsableComboBox';

const EditarSubevento = ({ show, handleClose, handleSave, subevento }) => {
    const [formData, setFormData] = useState({
        subTema: '',
        codigoTolerancia: '',
        metodoMonitoreo: '',
        frecuenciaMonitoreo: '',
        codigoResponsable: '',
        codigoSeveridad: '',
        controlImpl: '',
        recursos: '',
        fechaInicio: '',
        fechaFinal: ''
    });

    useEffect(() => {
        if (subevento) {
            setFormData({
                subTema: subevento.SUB_TEMA,
                codigoTolerancia: subevento.CODIGO_TOLERANCIA,
                metodoMonitoreo: subevento.METODO_MONITOREO,
                frecuenciaMonitoreo: subevento.FRECUENCIA_MONITOREO,
                codigoResponsable: subevento.RESPONSABLE,
                codigoSeveridad: subevento.CODIGO_SEVERIDAD,
                controlImpl: subevento.CONTROL_IMPL,
                recursos: subevento.RECURSOS,
                fechaInicio: subevento.FECHA_INICIO,
                fechaFinal: subevento.FECHA_FINAL
            });
        }
    }, [subevento]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleResponsableChange = (value) => {
        setFormData({ ...formData, codigoResponsable: value });
    };

    const onSave = () => {
        handleSave({
            ...formData,
            codigoEvento: subevento.CODIGO_EVENTO,
            codigoCia: subevento.CODIGO_CIA,
            codigoPeriodo: subevento.CODIGO_PERIODO,
            codigoTipoObjetivo: subevento.CODIGO_TIPO_OBJETIVO,
            codigoObjetivo: subevento.CODIGO_OBJETIVO,
            codigoArea: subevento.CODIGO_AREA,
            codigoRiesgo: subevento.CODIGO_RIESGO
        });
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Editar Subevento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Form.Group controlId="subTema">
                                <Form.Label>Sub Tema</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="subTema"
                                    value={formData.subTema}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="codigoTolerancia">
                                <Form.Label>Nivel de Tolerancia</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="codigoTolerancia"
                                    value={formData.codigoTolerancia}
                                    onChange={handleChange}
                                >
                                    <option value="">Seleccione...</option>
                                    <option value="1">No Tolerable</option>
                                    <option value="2">Gestionable</option>
                                    <option value="3">Tolerable</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="metodoMonitoreo">
                                <Form.Label>Método Monitoreo</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="metodoMonitoreo"
                                    value={formData.metodoMonitoreo}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="frecuenciaMonitoreo">
                                <Form.Label>Frecuencia Monitoreo</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="frecuenciaMonitoreo"
                                    value={formData.frecuenciaMonitoreo}
                                    onChange={handleChange}
                                >
                                    <option value="">Seleccione...</option>
                                    <option value="Diaria">Diaria</option>
                                    <option value="Quincenal">Quincenal</option>
                                    <option value="Mensual">Mensual</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="codigoResponsable">
                                <Form.Label>Responsable</Form.Label>
                                <PersonaResponsableComboBox 
                                    value={formData.codigoResponsable} 
                                    onChange={handleResponsableChange} 
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="codigoSeveridad">
                                <Form.Label>Severidad del Riesgo</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="codigoSeveridad"
                                    value={formData.codigoSeveridad}
                                    onChange={handleChange}
                                >
                                    <option value="">Seleccione...</option>
                                    <option value="1">Alta</option>
                                    <option value="2">Media</option>
                                    <option value="3">Baja</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="controlImpl">
                                <Form.Label>Control Implementado</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="controlImpl"
                                    value={formData.controlImpl}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="recursos">
                                <Form.Label>Recursos</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="recursos"
                                    value={formData.recursos}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group controlId="fechaInicio">
                                <Form.Label>Fecha Inicio</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="fechaInicio"
                                    value={formData.fechaInicio}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="fechaFinal">
                                <Form.Label>Fecha Final</Form.Label>
                                <Form.Control
                                    type="date"
                                    name="fechaFinal"
                                    value={formData.fechaFinal}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={onSave}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditarSubevento;
