import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Box, Typography, Button, FormControl, Stack } from '@mui/material';
import Select from 'react-select';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const ObjetivoModal = ({ isOpen, onClose, periodo, tipoObjetivo, onInsertSuccess }) => {
  const [data, setData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (isOpen && tipoObjetivo) {
      axios.get("/api/get/objetivo"+tipoObjetivo+"/"+periodo, {
        headers: { "x-access-token": localStorage.getItem('token') }
      })
        .then(response => {
          setData(response.data);
        })
        .catch(error => console.error(error));
    }
  }, [isOpen, tipoObjetivo]);

  const handleSelectionChange = (selectedOptions) => {
    if (selectedOptions.some(option => option.value === 'select-all')) {
      if (selectedOptions.length === data.length + 1) {
        setSelectedOptions([]);
      } else {
        setSelectedOptions(formatOptions);
      }
    } else {
      setSelectedOptions(selectedOptions);
    }
  };

  const handleSubmit = () => {
    const selectedValues = selectedOptions.map(option => option.value);
    axios.post('/api/insert/periodo-objetivo', { periodo, tipoObjetivo, selectedOptions: selectedValues }, {
      headers: { "x-access-token": localStorage.getItem('token') }
    })
      .then(response => {
        onInsertSuccess();  // Llama a la función para recargar los datos de la tabla
        onClose();
        setSelectedOptions([]);
      })
      .catch(error => console.error(error));
  };

  const formatOptions = data.map(item => ({
    value: item.CODIGO,
    label: item.DESCRIPCION
  }));

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Seleccionar Objetivos
        </Typography>
        <FormControl fullWidth>
          <Select
            isMulti
            options={[{ value: 'select-all', label: 'Seleccionar todos' }, ...formatOptions]}
            value={selectedOptions}
            onChange={handleSelectionChange}
            placeholder="Seleccione objetivos"
          />
        </FormControl>
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <Button variant="contained" onClick={handleSubmit}>Aceptar</Button>
          <Button variant="outlined" onClick={onClose}>Cancelar</Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ObjetivoModal;
