import React, { useState, useEffect } from "react";
import { Accordion, Button, Row, Col } from "react-bootstrap";
import { BsCheck } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";
import { VscFilePdf } from "react-icons/vsc";
import FormSinacig from "./FormSinacig.jsx";
import { RiRefreshFill } from "react-icons/ri";
import './reportes.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// HTTP Requests
import Axios from 'axios';

function SinacigAcc(props) {
    const xlsx = require('xlsx')

    const [listaAreas, setListaAreas] = useState([]);
    const [listaAreasSelec, setListaAreasSelec] = useState([]);
    const [periodo, setPeriodo] = useState('');
    const [listaAreasSelecDesc, setListaAreasSelecDesc] = useState([]);
    const [nombres, setNombres] = useState('S')
    const [dyp, setDyp] = useState([true]);
    const [ayp, setAyp] = useState([false]);
    const [ryp, setRyp] = useState([false]);
    // primer par
    const [isLoading1, setLoading1] = useState(false);
    const [isLoading2, setLoading2] = useState(false);
    const [isLoading3, setLoading3] = useState(false);
    const [isLoading4, setLoading4] = useState(false);
    const [isLoading5, setLoading5] = useState(false);
    const [isLoading6, setLoading6] = useState(false);
    const [isLoading7, setLoading7] = useState(false);
    const [isLoading8, setLoading8] = useState(false);

    useEffect(() => {
        Axios.get("/api/get/areas", { headers: { "x-access-token": localStorage.getItem('token'), } })
            .then((response) => {
                setListaAreas(response.data);
            });
    }, [])

    const checkParr = () => {
        var texto = '';
        /*if (listaAreasSelec.length < 1) {
            texto = texto + 'Seleccione al menos un área.'
        }*/
        if (periodo === '') {
            texto = texto + ' Seleccione un periodo válido.'
        }
        if (texto !== '') {
            toast.error(texto);
            return false
        } else {
            return true
        }
    }

    const getTipo = () => {
        if (dyp) {
            return 'dyp';
        } else if (ayp) {
            return 'ayp';
        } else {
            return 'ryp';
        }
    }

    const generateXlsx = (tipoReporte, imagenMapa) => {
        let mensaje = ''

        if (tipoReporte === 'mer') {
            mensaje = 'Matriz de Evaluación de Riesgos'
        } else if (tipoReporte === 'mr') {
            mensaje = 'Mapa de Riesgos'
        } else if (tipoReporte === 'rgo') {
            mensaje = 'Informe de riesgo'
        } else {
            mensaje = 'Matriz de Continuidad de Riesgos'
        }

        toast.info(`Generando reporte`);

        Axios.get('/api/get/generar-reporte-excel',

            {
                params: { tipo: tipoReporte, codigoPeriodo: periodo, codigoArea: listaAreasSelec.toString(), imagenMapa: null },

                headers: { "x-access-token": localStorage.getItem('token'), },
                responseType: 'blob',
            },).then(
                (response) => {

                    if (response.headers['content-length'] === '0') {
                        toast.error('Ha ocurrido un error al generar el reporte, se ha enviado un correo a soporte. Tendrá pronta respuesta, gracias.')
                    } else {
                        var name = mensaje;
                        var result = response.data.result;
                        var newResult = [];
                        let data = [];
                        result.forEach((value) => {
                            newResult.push(value);
                        })
                        Object.keys(newResult).forEach((key) => {
                            data.push(newResult[key]);
                        })
                        const workSheet = xlsx.utils.json_to_sheet(data);
                        const workBook = xlsx.utils.book_new();
                        xlsx.utils.book_append_sheet(workBook, workSheet, "data");
                        xlsx.write(workBook, { bookType: 'xlsx', type: 'buffer' });
                        xlsx.write(workBook, { bookType: 'xlsx', type: 'binary' });
                        xlsx.writeFile(workBook, `${name}.xlsx`)
                    }
                });
    }


    const generarReporte = (tipoReporte, imagenMapa,secondFunction) => {
        let mensaje = ''

        if (tipoReporte === 'mer') {
            mensaje = 'Matriz de Evaluación de Riesgos'
        } else if (tipoReporte === 'mr') {
            mensaje = 'Mapa de Riesgos'
        } else if (tipoReporte === 'rgo') {
            mensaje = 'Informe de riesgo'
        } else {
            mensaje = 'Matriz de Continuidad de Riesgos'
        }

        toast.info(`Generando reporte`);

        Axios.get('/api/get/generar-reporte',

            {
                params: { tipo: tipoReporte, codigoPeriodo: periodo, codigoArea: (listaAreasSelec != null ? listaAreasSelec.toString(): null),
                    descArea: (listaAreasSelecDesc != null ? listaAreasSelecDesc.toString(): null), imagenMapa: null },

                headers: { "x-access-token": localStorage.getItem('token'), },
                responseType: 'blob',
            },).then(
                (response) => {
                    if (response.headers['content-length'] === '0') {
                        toast.error('Ha ocurrido un error al generar su reporte, se ha enviado un correo a soporte. Tendrá pronta respuesta, gracias.')
                        secondFunction(false)
                    } else {
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' }
                        );
                        const fileURL = URL.createObjectURL(file);
                        const link = document.createElement('a');
                        link.href = fileURL;
                        link.setAttribute('download', `${mensaje}.pdf`,);
                        document.body.appendChild(link);
                        link.click();
                        window.open(fileURL);
                        link.parentNode.removeChild(link);
                        secondFunction(false)
                    }
                });
    }

    return (
        <>
        <p className="lead blog-description">
            Generación de reportes institucionales
        </p>
        <hr />
            {
                (dyp || ayp || ryp) ?
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <BsCheck /> 1. Matriz de evaluación de riesgos
                            </Accordion.Header>
                            <Accordion.Body>
                                <FormSinacig dyp={dyp} ayp={ayp} ryp={ryp} listaAreas={listaAreas}
                                    listaAreasSelec={listaAreasSelec} setListaAreasSelec={setListaAreasSelec}
                                    listaAreasSelecDesc={listaAreasSelecDesc} setListaAreasSelecDesc={setListaAreasSelecDesc}
                                    periodo={periodo} setPeriodo={setPeriodo} periodos={props.periodos}
                                />
                                <Row className="justify-content-md-center">
                                    <Col xs lg="11" >
                                        <Button size="sm" variant="secondary"
                                            onClick={() => { setListaAreasSelec(''); setPeriodo(''); }}
                                        >
                                            <RiRefreshFill /> Reiniciar filtros
                                        </Button>
                                        <Button
                                            variant="danger"
                                            disabled={isLoading1}
                                            size='sm'
                                            onClick={() => {
                                                if (checkParr()) {
                                                    setLoading1(true);
                                                    generarReporte('mer', '',setLoading1)
                                                    //generatePDF('Matriz evaluación de riesgos', 'mer', setLoading1);
                                                }
                                            }}
                                        >
                                            <VscFilePdf /> {(isLoading1) ? 'Cargando...' : ' Generar PDF'}

                                        </Button>
                                     {/*   <Button
                                            variant="success"
                                            size='sm'
                                            disabled={isLoading2}
                                            onClick={() => {
                                                if (checkParr()) {
                                                    setLoading2(true);
                                                    generateXlsx('mer', setLoading2);
                                                }
                                            }}
                                        >
                                            <SiMicrosoftexcel /> {(isLoading2) ? 'Cargando...' : ' Generar .xlsx'}
                                        </Button>*/}
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <BsCheck /> 2. Mapa de calor de riesgos inherente
                            </Accordion.Header>
                            <Accordion.Body>
                                <FormSinacig dyp={dyp} ayp={ayp} ryp={ryp} listaAreas={listaAreas}
                                    listaAreasSelec={listaAreasSelec} setListaAreasSelec={setListaAreasSelec}
                                    listaAreasSelecDesc={listaAreasSelecDesc} setListaAreasSelecDesc={setListaAreasSelecDesc}
                                    periodo={periodo} setPeriodo={setPeriodo} periodos={props.periodos}
                                />
                                <Row className="justify-content-md-center">
                                    <Col xs lg="11" >
                                        <Button size="sm" variant="secondary"
                                            onClick={() => { setListaAreasSelec(''); setPeriodo(''); }}
                                        >
                                            <RiRefreshFill /> Reiniciar filtros
                                        </Button>
                                        <Button
                                            variant="danger"
                                            size='sm'
                                            disabled={isLoading3}
                                            onClick={() => {
                                                if (checkParr()) {
                                                    setLoading3(true);
                                                    generarReporte('mrio', '',setLoading3)
                                                }
                                            }}
                                        >
                                            <VscFilePdf /> Generar PDF
                                        </Button>
                                        {/*<Button
                                            variant="success"
                                            size='sm'
                                            disabled={isLoading4}
                                            onClick={() => {
                                                if (checkParr()) {
                                                    setLoading4(true);
                                                    generateXlsx('mr', setLoading4);
                                                }
                                            }}
                                        >
                                            <SiMicrosoftexcel /> generar .xlsx
                                        </Button>*/}
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                <BsCheck /> 3. Mapa de calor riesgos residual
                            </Accordion.Header>
                            <Accordion.Body>
                                <FormSinacig dyp={dyp} ayp={ayp} ryp={ryp} listaAreas={listaAreas}
                                    listaAreasSelec={listaAreasSelec} setListaAreasSelec={setListaAreasSelec}
                                    listaAreasSelecDesc={listaAreasSelecDesc} setListaAreasSelecDesc={setListaAreasSelecDesc}
                                    periodo={periodo} setPeriodo={setPeriodo} periodos={props.periodos}
                                />
                                <Row className="justify-content-md-center">
                                    <Col xs lg="11" >
                                        <Button size="sm" variant="secondary"
                                            onClick={() => { setListaAreasSelec(''); setPeriodo(''); }}
                                        >
                                            <RiRefreshFill /> Reiniciar filtros
                                        </Button>
                                        <Button
                                            variant="danger"
                                            size='sm'
                                            disabled={isLoading5}
                                            onClick={() => {
                                                if (checkParr()) {
                                                    setLoading5(true);
                                                    generarReporte('mro', '',setLoading5)
                                                }
                                            }}
                                        >
                                            <VscFilePdf /> Generar PDF
                                        </Button>
                                       {/* <Button
                                            variant="success"
                                            size='sm'
                                            disabled={isLoading6}
                                            onClick={() => {
                                                if (checkParr()) {
                                                    setLoading6(true);
                                                    generateXlsx('mr', setLoading6);
                                                }
                                            }}
                                        >
                                            <SiMicrosoftexcel /> generar .xlsx
                                        </Button>*/}
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                <BsCheck /> 4. Matriz de continuidad de evaluación de riesgos
                            </Accordion.Header>
                            <Accordion.Body>
                                <FormSinacig dyp={dyp} ayp={ayp} ryp={ryp} listaAreas={listaAreas}
                                    listaAreasSelec={listaAreasSelec} setListaAreasSelec={setListaAreasSelec}
                                    listaAreasSelecDesc={listaAreasSelecDesc} setListaAreasSelecDesc={setListaAreasSelecDesc}
                                    periodo={periodo} setPeriodo={setPeriodo} periodos={props.periodos}
                                />
                                <Row className="justify-content-md-center">
                                    <Col xs lg="11" >
                                        <Button size="sm" variant="secondary"
                                            onClick={() => { setListaAreasSelec(''); setPeriodo(''); }}
                                        >
                                            <RiRefreshFill /> Reiniciar filtros
                                        </Button>
                                        <Button
                                            variant="danger"
                                            size='sm'
                                            disabled={isLoading7}
                                            onClick={() => {
                                                if (checkParr()) {
                                                    setLoading7(true);
                                                    generarReporte('mcr', '',setLoading7)
                                                }
                                            }}
                                        >
                                            <VscFilePdf /> {(isLoading7) ? 'Cargando...' : ' Generar PDF'}
                                        </Button>
                                    </Col>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    :
                    <></>
            }

        </>
    );

}

export default SinacigAcc