import React, { useState } from "react";
import './rolUsuario.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Button, Modal, Row, Col, InputGroup } from "react-bootstrap";

// HTTP Requests
import Axios from 'axios';

export const AsignarRol = (props) => {

    const [validated, setValidated] = useState(false);
    const [institucion, setInstitucion] = useState('');
    const [rol, setRol] = useState([]);
    const [persona, setPersona] = useState('');
    const [listaPersonas, setListaPersonas] = useState([]);
    const [inicio, setInicio] = useState('');
    const [fin, setFin] = useState('');

    const cancelNew = () => {
        setListaPersonas([]);
        setInstitucion('');
        setRol([]);
        setPersona('');
        setInicio('');
        setFin('');
        setValidated(false);
        props.setShowNew(false);
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            setValidated(true);
        } else {
            submitPersona();
        }
        setValidated(true);
      };

      const submitPersona = () => {
        Axios.post("/api/insert/rolnuevopersona", {
            fi: inicio,
            ff: fin,
            rol: rol,
            ins: institucion,
            per: persona,
        },{headers:{"x-access-token": localStorage.getItem('tokenAdminSys')}}).then((response)=>{
            if(response.data.result){
                toast.success(response.data.result)
            }
            if(response.data.msgE){
                toast.info(response.data.msgE)
            }
            clear();
            props.setShowNew(false);
            props.refresh()
        });
    };

    const clear = () => {
        setValidated(false);
        setListaPersonas([]);
        setInstitucion('');
        setRol([]);
        setPersona('');
        setInicio('');
        setFin('');
    }

    return(
        <Modal show={props.showNew} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
                <Modal.Title>ASIGNAR NUEVO ROL A USUARIO</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                            <Form.Label>Institución</Form.Label>
                            <Form.Select
                                required
                                value={institucion}
                                onChange={(e)=>{
                                    if(e.target.value !== ''){
                                        setInstitucion(e.target.value);
                                        Axios.get('/api/get/listaUsuariosInstitucionRoles/'+String(e.target.value), 
                                        {headers:{"x-access-token": localStorage.getItem('tokenAdminSys')}}).then((response)=>{
                                            if(response.data.auth === false){} else{
                                                setListaPersonas(response.data.result);
                                            }
                                        })
                                    } else {
                                        setListaPersonas([]);
                                    }
                                }}
                            >   
                            <option value=''>Seleccione institución</option>
                            {
                                props.instituciones.map((value)=>{
                                    return(<option value={value.CODIGO} key={value.CODIGO}>{value.NOMBRE}</option>)
                                })
                            }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Seleccione una institución
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md="12" controlId="validationCustom02">
                            <Form.Label>Persona</Form.Label>
                            <Form.Select
                                required
                                onChange={(e)=>{
                                    setPersona(e.target.value)
                                }}
                            >   
                                <option value=''>Elija una persona</option>
                                {
                                    listaPersonas.map((value)=>{
                                        return(<option value={value.USUARIO_WEB} key={value.CODIGO_PERSONA} >
                                            {value.USUARIO_WEB} - {value.NOMBRE_COMPLETO} - [{(String(value.USUARIO_VALIDO) === '1') ? 'VALIDO':'NO VALIDO'}]
                                        </option>)
                                    })    
                                }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Seleccione una persona de la institución
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <p/>
                    <Row>
                        <Form.Group as={Col} md="12" controlId="validationCustom02">
                            <Form.Label>Rol</Form.Label>
                            <InputGroup>
                                <Button 
                                    onClick={()=>{
                                        let newArray = []
                                        props.roles.forEach((value) => {
                                            newArray.push(value.CODIGO_ROL);
                                        });
                                        setRol(newArray);
                                    }}
                                    variant="outline-secondary" 
                                >
                                    Seleccionar todos los roles
                                </Button>
                                <Form.Select
                                    required
                                    multiple
                                    value={rol}
                                    onChange={(e)=>{
                                        let value = Array.from(e.target.selectedOptions, option => option.value);
                                        setRol(value);
                                    }}
                                >   
                                    {
                                        props.roles.map((value)=>{
                                            return(<option value={value.CODIGO_ROL} key={value.CODIGO_ROL}>{value.NOMBRE}</option>)
                                        })
                                    }
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Seleccione al menos un rol
                                </Form.Control.Feedback>
                            </InputGroup>
                                    
                        </Form.Group>
                    </Row>
                    <p/>
                    <Row>
                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label>Fecha de inicio de vigencia</Form.Label>
                            <Form.Control
                                    required
                                    type='date'
                                    onChange={(e)=>{setInicio(e.target.value)}}
                                    value={inicio}
                                >

                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Indique fecha de inicio de vigencia    
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label>Fecha de fin de vigencia</Form.Label>
                            <Form.Control
                                    required
                                    type='date'
                                    onChange={(e)=>{setFin(e.target.value)}}
                                    value={fin}
                                >

                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Indique fecha de fin de vigencia
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <hr/>
                    <Button type="submit" variant="success" size='sm'>Guardar</Button>
                    <Button variant="secondary" size='sm' onClick={()=>{cancelNew();}}>Cancelar</Button>
                </Form>                
            </Modal.Body>
        </Modal>
    )

}