import React, { useState, useEffect } from "react";
import './instituciones.css'
import { Form, Button, Modal, Row, Col} from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// HTTP Requests
import Axios from 'axios';

export const CrearEntidad = (props) => {

    const [validated, setValidated] = useState('');
    const [nombre, setNombre] = useState('');
    const [siglas, setSiglas] = useState('');

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            setValidated(true);
        } else {
            submitData();
        }
        setValidated(true);
    };

    const submitData = () => {
        const opts ={
            nombre: nombre,
            siglas: siglas
        }
        if(props.modalCodigo){
            opts['codigo']=props.modalCodigo;
            Axios.post('/api/update/entidadAdmin', opts, {headers:{"x-access-token": localStorage.getItem('tokenAdminSys')}})
            .then(
                (response)=>{
                    if (response.data.error){
                        toast.error('Error al actualizar entidad');
                    } else if (response.data.result) {
                        toast.success('Entidad modificada');
                        props.refresh();
                        closeModal();
                    } 
                }
            )
        } else {
            Axios.post('/api/post/entidadAdmin', opts, {headers:{"x-access-token": localStorage.getItem('tokenAdminSys')}})
            .then(
                (response)=>{
                    if (response.data.error){
                        toast.error('Error al guardar nueva entidad');
                    } else if (response.data.result) {
                        toast.success('Entidad creada');
                        props.refresh();
                        setNombre('');
                        setSiglas('');
                        closeModal();
                    } 
                }
            );
        }
    }

    const closeModal = () => {
        props.setShowE(false);
        setValidated(false);
    }

    useEffect(()=>{
        setNombre(props.modalNombre);
        setSiglas(props.modalSiglas);
    },[props.modalCodigo])

    return(
        <Modal show={props.showE} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
                <Modal.Title>{props.tipo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className="mb-1">
                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                            <Form.Label>Nombre entidad</Form.Label>
                            <Form.Control maxLength="500" required type="text" value={nombre} placeholder="Nombre de la institución" onChange={(e)=>{setNombre(e.target.value)}} />
                            <Form.Control.Feedback type="invalid"> Ingrese el nombre de la institución </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId="validationCustom02">
                            <Form.Label>Siglas</Form.Label>
                            <Form.Control maxLength="10" required type="text" value={siglas} placeholder="Siglas" onChange={(e)=>{setSiglas(e.target.value)}} onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()} />
                        </Form.Group>
                    </Row>
                    <hr/>
                    <Button type="submit" variant="success" size='sm'>Guardar</Button>
                    <Button variant="secondary" size='sm' onClick={()=>{closeModal();}}>Cancelar</Button>
                </Form>                
            </Modal.Body>
        </Modal>
    );

}