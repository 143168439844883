import React, { useState} from "react";
import './suggestionsInput.css'
import { Form } from 'react-bootstrap';

export const SuggestionsInput = (props) => {

    /**
     * 
     */
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);

    const onChange = (e) => {
      const userInput = e.target.value;
      
      props.setInput(e.target.value);
      
      // Filter our suggestions that don't contain the user's input
      const unLinked = props.suggestions.filter(
        (suggestion) =>
          suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );

      setFilteredSuggestions(unLinked);
      setActiveSuggestionIndex(-1);
      setShowSuggestions(true);
    };
    
    const onClick = (e) => {
      setFilteredSuggestions([]);
      props.setInput(e.target.innerText);
      setActiveSuggestionIndex(0);
      setShowSuggestions(false);
    };
    /*
    const onKeyDown = (e) => {
      // User pressed the enter key
      if (e.keyCode === 13) {
        props.setInput(filteredSuggestions[activeSuggestionIndex]);
        setActiveSuggestionIndex(0);
        setShowSuggestions(false);
      }
      // User pressed the up arrow
      else if (e.keyCode === 38) {
        if (activeSuggestionIndex === 0) {
          return;
        }
        setActiveSuggestionIndex(activeSuggestionIndex - 1);
      }
      // User pressed the down arrow
      else if (e.keyCode === 40) {
        if (activeSuggestionIndex - 1 === filteredSuggestions.length) {
          return;
        }
        setActiveSuggestionIndex(activeSuggestionIndex + 1);
      }
    };
    */
    const SuggestionsListComponent = () => {
      return filteredSuggestions.length ? (
        <ul className="suggestions">
          {filteredSuggestions.map(
            (suggestion, index) => {
              let className;
              // Flag the active suggestion with a class
              if (index === activeSuggestionIndex) {
                className = "suggestion-active";
              }
              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                  </li>
                  );
            }
          )}
        </ul>
      ) : (<div/>);
    };


  return(
      <>
          <Form.Control
              required={props.required}
              type="text"
              onChange={onChange}
              value={props.input}
              placeholder={props.placeholder}
              maxLength={props.max}
          />
          {showSuggestions && props.input && <SuggestionsListComponent/>}
      </>
  );
}