import React, { useState, useEffect } from "react";
import {Table} from 'react-bootstrap';
import './formulario.css'
import {Areas} from './area'
import UpdatedNavBar from "../NavBar/UpdatedNavBar";

// HTTP Requests
import Axios from 'axios';

export const AreasView = () => {

    /**
     * States de areas
     */
    const [descrArea, setDescrArea] = useState('');
    const [abreviaturaArea, setAbreviaturaArea] = useState('');
    const [idArea, setIdArea] = useState('');

    /**
     * nombre usuario
     */
    const [usuarioWeb, setUsuarioWeb] = useState('');

    useEffect(()=> {
        Axios.get("/api/getuserweb",
        {headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=> {
            setUsuarioWeb(response.data.usuarioWeb);
        });
    }, [])

    /**
     * Bandera para área de evaluación
     */
    const [areaFlag, setAreaFlag] = useState(true);

    return(
        <>
            <UpdatedNavBar />
            <div className="container-dependencias">
                {
                    (areaFlag) ?
                    <Areas 
                        descrArea={descrArea} setDescrArea={setDescrArea} 
                        abreviaturaArea={abreviaturaArea} setAbreviaturaArea={setAbreviaturaArea}
                        idArea={idArea} setIdArea={setIdArea}
                        setAreaFlag={setAreaFlag}
                        usuarioWeb={usuarioWeb}
                    /> : 
                    <>
                        <h2>Área</h2 >
                        <p className="lead blog-description">Áreas de la organización</p>
                        
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Descripción</th>
                                    <th>Abreviatura</th>
                                </tr> 
                                <tr className="segunda-fila">
                                    <td>{descrArea}</td>
                                    <td>{abreviaturaArea}</td>
                                </tr>
                            </thead>
                        </Table>
                    </>
                }
            </div>
        
        </>
    )

}
