import React, { useEffect, useState } from 'react';
import './home.css';
import { Card } from 'react-bootstrap';
import { Box } from '@mui/material';

// HTTP Requests
import Axios from 'axios';

export const Anuncios = () => {

    const [listaAnuncios, setListaAnuncios] = useState([]);

    useEffect(()=>{
        Axios.get('/api/getNews',{headers:{"x-access-token": localStorage.getItem('token'),},}).then(
            (response)=> {
                setListaAnuncios(response.data.result)
            }
        );
    }, [])

    return (
        <>
        <Box sx={{width: {xs: '90%', md: '50%'}}}>
            {
                listaAnuncios.map((value)=>{
                    let color;
                    if(String(value.css) === 'warning'){
                        color = '#FFD100'
                    } else if (String(value.css) === 'info'){
                        color = '#31708f'
                    }
                    return(
                        <Card key={value.id_notificacion} className='carta-anuncio'>
                            <Card.Header>
                                <Card.Title style={{color:color}}>{value.titulo}</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    {
                                        (String(value.url).length > 0 && String(value.url).includes('outube')) 
                                        &&
                                        <div className="video-container">
                                            <iframe title='video' width="560" height="315" src={value.url} frameborder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscree;" className="video" allowfullscreen/>
                                        </div>
                                    }
                                    {value.descripcion}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    )
                })
            }
        </Box>
        
        </>
    )
    
} 