import React, { useEffect } from 'react';
import {Navbar , Container, Nav } from 'react-bootstrap';
import './navBar.css';
import Axios from 'axios';

export const NavBar = () => {

  function getPosition(string, subString, index) {
    return string.split(subString, index).join(subString).length;
  }

  const logOut = () => {
    localStorage.clear();
    window.location = String(window.location.href).substring(0,getPosition(window.location.href,'/',3))+'/loginAdminSystem';
  }

  useEffect(()=>{
    Axios.get('/api/get/VerifyAdminSystem',{headers:{"x-access-token": localStorage.getItem('tokenAdminSys')}}).then((response)=>{
      if (response.data.auth === false){
        localStorage.clear();
        window.location = String(window.location.href).substring(0,getPosition(window.location.href,'/',3));
      }
    }
  );
  },[])
  
  return ( 
    <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark">
      <Container>
      <Navbar.Brand href="/">Gestión de riesgos</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          {/*<Nav.Link href="controlUsuarios" >Control Usuarios</Nav.Link>*/}
          <Nav.Link href="cantidadUsuarios" >Cantidad Usuarios</Nav.Link>
          <Nav.Link href="Historial" >Historial</Nav.Link>
          <Nav.Link href="institucion" >Institución</Nav.Link>
          <Nav.Link href="rolUsuario" >Rol usuario</Nav.Link>
          <Nav.Link href="usuario" >Usuario</Nav.Link>
        </Nav>
        <Navbar.Text>
          <button type='reset' onClick={()=>logOut()}><Navbar.Text>Cerrar sesión</Navbar.Text> </button>
        </Navbar.Text>
      </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};