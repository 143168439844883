import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, InputGroup, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { AiOutlineSearch, AiOutlinePlus } from 'react-icons/ai';
import * as XLSX from 'xlsx';
import { VscFilePdf } from "react-icons/vsc";
import { toast } from 'react-toastify';

const TablaDatosTipoObjetivo = ({ TipoObjetivo, CodigoPeriodo, reloadTrigger }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [listaPrin, setListaPrin] = useState([]);




    //// reporte de Excel 
    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Datos');
        XLSX.writeFile(workbook, 'datos_objetivos.xlsx');
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                axios.get("/api/get/periodo-objetivo" + TipoObjetivo + "/" + CodigoPeriodo, { headers: { "x-access-token": localStorage.getItem('token') } })
                    .then((response) => {
                        setData(response.data);
                        setListaPrin(response.data);
                    });
            } catch (error) {
                console.error("Error fetching data", error);
                setData([]); // Establecer data como un array vacío en caso de error
            } finally {
                setLoading(false);
            }
        };

        if (TipoObjetivo) {
            fetchData();
        }
    }, [TipoObjetivo, CodigoPeriodo, reloadTrigger]); // Agrega reloadTrigger como dependencia

    if (loading) {
        return <p>Cargando...</p>;
    }

    if (!Array.isArray(data)) {
        return <p>Error: Data is not an array.</p>;
    }

    const busquedaObjetivo = (listaInfo) => {
        const input1 = document.getElementById('input-1').value;
        const input2 = document.getElementById('input-2').value;
        let tmpArray = [];
        if (String(input1) === '' && String(input2) === '') {
            return listaInfo;
        } else {
            const indexes = listaInfo.map(
                (element, index) => {
                    let flag = false;

                    if (String(input1) !== '') {
                        if (element.DESCRIPCION.toLowerCase().includes(input1.toLowerCase())) {
                            flag = true;
                        } else {
                            return -1;
                        }
                    }

                    if (String(input2) !== '') {
                        if (element.ABREVIATURA.toLowerCase().includes(input2.toLowerCase())) {
                            flag = true;
                        } else {
                            return -1;
                        }
                    }

                    if (flag) {
                        return index;
                    }

                }
            ).filter(value => value >= 0);
            for (let i of indexes) {
                tmpArray.push(listaInfo[i]);
            }
            return tmpArray;
        }
    };
    const desasignar = async (CODIGO) => {
        var codigoObjetivo = CODIGO;
        try {
            await axios.post('/api/delete/periodo-objetivo', { CodigoPeriodo, TipoObjetivo, codigoObjetivo }, {
                headers: { "x-access-token": localStorage.getItem('token') }
            });
            // Fuerza una recarga de datos
            setLoading(true); // Muestra el estado de carga mientras se obtienen los datos nuevamente
            const response = await axios.get("/api/get/periodo-objetivo" + TipoObjetivo + "/" + CodigoPeriodo, {
                headers: { "x-access-token": localStorage.getItem('token') }
            });
            setData(response.data);
            setListaPrin(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error desasignando objetivo", error);
        }
    };
    const generarReporte = (mensaje) => {
        toast.info(`El reporte ${mensaje} se esta generando`);

        axios.get('/api/get/generar-reporte',
            {
                params: { tipo: String('otp'), codigoPeriodo: CodigoPeriodo, tipoObjetivo: TipoObjetivo },
                headers: { "x-access-token": localStorage.getItem('token'), },
                responseType: 'blob',
            },).then((response) => {
                if (response.headers['content-length'] === '0') {
                    toast.error('Ha ocurrido un error al generar su reporte, se ha enviado un correo a soporte. Tendrá pronta respuesta, gracias.')
                } else {
                    //Create a Blob from the PDF Stream
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' }
                    );
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file);
                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.setAttribute('download', `${mensaje}.pdf`,);
                    // Append to html link element page
                    document.body.appendChild(link);
                    // Start download
                    link.click();
                    //Open the URL on new Window
                    window.open(fileURL);
                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                }
            });
    }

    return (
        <table className="table">
            <thead>
                <tr>
                    <td>
                        <DropdownButton
                            as={ButtonGroup}
                            title="Acciones"
                            variant="secondary"
                            id="dropdown-acciones"
                        >
                            <Dropdown.Item as="button" onClick={exportToExcel}>
                                Exportar a Excel
                            </Dropdown.Item>
                            <Dropdown.Item as="button" onClick={() => { generarReporte('Objetivos por tipo de objetivo y período') }}>
                                <VscFilePdf /> Generar PDF
                            </Dropdown.Item>
                        </DropdownButton>
                    </td>

                    <td>
                        <InputGroup className="search-input">
                            <Button
                                variant="outline-secondary"
                                onClick={() => {
                                    setData(busquedaObjetivo(listaPrin));
                                }}
                            >
                                <AiOutlineSearch />
                            </Button>
                            <Form.Control
                                onChange={() => {
                                    setData(busquedaObjetivo(listaPrin));
                                }}
                                id="input-1"
                                type="text"
                                placeholder="búsqueda por descripción"
                            />
                        </InputGroup>
                    </td>
                    <td>
                        <InputGroup className="search-input">
                            <Button
                                variant="outline-secondary"
                                onClick={() => {
                                    setData(busquedaObjetivo(listaPrin));
                                }}
                            >
                                <AiOutlineSearch />
                            </Button>
                            <Form.Control
                                onChange={() => {
                                    setData(busquedaObjetivo(listaPrin));
                                }}
                                id="input-2"
                                type="text"
                                placeholder="búsqueda por abreviatura"
                            />
                        </InputGroup>
                    </td>
                </tr>
                <tr>
                    <th>Opción</th>
                    <th>Descripción</th>
                    <th>Abreviatura</th>
                </tr>
            </thead>
            <tbody>
                {data.map((item) => (
                    <tr key={item.CODIGO}>
                        <td>
                            <div className="dropdown">
                                <Button size='sm' variant="success" className="nueva-dependencia" onClick={() => desasignar(item.CODIGO_OBJETIVO)}>
                                    <AiOutlinePlus /> Desasignar
                                </Button>

                            </div>
                        </td>
                        <td>{item.DESCRIPCION}</td>
                        <td>{item.ABREVIATURA}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default TablaDatosTipoObjetivo;
