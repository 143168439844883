import React, { useState, useEffect } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import Axios from 'axios';

function InformacionGeneralRiesgo({
    setPeriodo,
    setTipObj: updateParentTipObj,
    setSelectedObjective,
    setSelectedArea,
    periodo,
}) {
    const [tipObj, setTipObj] = useState('');
    const [listaTipObj, setListaTipObj] = useState([]);
    const [listaPrin, setListaPrin] = useState([]);
    const [listaAreas, setListaAreas] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch tiposObjetivos
        Axios.get('/api/get/tiposObjetivos', {
            headers: { 'x-access-token': localStorage.getItem('token') },
        }).then((response) => {
            setListaTipObj(response.data);
        });
    }, []);

    const fetchObjetivos = async (tipoObjetivo) => {
        if (tipoObjetivo && periodo) {
            Axios.get(
                `/api/get/periodo-objetivo${tipoObjetivo}/${periodo}`,
                { headers: { 'x-access-token': localStorage.getItem('token') } }
            )
                .then((response) => {
                    if (Array.isArray(response.data)) {
                        setListaPrin(response.data);
                    } else {
                        setListaPrin([]);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching data', error);
                    setListaPrin([]);
                });
        }
    };

    const fetchAreas = async (selectedObjective) => {
        if (periodo && selectedObjective && tipObj) {
            try {
                const response = await Axios.get(
                    `/api/get/objetivo-area-tabla${tipObj}/${periodo}/${selectedObjective}`,
                    { headers: { 'x-access-token': localStorage.getItem('token') } }
                );
                if (Array.isArray(response.data)) {
                    setListaAreas(response.data);
                } else {
                    setListaAreas([]);
                }
            } catch (error) {
                console.error('Error fetching data', error);
                setListaAreas([]);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <>
            {/* Tipo de Objetivo */}
            <Row className="mb-3">
                <Form.Group as={Col} md="2"></Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label>
                        <b>Tipo de Objetivo</b>
                    </Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Select
                        onChange={(e) => {
                            if (e.target.value !== '') {
                                const newTipObj = e.target.value;
                                setTipObj(newTipObj);
                                updateParentTipObj(newTipObj); // Update parent component
                                fetchObjetivos(newTipObj);
                            }
                        }}
                    >
                        <option value=""></option>
                        {listaTipObj.map((value) => (
                            <option
                                value={value.CODIGO}
                                key={value.CODIGO}
                                data-description={value.DESCRIPCION}
                            >
                                {value.DESCRIPCION}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Row>

            {/* Objetivo */}
            <Row className="mb-3">
                <Form.Group as={Col} md="2"></Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label>
                        <b>Objetivo</b>
                    </Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Select
                        onChange={(e) => {
                            if (e.target.value !== '') {
                                const selectedOption = e.target.selectedOptions[0];
                                setSelectedObjective(
                                    e.target.value,
                                    selectedOption.getAttribute('data-description')
                                );
                                fetchAreas(e.target.value);
                            }
                        }}
                    >
                        <option value=""></option>
                        {listaPrin.map((item) => (
                            <option
                                value={item.CODIGO_OBJETIVO}
                                key={item.CODIGO_OBJETIVO}
                                data-description={item.DESCRIPCION}
                            >
                                {item.DESCRIPCION}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Row>

            {/* Área */}
            <Row className="mb-3">
                <Form.Group as={Col} md="2"></Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label>
                        <b>Área</b>
                    </Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Select
                        onChange={(e) => {
                            if (e.target.value !== '') {
                                const selectedOption = e.target.selectedOptions[0];
                                setSelectedArea(
                                    e.target.value,
                                    selectedOption.getAttribute('data-description')
                                );
                            }
                        }}
                    >
                        <option value=""></option>
                        {listaAreas.map((item) => (
                            <option
                                value={item.CODIGO_AREA}
                                key={item.CODIGO_AREA}
                                data-description={item.DESCRIPCION}
                            >
                                {item.DESCRIPCION}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Row>
        </>
    );
}

export default InformacionGeneralRiesgo;
