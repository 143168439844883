import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Tab, Tabs, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import PersonaResponsableComboBox from './PersonaResponsableComboBox'; 
import FrecuenciaComboBox from './ComboBoxFrecuencia'; 
import ComboBoxVariableMitigacion from './ComboBoxVariableMitigacion'; 
import { DownloadAndUpdate } from "../../../Components/donwloadAndUpdate";
import { UploadFile } from "../../../Components/uploadFile";
import RiesgosMonitoreo from './RiesgoMonitoreo';
import RiesgosInforme from './RiesgoInforme';
import Eventos from './Eventos/Eventos';
const EditarRiesgoModal = ({ isOpen, onClose, riesgo, codigoCia, codigoPeriodo, codigoTipoObjetivo, codigoObjetivo, codigoArea, descripcionPeriodo, descripcionTipObj, descripcionObjetivo, descripcionArea }) => {
    const [formData, setFormData] = useState({
        CODIGO_RIESGO: '',
        DESCRIPCION: '',
        CODIGO_PROBABILIDAD: '',
        CODIGO_SEVERIDAD: '',
        CODIGO_FRECUENCIA: '',
        ADAPTABILIDAD: '',
        CODIGO_MITIGACION: '',
        CODIGO_TOLERANCIA: '',
        DESC_CONTINT_MITIGACION: '',
        DESC_FRECUENCIA_MONITOREO: '',
        DESC_METODO_MONITOREO: '',
        DESC_MITIGACION: '',
        CODIGO_ENCARGADO: '',
        ESTADO_SUPERVISION: '',
        EVENTOS: '',
        FECHA_FIN_MONITOREO: '',
        FECHA_INI_MONITOREO: '',
        FECHA_ULTIMA_SUPERVISION: '',
        NIVEL_CAPACIDAD_MITIGACION: '',
        NOMBRE_DOCUMENTO: '',
        NOMBRE_ARCHIVO: '',
        OBSERVACIONES: '',
        OBSERVACIONES_INFORME: '',
        COMENTARIOS_SUPERVISOR: '',
        RESPONSABLE_DELEGADO: '',
        CODIGO_SUPERVISOR: '',
        VARIABLE_MITIGACION: '' // Nuevo campo para variable a mitigar
    });

    const [listas, setListas] = useState({
        probabilidad: [],
        tolerancia: [],
        severidad: [],
        mitigacion: [],
        capacidadMitigacion: []
    });

    const fetchRiesgoById = async () => {
        if (riesgo) {
            try {
                const response = await axios.get('/api/getRiesgoById', {
                    headers: { "x-access-token": localStorage.getItem('token') },
                    params: {
                        codigoCia:riesgo.CODIGO_CIA,
                        codigoPeriodo: riesgo.CODIGO_PERIODO,
                        codigoTipoObjetivo: riesgo.CODIGO_TIPO_OBJETIVO,
                        codigoObjetivo: riesgo.CODIGO_OBJETIVO,
                        codigoArea: riesgo.CODIGO_AREA,
                        codigoRiesgo: riesgo.CODIGO_RIESGO
                    }
                });
                const data = response.data;
                setFormData({
                    CODIGO_RIESGO: data.CODIGO_RIESGO || '',
                    DESCRIPCION: data.DESCRIPCION || '',
                    CODIGO_PROBABILIDAD: data.CODIGO_PROBABILIDAD || '',
                    CODIGO_SEVERIDAD: data.CODIGO_SEVERIDAD || '',
                    CODIGO_FRECUENCIA: data.CODIGO_FRECUENCIA || '',
                    ADAPTABILIDAD: data.ADAPTABILIDAD || '',
                    CODIGO_MITIGACION: data.CODIGO_MITIGACION || '',
                    CODIGO_TOLERANCIA: data.CODIGO_TOLERANCIA || '',
                    DESC_CONTINT_MITIGACION: data.DESC_CONTINT_MITIGACION || '',
                    DESC_FRECUENCIA_MONITOREO: data.DESC_FRECUENCIA_MONITOREO || '',
                    DESC_METODO_MONITOREO: data.DESC_METODO_MONITOREO || '',
                    DESC_MITIGACION: data.DESC_MITIGACION || '',
                    CODIGO_ENCARGADO: data.CODIGO_ENCARGADO || '',
                    ESTADO_SUPERVISION: data.ESTADO_SUPERVISION || '',
                    EVENTOS: data.EVENTOS || '',
                    FECHA_FIN_MONITOREO: data.FECHA_FIN_MONITOREO || '',
                    FECHA_INI_MONITOREO: data.FECHA_INI_MONITOREO || '',
                    FECHA_ULTIMA_SUPERVISION: data.FECHA_ULTIMA_SUPERVISION || '',
                    NIVEL_CAPACIDAD_MITIGACION: data.NIVEL_CAPACIDAD_MITIGACION || '',
                    NOMBRE_DOCUMENTO: data.NOMBRE_DOCUMENTO || '',
                    NOMBRE_ARCHIVO: data.NOMBRE_ARCHIVO || '',
                    OBSERVACIONES: data.OBSERVACIONES || '',
                    OBSERVACIONES_INFORME: data.OBSERVACIONES_INFORME || '',
                    COMENTARIOS_SUPERVISOR: data.COMENTARIOS_SUPERVISOR || '',
                    RESPONSABLE_DELEGADO: data.RESPONSABLE_DELEGADO || '',
                    CODIGO_SUPERVISOR: data.CODIGO_SUPERVISOR || '',
                    VARIABLE_MITIGACION: data.VARIABLE_MITIGACION || '' // Añadir variable mitigación
                });
                console.log("Datos obtenidos:", response.data);
            } catch (error) {
                console.error("Error fetching data", error);
            }
        }
    };

    const fetchListas = async () => {
        try {
            const responses = await Promise.all([
                axios.get("/api/getRiesgos_riesgo/Probabilidad", { headers: { "x-access-token": localStorage.getItem('token') } }),
                axios.get("/api/getRiesgos_riesgo/Tolerancia", { headers: { "x-access-token": localStorage.getItem('token') } }),
                axios.get("/api/getRiesgos_riesgo/Severidad", { headers: { "x-access-token": localStorage.getItem('token') } }),
                axios.get("/api/getRiesgos_riesgo/Mitigacion", { headers: { "x-access-token": localStorage.getItem('token') } }),
                axios.get("/api/getRiesgos_riesgo/ValorMitigacion", { headers: { "x-access-token": localStorage.getItem('token') } })
            ]);
            setListas({
                probabilidad: Array.isArray(responses[0].data) ? responses[0].data : [],
                tolerancia: Array.isArray(responses[1].data) ? responses[1].data : [],
                severidad: Array.isArray(responses[2].data) ? responses[2].data : [],
                mitigacion: Array.isArray(responses[3].data) ? responses[3].data : [],
                capacidadMitigacion: Array.isArray(responses[4].data) ? responses[4].data : []
            });
            console.log("Lista de capacidad de mitigación:", responses[4].data);
        } catch (error) {
            console.error("Error fetching listas data", error);
        }
    };

    useEffect(() => {
        if (isOpen) {
            fetchRiesgoById();
            fetchListas();
        }
    }, [isOpen]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        if (['FECHA_INI_MONITOREO', 'FECHA_FIN_MONITOREO', 'ESTADO_SUPERVISION'].includes(name)) {
            setFormData({ ...formData, FECHA_ULTIMA_SUPERVISION: new Date().toISOString().split('T')[0] });
        }
    };

    const handleSave = async () => {
        console.log("Datos enviados:", {
            codigoCia,
            codigoPeriodo,
            codigoTipoObjetivo,
            codigoObjetivo,
            codigoArea,
            codigoRiesgo: formData.CODIGO_RIESGO,
            ...formData,
            CODIGO_ENCARGADO: parseInt(formData.CODIGO_ENCARGADO) || null,
            RESPONSABLE_DELEGADO: parseInt(formData.RESPONSABLE_DELEGADO) || null,
            CODIGO_SUPERVISOR: parseInt(formData.CODIGO_SUPERVISOR) || null
        });

        try {
            await axios.put('/api/update/riesgo', {
                codigoCia,
                codigoPeriodo,
                codigoTipoObjetivo,
                codigoObjetivo,
                codigoArea,
                codigoRiesgo: formData.CODIGO_RIESGO,
                ...formData,
                CODIGO_ENCARGADO: parseInt(formData.CODIGO_ENCARGADO) || null,
                RESPONSABLE_DELEGADO: parseInt(formData.RESPONSABLE_DELEGADO) || null,
                CODIGO_SUPERVISOR: parseInt(formData.CODIGO_SUPERVISOR) || null
            }, {
                headers: { "x-access-token": localStorage.getItem('token') }
            });
            console.log("Datos del formulario guardados correctamente:", formData);
            onClose(); // Cerrar el modal después de guardar
        } catch (error) {
            console.error("Error al guardar los datos del formulario:", error);
        }
    };

    return (
        <Modal show={isOpen} onHide={onClose} size="lg" dialogClassName="modal-dialog" style={{ marginTop: '50px' }}>
            <Modal.Header closeButton>
                <Modal.Title>Detalles del Riesgo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="mb-3 justify-content-center text-center">
                    <Col xs="auto" className="mx-2">
                        <Form.Label><strong>Período:</strong> {codigoPeriodo}</Form.Label>
                    </Col>
                    <Col xs="auto" className="mx-2">
                        <Form.Label><strong>Tipo Objetivo:</strong> {descripcionTipObj}</Form.Label>
                    </Col>
                    <Col xs="auto" className="mx-2">
                        <Form.Label><strong>Objetivo:</strong> {descripcionObjetivo}</Form.Label>
                    </Col>
                    <Col xs="auto" className="mx-2">
                        <Form.Label><strong>Área:</strong> {descripcionArea}</Form.Label>
                    </Col>
                   
                </Row>

                <Tabs defaultActiveKey="informacion-general" id="detalle-riesgo-tabs">
                    <Tab eventKey="informacion-general" title="Información General">
                        <Form.Group controlId="CODIGO_RIESGO">
                            <Form.Label>Código Riesgo</Form.Label>
                            <Form.Control type="text" value={codigoTipoObjetivo + "-" + formData.CODIGO_RIESGO} readOnly />
                        </Form.Group>
                        <Form.Group controlId="DESCRIPCION">
                            <Form.Label>Nombre Riesgo</Form.Label>
                            <Form.Control maxLength={300} type="text" name="DESCRIPCION" value={formData.DESCRIPCION} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group controlId="OBSERVACIONES">
                            <Form.Label>Observaciones Generales</Form.Label>
                            <Form.Control maxLength={100} as="textarea" rows={3} name="OBSERVACIONES" value={formData.OBSERVACIONES} onChange={handleInputChange} />
                        </Form.Group>
                    </Tab>
                    <Tab eventKey="apetito-al-riesgo" title="Apetito al Riesgo">
                        <Form.Group controlId="CODIGO_PROBABILIDAD">
                            <Form.Label>Código Probabilidad</Form.Label>
                            <Form.Select name="CODIGO_PROBABILIDAD" value={formData.CODIGO_PROBABILIDAD} onChange={handleInputChange}>
                                {listas.probabilidad.length > 0 ? listas.probabilidad.map((value) => (
                                    <option value={value.CODIGO} key={value.CODIGO}>
                                        {value.CODIGO} - {value.DESCRIPCION}
                                    </option>
                                )) : <option value=''>Cargando...</option>}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group controlId="CODIGO_SEVERIDAD">
                            <Form.Label>Código Severidad</Form.Label>
                            <Form.Select name="CODIGO_SEVERIDAD" value={formData.CODIGO_SEVERIDAD} onChange={handleInputChange}>
                                {listas.severidad.length > 0 ? listas.severidad.map((value) => (
                                    <option value={value.CODIGO} key={value.CODIGO}>
                                        {value.CODIGO} - {value.DESCRIPCION}
                                    </option>
                                )) : <option value=''>Cargando...</option>}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group controlId="CODIGO_TOLERANCIA">
                            <Form.Label>Código Tolerancia</Form.Label>
                            <Form.Select name="CODIGO_TOLERANCIA" value={formData.CODIGO_TOLERANCIA} onChange={handleInputChange}>
                                {listas.tolerancia.length > 0 ? listas.tolerancia.map((value) => (
                                    <option value={value.CODIGO} key={value.CODIGO}>
                                        {value.CODIGO} - {value.DESCRIPCION}
                                    </option>
                                )) : <option value=''>Cargando...</option>}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group controlId="CODIGO_MITIGACION">
                            <Form.Label>Código Mitigación</Form.Label>
                            <Form.Select name="CODIGO_MITIGACION" value={formData.CODIGO_MITIGACION} onChange={handleInputChange}>
                                {listas.mitigacion.length > 0 ? listas.mitigacion.map((value) => (
                                    <option value={value.CODIGO} key={value.CODIGO}>
                                        {value.CODIGO} - {value.DESCRIPCION}
                                    </option>
                                )) : <option value=''>Cargando...</option>}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group controlId="NIVEL_CAPACIDAD_MITIGACION">
                            <Form.Label>Nivel Capacidad Mitigación</Form.Label>
                            <Form.Select name="NIVEL_CAPACIDAD_MITIGACION" value={formData.NIVEL_CAPACIDAD_MITIGACION} onChange={handleInputChange}>
                                {listas.mitigacion.length > 0 ? listas.mitigacion.map((value) => (
                                    <option value={value.VALOR} key={value.VALOR}>
                                        {value.VALOR} - {value.CODIGO2}
                                    </option>
                                )) : <option value=''>Cargando...</option>}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group controlId="CODIGO_FRECUENCIA">
                            <Form.Label>Código Frecuencia</Form.Label>
                            <FrecuenciaComboBox 
                                value={formData.CODIGO_FRECUENCIA} 
                                onChange={(value) => setFormData({ ...formData, CODIGO_FRECUENCIA: value })} />
                        </Form.Group>
                        <Form.Group controlId="VARIABLE_MITIGACION">
                            <Form.Label>Variable a Mitigar</Form.Label>
                            <ComboBoxVariableMitigacion 
                                value={formData.VARIABLE_MITIGACION} 
                                onChange={(value) => setFormData({ ...formData, VARIABLE_MITIGACION: value })} />
                        </Form.Group>
                        <Form.Group controlId="EVENTOS">
                            <Form.Label>Eventos</Form.Label>
                            <Form.Control as="textarea" rows={3} name="EVENTOS" value={formData.EVENTOS} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group controlId="DESC_MITIGACION">
                            <Form.Label>Descripción Mitigación</Form.Label>
                            <Form.Control as="textarea" rows={3} name="DESC_MITIGACION" value={formData.DESC_MITIGACION} onChange={handleInputChange} />
                        </Form.Group>
                    </Tab>
                    {/*
                    <Tab eventKey="monitoreo" title="Monitoreo">
                        <Form.Group controlId="CODIGO_ENCARGADO">
                            <Form.Label>Responsable</Form.Label>
                            <PersonaResponsableComboBox 
                                value={formData.CODIGO_ENCARGADO} 
                                onChange={(value) => setFormData({ ...formData, CODIGO_ENCARGADO: value })} />
                        </Form.Group>
                        <Form.Group controlId="RESPONSABLE_DELEGADO">
                            <Form.Label>Delegado</Form.Label>
                            <PersonaResponsableComboBox 
                                value={formData.RESPONSABLE_DELEGADO} 
                                onChange={(value) => setFormData({ ...formData, RESPONSABLE_DELEGADO: value })} />
                        </Form.Group>
                        <Form.Group controlId="CODIGO_SUPERVISOR">
                            <Form.Label>Monitor</Form.Label>
                            <PersonaResponsableComboBox 
                                value={formData.CODIGO_SUPERVISOR} 
                                onChange={(value) => setFormData({ ...formData, CODIGO_SUPERVISOR: value })} />
                        </Form.Group>
                        <Form.Group controlId="ESTADO_SUPERVISION">
                            <Form.Label>Estado Monitoreo</Form.Label>
                            <Form.Select name="ESTADO_SUPERVISION" value={formData.ESTADO_SUPERVISION} onChange={handleInputChange}>
                                <option value="en_supervision">En Monitoreo</option>
                                <option value="supervisado">Finalizado</option>
                                <option value="sin_revisar">Bajo analisis</option>
                                <option value="suspendido">Suspendido</option>
                            </Form.Select>
                        </Form.Group>
                        <Row>
                            <Col>
                                <Form.Group controlId="FECHA_INI_MONITOREO">
                                    <Form.Label>Fecha Inicio Monitoreo</Form.Label>
                                    <DatePicker
                                        selected={formData.FECHA_INI_MONITOREO ? new Date(formData.FECHA_INI_MONITOREO) : null}
                                        onChange={(date) => setFormData({ ...formData, FECHA_INI_MONITOREO: date.toISOString().split('T')[0] })}
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control"
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="FECHA_FIN_MONITOREO">
                                    <Form.Label>Fecha Fin Monitoreo</Form.Label>
                                    <DatePicker
                                        selected={formData.FECHA_FIN_MONITOREO ? new Date(formData.FECHA_FIN_MONITOREO) : null}
                                        onChange={(date) => setFormData({ ...formData, FECHA_FIN_MONITOREO: date.toISOString().split('T')[0] })}
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control"
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="FECHA_ULTIMA_SUPERVISION">
                                    <Form.Label>Fecha último monitoreo</Form.Label>
                                    <Form.Control type="text" value={formData.FECHA_ULTIMA_SUPERVISION} readOnly />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group controlId="COMENTARIOS_SUPERVISOR">
                            <Form.Label>Comentarios del monitor</Form.Label>
                            <Form.Control as="textarea" rows={3} value={formData.COMENTARIOS_SUPERVISOR} onChange={handleInputChange} name="COMENTARIOS_SUPERVISOR" />
                        </Form.Group>

                        <br/>
                        <RiesgosMonitoreo codigoCia={riesgo.CODIGO_CIA} codigoRiesgo={formData.CODIGO_RIESGO} />
      
                    </Tab>
                    <Tab eventKey="informe" title="Informe">
                        <Form.Group controlId="NOMBRE_DOCUMENTO">
                            <Form.Label>Nombre Informe</Form.Label>
                            <Form.Control maxLength={255} type="text" name="NOMBRE_DOCUMENTO" value={formData.NOMBRE_DOCUMENTO} onChange={handleInputChange} />
                        </Form.Group> 

                        {   

                            (String(formData.NOMBRE_ARCHIVO).trim() === '') ?
                                <UploadFile inputId={'input' + formData.CODIGO_RIESGO} codigo={formData.CODIGO_RIESGO}
                                    URL={'/upload-informe-file'} refresh={() => { }} maxSize={50000000}
                                />
                                :
                                <DownloadAndUpdate inputId={'input' + formData.CODIGO_RIESGO} codigo={formData.CODIGO_RIESGO}
                                    both={true} original={formData.NOMBRE_ARCHIVO} path={formData.NOMBRE_ARCHIVO} downloadURL={'/download-activity-file'}
                                    mimeType={'application/pdf'} URL={'/update-informe-file'} refresh={() => { }} maxSize={50000000}
                                />
                        }

                        <Form.Group controlId="OBSERVACIONES_INFORME">
                            <Form.Label>Observaciones Informe</Form.Label>
                            <Form.Control as="textarea" rows={3} name="OBSERVACIONES_INFORME" value={formData.OBSERVACIONES_INFORME} onChange={handleInputChange} />
                        </Form.Group>
                        <br />
                        <RiesgosInforme codigoCia={riesgo.CODIGO_CIA} codigoRiesgo={formData.CODIGO_RIESGO} />
                    </Tab>
                    */}
                    <Tab eventKey="detalles" title="Detalles">
                        <Row>
                            <Col>
                                <Form.Group controlId="DESC_CONTINT_MITIGACION">
                                    <Form.Label>Descripción Continuidad Mitigación</Form.Label>
                                    <Form.Control as="textarea" rows={3} name="DESC_CONTINT_MITIGACION" value={formData.DESC_CONTINT_MITIGACION} onChange={handleInputChange} />
                                </Form.Group>
                                <Form.Group controlId="DESC_FRECUENCIA_MONITOREO">
                                    <Form.Label>Descripción Frecuencia Monitoreo</Form.Label>
                                    <Form.Control as="textarea" rows={3} name="DESC_FRECUENCIA_MONITOREO" value={formData.DESC_FRECUENCIA_MONITOREO} onChange={handleInputChange} />
                                </Form.Group>
                                <Form.Group controlId="DESC_METODO_MONITOREO">
                                    <Form.Label>Descripción Método Monitoreo</Form.Label>
                                    <Form.Control as="textarea" rows={3} name="DESC_METODO_MONITOREO" value={formData.DESC_METODO_MONITOREO} onChange={handleInputChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="eventos" title="Eventos">
                        <Eventos riesgo={riesgo} />
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditarRiesgoModal;
