import React, { useState, useEffect } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import Axios from 'axios';

function SearchObjetivoArea(props) {
    const [listaPeriodo, setListaPeriodo] = useState([]);
    const [listaTipObj, setListaTipObj] = useState([]);
    const [listaPrin, setListaPrin] = useState([]);
    const [listaAreas, setListaAreas] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Obtiene los períodos de la empresa
        Axios.get("/api/get/periodos", { headers: { "x-access-token": localStorage.getItem('token') } })
            .then((response) => {
                setListaPeriodo(response.data);
            });

        // Obtiene los tipos de objetivos de la empresa
        Axios.get("/api/get/tiposObjetivos", { headers: { "x-access-token": localStorage.getItem('token') } })
            .then((response) => {
                setListaTipObj(response.data);
            });
    }, []);

    useEffect(() => {
        if (props.tipObj && props.periodo) {
            console.log("tipo: " + props.tipObj + " periodo: " + props.periodo);
            Axios.get("/api/get/periodo-objetivo" + props.tipObj + "/" + props.periodo, { headers: { "x-access-token": localStorage.getItem('token') } })
                .then((response) => {
                    console.log("response " + response.data);
                    if (Array.isArray(response.data)) {
                        setListaPrin(response.data);
                    } else {
                        setListaPrin([]);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data", error);
                    setListaPrin([]);
                });
        }
    }, [props.tipObj, props.periodo]);

    const fetchAreas = async (selectedObjective) => {
        if (props.tipObj && props.periodo && selectedObjective) {
            console.log("Fetching areas for tipo: " + props.tipObj + " periodo: " + props.periodo + " objetivo: " + selectedObjective);
            try {
                const response = await Axios.get("/api/get/objetivo-area-tabla" + props.tipObj + "/" + props.periodo + "/" + selectedObjective, { headers: { "x-access-token": localStorage.getItem('token') } });
                console.log("response " + response.data);
                if (Array.isArray(response.data)) {
                    setListaAreas(response.data);
                    console.log("Asignando áreas");
                } else {
                    setListaAreas([]);
                }
            } catch (error) {
                console.error("Error fetching data", error);
                setListaAreas([]);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <>
            <Row className="mb-3">
                <Form.Group as={Col} md="2">
                    <Form.Label><b></b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label><b>Período de evaluación</b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Select onChange={(e) => {
                        if (e.target.value !== '') {
                            const selectedOption = e.target.selectedOptions[0];
                            props.setPeriodo(e.target.value, selectedOption.getAttribute('data-description'));
                        }
                    }}>
                        <option value=''></option>
                        {listaPeriodo.map((value) => (
                            <option value={value.CODIGO_PERIODO} key={value.CODIGO_PERIODO} data-description={`Del ${value.FECINI} al ${value.FECFIN}`}>
                                Del {value.FECINI} al {value.FECFIN} de {value.CODIGO_PERIODO}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="2">
                    <Form.Label><b></b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label><b>Tipo de Objetivo</b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Select onChange={(e) => {
                        if (e.target.value !== '') {
                            const selectedOption = e.target.selectedOptions[0];
                            props.setTipObj(e.target.value, selectedOption.getAttribute('data-description'));
                        }
                    }}>
                        <option value=''></option>
                        {listaTipObj.map((value) => (
                            <option value={value.CODIGO} key={value.CODIGO} data-description={value.DESCRIPCION}>
                                {value.DESCRIPCION}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="2">
                    <Form.Label><b></b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label><b>Objetivo</b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Select onChange={(e) => {
                        if (e.target.value !== '') {
                            const selectedOption = e.target.selectedOptions[0];
                            props.setSelectedObjective(e.target.value, selectedOption.getAttribute('data-description'));
                            fetchAreas(e.target.value); // Fetch areas when an objective is selected
                        }
                    }}>
                        <option value=''></option>
                        {listaPrin.map((item) => (
                            <option value={item.CODIGO_OBJETIVO} key={item.CODIGO_OBJETIVO} data-description={item.DESCRIPCION}>
                                {item.DESCRIPCION}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="2">
                    <Form.Label><b></b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="2">
                    <Form.Label><b>Área</b></Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                    <Form.Select onChange={(e) => {
                        if (e.target.value !== '') {
                            const selectedOption = e.target.selectedOptions[0];
                            props.setSelectedArea(e.target.value, selectedOption.getAttribute('data-description'));
                        }
                    }}>
                        <option value=''></option>
                        {listaAreas.map((item) => (
                            <option value={item.CODIGO_AREA} key={item.CODIGO_AREA} data-description={item.DESCRIPCION}>
                                {item.DESCRIPCION}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Row>
        </>
    );
}

export default SearchObjetivoArea;
