import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, InputGroup, Modal, Col, Row, Dropdown, DropdownButton } from 'react-bootstrap';
import { AiOutlineSearch, AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import * as XLSX from 'xlsx';
import { VscFilePdf } from "react-icons/vsc";
import { toast } from 'react-toastify';
import EditarRiesgoModal from './EditarRiesgoModal';
import EliminarRiesgoModal from './EliminarRiesgoModal';
import HeatMapChart from './HeatMapChart';
import zIndex from '@mui/material/styles/zIndex';

const TablaDatosTipoObjetivo = ({ TipoObjetivo, CodigoPeriodo, CodigoObjetivo, CodigoArea, reloadTrigger, descripcionTipObj, descripcionArea, descripcionObjetivo }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [listaPrin, setListaPrin] = useState([]);
    const [selectedRiesgo, setSelectedRiesgo] = useState(null);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isHeatMapModalOpen, setHeatMapModalOpen] = useState(false);
    const [heatMapType, setHeatMapType] = useState('inherente');
    const [isIndividualHeatMapModalOpen, setIndividualHeatMapModalOpen] = useState(false);

    const [visibleColumns, setVisibleColumns] = useState({
        codigoRiesgo: true,
        descripcion: true,
/*        probabilidad: true,
        severidad: true,
        riesgoInherente: true,
        probabilidadInherente: true,
        severidadInherente: true,
        riesgoResidual: true,
        valorMitigacion: true,*/
        responsable: true,
        delegado: true,
    });
    const [showColumnModal, setShowColumnModal] = useState(false);

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Datos');
        XLSX.writeFile(workbook, 'datos_objetivos.xlsx');
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const url = `/api/get/riesgo-monitor/${CodigoPeriodo}`;
            const response = await axios.get(url, {
                headers: { "x-access-token": localStorage.getItem('token') }
            });
            console.log('Fetched Data:', response.data); // Debugging point
            const fetchedData = response.data;

            // Reordenar los datos para resaltar las filas que cumplen con alguna de las condiciones
            const reorderedData = fetchedData.sort((a, b) => {
                const aHighlight = a.ES_CODIGO_ENCARGADO_1 === 'TRUE' || a.ES_CODIGO_SUPERVISOR_1 === 'TRUE' || a.ES_CODIGO_DELEGADO_1 === 'TRUE';
                const bHighlight = b.ES_CODIGO_ENCARGADO_1 === 'TRUE' || b.ES_CODIGO_SUPERVISOR_1 === 'TRUE' || b.ES_CODIGO_DELEGADO_1 === 'TRUE';
                return bHighlight - aHighlight;
            });

            setData(reorderedData);
            setListaPrin(reorderedData);
        } catch (error) {
            console.error("Error fetching data", error);
            setData([]);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        if (CodigoPeriodo) {
            fetchData();
        }
    }, [CodigoPeriodo]);

    if (loading) {
        //return <p>Seleccione un período para continuar.</p>;
    }

    if (!Array.isArray(data)) {
        return <p>Error: Data is not an array.</p>;
    }

    const busquedaObjetivo = (listaInfo) => {
        const input1 = document.getElementById('input-codigoRiesgo')?.value || '';
/*        const input2 = document.getElementById('input-probabilidad')?.value || '';
        const input3 = document.getElementById('input-severidad')?.value || '';
        const input4 = document.getElementById('input-riesgoInherente')?.value || '';
        const input5 = document.getElementById('input-riesgoResidual')?.value || '';
        const input6 = document.getElementById('input-valorMitigacion')?.value || '';*/
        const input7 = document.getElementById('input-responsable')?.value || '';
        const input8 = document.getElementById('input-descripcion')?.value || '';
        const input9 = document.getElementById('input-delegado')?.value || '';
/*        const input9 = document.getElementById('input-probabilidadInherente')?.value || '';
        const input10 = document.getElementById('input-severidadInherente')?.value || '';*/
        let tmpArray = [];
        if (String(input1) === '' && /*String(input2) === '' && String(input3) === '' && String(input4) === '' && String(input5) === '' && String(input6) === '' &&*/ String(input7) === '' && String(input8) === '' && String(input9) === ''/* && String(input10) === ''*/) {
            return listaInfo;
        } else {
            const indexes = listaInfo.map(
                (element, index) => {
                    let flag = false;

                    if (String(input1) !== '') {
                        if (element.CODIGO_RIESGO.toLowerCase().includes(input1.toLowerCase())) {
                            flag = true;
                        } else {
                            return -1;
                        }
                    }
/*
                    if (String(input2) !== '') {
                        if (element.DESCRIPCION_PROBABILIDAD.toLowerCase().includes(input2.toLowerCase())) {
                            flag = true;
                        } else {
                            return -1;
                        }
                    }

                    if (String(input3) !== '') {
                        if (element.DESCRIPCION_SEVERIDAD.toLowerCase().includes(input3.toLowerCase())) {
                            flag = true;
                        } else {
                            return -1;
                        }
                    }

                    if (String(input4) !== '') {
                        if (element.RIESGO_INHERENTE.toString().includes(input4)) {
                            flag = true;
                        } else {
                            return -1;
                        }
                    }

                    if (String(input5) !== '') {
                        if (element.RIESGO_RESIDUAL.toString().includes(input5)) {
                            flag = true;
                        } else {
                            return -1;
                        }
                    }

                    if (String(input6) !== '') {
                        if (element.VALORMITIGACION.toString().includes(input6)) {
                            flag = true;
                        } else {
                            return -1;
                        }
                    }
*/
                    if (String(input7) !== '') {
                        if (element.NOMBRE.toLowerCase().includes(input7.toLowerCase())) {
                            flag = true;
                        } else {
                            return -1;
                        }
                    }

                    if (String(input8) !== '') {
                        if (element.DESCRIPCION_RIESGO.toLowerCase().includes(input8.toLowerCase())) {
                            flag = true;
                        } else {
                            return -1;
                        }
                    }

                    if (String(input9) !== '') {
                        if (element.DELEGADO.toLowerCase().includes(input9.toLowerCase())) {
                            flag = true;
                        } else {
                            return -1;
                        }
                    }
/*
                    if (String(input9) !== '') {
                        if (element.PROBABILIDAD_INHERENTE.toString().includes(input9)) {
                            flag = true;
                        } else {
                            return -1;
                        }
                    }

                    if (String(input10) !== '') {
                        if (element.SEVERIDAD_INHERENTE.toString().includes(input10)) {
                            flag = true;
                        } else {
                            return -1;
                        }
                    }
*/
                    if (flag) {
                        return index;
                    }

                }
            ).filter(value => value >= 0);
            for (let i of indexes) {
                tmpArray.push(listaInfo[i]);
            }
            return tmpArray;
        }
    };

    const desasignar = async (CODIGO) => {
        var CodigoArea = CODIGO;
        try {
            await axios.post('/api/delete/objetivo-area', { CodigoPeriodo, TipoObjetivo, CodigoObjetivo, CodigoArea }, {
                headers: { "x-access-token": localStorage.getItem('token') }
            });
            await fetchData();
        } catch (error) {
            console.error("Error desasignando objetivo", error);
        }
    };

    const generarReporte = (tipoReporte, codigoPeriodo, imagenMapa) => {
        let mensaje = ''

        if (tipoReporte === 'mer') {
            mensaje = 'Matriz de Evaluación de Riesgos'
        } else if (tipoReporte === 'mr') {
            mensaje = 'Mapa de Riesgos'
        } else if (tipoReporte === 'rgo') {
            mensaje = 'Informe de riesgo'
        } else {
            mensaje = 'Matriz de Continuidad de Riesgos'
        }

        toast.info(`Generando reporte`);

        axios.get('/api/get/generar-reporte',

            {
                params: { tipo: tipoReporte, codigoPeriodo: codigoPeriodo, codigoArea: CodigoArea, imagenMapa: null },

                headers: { "x-access-token": localStorage.getItem('token'), },
                responseType: 'blob',
            },).then(
                (response) => {
                    if (response.headers['content-length'] === '0') {
                        toast.error('Ha ocurrido un error al generar su reporte, se ha enviado un correo a soporte. Tendrá pronta respuesta, gracias.')
                    } else {
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' }
                        );
                        const fileURL = URL.createObjectURL(file);
                        const link = document.createElement('a');
                        link.href = fileURL;
                        link.setAttribute('download', `${mensaje}.pdf`,);
                        document.body.appendChild(link);
                        link.click();
                        window.open(fileURL);
                        link.parentNode.removeChild(link);
                    }
                });
    }

    const handleEditClick = (riesgo) => {
        console.log('riesgo: ', riesgo)
        setSelectedRiesgo(riesgo);
        setEditModalOpen(true);
    }

    const handleDeleteClick = (riesgo) => {
        setSelectedRiesgo(riesgo);
        setDeleteModalOpen(true);
    }

    const handleIndividualHeatMapClick = (riesgo) => {
        setSelectedRiesgo(riesgo);
        setIndividualHeatMapModalOpen(true);
    }

    const getColorForRisk = (risk) => {
        if (risk <= 10) {
            return '#3FA535'; // Verde
        } else if (risk <= 15) {
            return '#FFED00'; // Amarillo
        } else {
            return '#CD1719'; // Rojo
        }
    }



    const riesgoCeldaStyle = {
        margin: '0 20px',
        padding: '5px',
        borderRadius: '5px',
        textAlign: 'center' // Centro los elementos dentro de las celdas de riesgo
    };

    const toggleColumnVisibility = (column) => {
        setVisibleColumns((prevState) => ({
            ...prevState,
            [column]: !prevState[column]
        }));
    };

    const handleShowColumnModal = () => setShowColumnModal(true);
    const handleCloseColumnModal = () => setShowColumnModal(false);

    const handleHeatMapModalOpen = (type) => {
        setHeatMapType(type);
        setHeatMapModalOpen(true);
    };

    const handleIndividualHeatMapModalOpen = (type) => {
        setHeatMapType(type);
        setIndividualHeatMapModalOpen(true);
    };

    const columnNames = {
        codigoRiesgo: 'Código Riesgo',
        descripcion: 'Descripción',
/*        probabilidad: 'Probabilidad',
        severidad: 'Severidad',
        riesgoInherente: 'Riesgo Inherente',
        probabilidadInherente: 'Probabilidad Inherente',
        severidadInherente: 'Severidad Inherente',
        riesgoResidual: 'Riesgo Residual',
        valorMitigacion: 'Valor Mitigación',*/
        responsable: 'Responsable',
        delegado: 'Delegado'
    };

    return (
        <>
            <style>
                {`
                    .hidden {
                        display: none;
                    }
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    th, td {
                        text-align: center; /* Centro los textos en las celdas de la tabla */
                        border: 2px solid black; /* Engrosar la línea de la tabla */
                    }
                    th {
                        background-color: #f2f2f2;
                    }
                    .search-input {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                `}
            </style>
            <div style={{ overflowX: 'auto' }}>
                <table className="table">
                    <thead>
                        <tr>
                            <th>
                                Opción
                            </th>
                            {Object.keys(visibleColumns).map((column) => (
                                <th key={column} className={!visibleColumns[column] ? 'hidden' : ''}>
                                    {visibleColumns[column] ? (
                                        <>
                                            {columnNames[column]}
                                            <Button variant="link" size="sm" onClick={() => toggleColumnVisibility(column)}>-</Button>
                                        </>
                                    ) : (
                                        <Button variant="link" size="sm" onClick={() => toggleColumnVisibility(column)}>+</Button>
                                    )}
                                </th>
                            ))}
                        </tr>
                        <tr>
                            <td></td>
                            {Object.keys(visibleColumns).map((column) => (
                                visibleColumns[column] && (
                                    <td key={column}>
                                        <InputGroup className="search-input">
                                            <Form.Control
                                                id={`input-${column}`}
                                                type="text"
                                                placeholder={`búsqueda por ${columnNames[column]}`}
                                                onChange={() => setData(busquedaObjetivo(listaPrin))}
                                            />
                                            <Button
                                                variant="outline-secondary"
                                                onClick={() => setData(busquedaObjetivo(listaPrin))}
                                            >
                                                <AiOutlineSearch />
                                            </Button>
                                        </InputGroup>
                                    </td>
                                )
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item) => (
                            <tr key={item.CODIGO_RIESGO} style={item.ES_CODIGO_ENCARGADO_1 === 'TRUE' || item.ES_CODIGO_SUPERVISOR_1 === 'TRUE' || item.ES_CODIGO_DELEGADO_1 === 'TRUE' ? { backgroundColor: '#F4F1BB' } : {}}>
                                <td>
                                    <div style={{ display: 'flex', gap: '8px' }}>
                                        <Button size='sm' variant="primary" onClick={() => handleEditClick(item)}>
                                            <AiOutlineEdit /> Agregar informe
                                        </Button>
                                        <Button size='sm' variant="secondary" onClick={() => generarReporte('rgo', item.CODIGO_RIESGO, '')}>
                                            <AiOutlineEdit /> Reporte riesgo
                                        </Button>
                                    </div>
                                </td>
                                {visibleColumns.codigoRiesgo && <td>{item.ABREVIATURAAREA} {item.CODIGO_TIPO_OBJETIVO}-{item.CODIGO_RIESGO}</td>}
                                {visibleColumns.descripcion && <td>{item.DESCRIPCION_RIESGO}</td>}
                                {/*visibleColumns.probabilidad && <td>{item.DESCRIPCION_PROBABILIDAD}</td>*/}
                                {/*visibleColumns.severidad && <td>{item.DESCRIPCION_SEVERIDAD}</td>*/}
                                {/*visibleColumns.riesgoInherente && <td style={{ backgroundColor: getColorForRisk(item.RIESGO_INHERENTE), ...riesgoCeldaStyle }}>{item.RIESGO_INHERENTE}</td>*/}
                                {/*visibleColumns.probabilidadInherente && <td>{item.PROBABILIDAD_INHERENTE}</td>*/}
                                {/*visibleColumns.severidadInherente && <td>{item.SEVERIDAD_INHERENTE}</td>*/}
                                {/*visibleColumns.riesgoResidual && <td style={{ backgroundColor: getColorForRisk(item.RIESGO_RESIDUAL), ...riesgoCeldaStyle }}>{item.RIESGO_RESIDUAL}</td>*/}
                                {/*visibleColumns.valorMitigacion && <td>{item.VALORMITIGACION}</td>*/}
                                {visibleColumns.responsable && <td>{item.NOMBRE}</td>}
                                {visibleColumns.delegado && <td>{item.DELEGADO}</td>}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Modal show={showColumnModal} onHide={handleCloseColumnModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Configurar Columnas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Object.keys(visibleColumns).map((column) => (
                        <Form.Check
                            type="checkbox"
                            label={columnNames[column]}
                            checked={visibleColumns[column]}
                            onChange={() => toggleColumnVisibility(column)}
                            key={column}
                        />
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseColumnModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            {isEditModalOpen && selectedRiesgo && (
                <EditarRiesgoModal
                    isOpen={isEditModalOpen}
                    onClose={() => {
                        setEditModalOpen(false);
                        fetchData();
                    }}
                    riesgo={selectedRiesgo}
                    onUpdateSuccess={() => {
                        setLoading(true);
                        console.log('selected riesgo: ', selectedRiesgo);

                    }}
                    codigoCia={1}
                    codigoPeriodo={CodigoPeriodo}
                    codigoTipoObjetivo={selectedRiesgo.CODIGO_TIPO_OBJETIVO}
                    codigoObjetivo={selectedRiesgo.CODIGO_OBJETIVO}
                    codigoArea={selectedRiesgo.CODIGO_AREA}
                    descripcionTipObj={selectedRiesgo.DESCRIPCION}
                    descripcionArea={selectedRiesgo.DESCRIPCION_AREA}
                    descripcionObjetivo={selectedRiesgo.DESCRIPCION_OBJETIVO}
                />
            )}
            {isDeleteModalOpen && selectedRiesgo && (
                <EliminarRiesgoModal
                    isOpen={isDeleteModalOpen}
                    onClose={() => {
                        setDeleteModalOpen(false);
                        fetchData();
                    }}
                    riesgo={selectedRiesgo}
                    onDeleteSuccess={() => {
                        setLoading(true);
                        axios.get(`/api/getRiesgos_riesgo/1/${CodigoPeriodo}/${TipoObjetivo}/${CodigoArea}/${CodigoObjetivo}`, {
                            headers: { "x-access-token": localStorage.getItem('token') }
                        }).then(response => {
                            setData(response.data);
                            setListaPrin(response.data);
                            setLoading(false);
                        }).catch(error => {
                            console.error("Error fetching data", error);
                            setLoading(false);
                        });
                    }}
                />
            )}
            {isHeatMapModalOpen && (
                <Modal
                    show={isHeatMapModalOpen}
                    onHide={() => setHeatMapModalOpen(false)}
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{heatMapType === 'inherente' ? 'Mapa de Calor de Riesgo Inherente' : 'Mapa de Calor de Riesgo Residual'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <HeatMapChart
                            data={data.map(item => ({
                                CODIGO_RIESGO: item.CODIGO_RIESGO,
                                VALOR_PROBABILIDAD: item.VALOR_PROBABILIDAD,
                                VALOR_SEVERIDAD: item.VALOR_SEVERIDAD,
                                RIESGO_INHERENTE: item.RIESGO_INHERENTE,
                                RIESGO_RESIDUAL: item.RIESGO_RESIDUAL,
                                PROBABILIDAD_INHERENTE: item.PROBABILIDAD_INHERENTE,
                                SEVERIDAD_INHERENTE: item.SEVERIDAD_INHERENTE,
                                VALOR_MITIGACION: item.VALORMITIGACION,
                                RESPONSABLE: item.NOMBRE,
                                CODIGO_AREA: CodigoArea,
                                CODIGO_PERIODO: CodigoPeriodo // Asegúrate de pasar este dato aquí
                            }))}
                            title={heatMapType === 'inherente' ? 'Mapa de Calor de Riesgo Inherente' : 'Mapa de Calor de Riesgo Residual'}
                            tipoObjetivo={TipoObjetivo}
                            codigoPeriodo={CodigoPeriodo}
                            riesgoTipo={heatMapType} // Asegúrate de pasar el tipo de riesgo aquí
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setHeatMapModalOpen(false)}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
            )}
            {isIndividualHeatMapModalOpen && selectedRiesgo && (
                <Modal
                    show={isIndividualHeatMapModalOpen}
                    onHide={() => setIndividualHeatMapModalOpen(false)}
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Mapa de Calor Individual</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <HeatMapChart
                            data={[{
                                CODIGO_RIESGO: selectedRiesgo.CODIGO_RIESGO,
                                VALOR_PROBABILIDAD: selectedRiesgo.VALOR_PROBABILIDAD,
                                VALOR_SEVERIDAD: selectedRiesgo.VALOR_SEVERIDAD,
                                RIESGO_INHERENTE: selectedRiesgo.RIESGO_INHERENTE,
                                RIESGO_RESIDUAL: selectedRiesgo.RIESGO_RESIDUAL,
                                PROBABILIDAD_INHERENTE: selectedRiesgo.PROBABILIDAD_INHERENTE,
                                SEVERIDAD_INHERENTE: selectedRiesgo.SEVERIDAD_INHERENTE,
                                VALOR_MITIGACION: selectedRiesgo.VALORMITIGACION,
                                RESPONSABLE: selectedRiesgo.NOMBRE,
                                CODIGO_PERIODO: CodigoPeriodo
                            }]}
                            title={`Mapa de Calor Individual - ${TipoObjetivo}-${selectedRiesgo.CODIGO_RIESGO}`}
                            tipoObjetivo={TipoObjetivo}
                            codigoPeriodo={CodigoPeriodo}
                            riesgoTipo="inherente"
                            isIndividual={true}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setIndividualHeatMapModalOpen(false)}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default TablaDatosTipoObjetivo;
