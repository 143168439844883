import React, { useState, useEffect } from "react";
import './rolUsuario.css'
import { NavBar } from '../NavBarAdmin/navBar';
import { AsignarRol } from "./asignarRol";
import { UpdateRol } from "./updateRol";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Table, Button, Form, InputGroup } from "react-bootstrap";
import { AiFillEdit, AiOutlinePlus, AiFillDelete, AiOutlineSearch } from 'react-icons/ai';

// HTTP Requests
import Axios from 'axios';

export const RolUsuario = () => {

    const [listaPrin, setListaPrin] = useState([]);
    const [listaSec, setListaSec] = useState([]);
    const [showNew, setShowNew] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [roles, setRoles] = useState([]);
    const [instituciones, setInstituciones] = useState([]);
    const [nombre, setNombre] = useState('');
    const [rol, setRol] = useState('');
    const [institucion, setInstitucion] = useState('');
    const [inicio, setInicio] = useState('');
    const [fin, setFin] = useState('');
    const [id, setID] = useState('');
 
    useEffect(()=>{
        //Lista de usuarios con sus roles
        Axios.get('/api/get/listaRolesUsarioRoles', {headers:{"x-access-token": localStorage.getItem('tokenAdminSys')}}).then((response)=>{
            if(response.data.auth === false){} else{
                setRoles(response.data.result);
            }
        });
        //Lista de usuarios con sus roles
        Axios.get('/api/get/institucionesRoles', {headers:{"x-access-token": localStorage.getItem('tokenAdminSys')}}).then((response)=>{
            if(response.data.auth === false){} else{
                setInstituciones(response.data.result);
            }
        });
        //Lista de usuarios con sus roles
        refresh()
    },[])

    const refresh = () =>{
        Axios.get('/api/get/rolesUsuarios', {headers:{"x-access-token": localStorage.getItem('tokenAdminSys')}}).then((response)=>{
            if(response.data.auth === false){} else{
                setListaPrin(response.data.result);
                setListaSec(response.data.result)
            }
        });
    }

    const getDateFormat = (tmpDate) => {
        let firstDate = new Date(tmpDate);
        let options = { day: 'numeric',  month: 'long', year: 'numeric', timeZone: "GMT"};
        return String(firstDate.toLocaleDateString('es-ES', options))
    }

    const deleteRol = (id) => {
        Axios.get('/delete/rolUsuario/'+String(id), {headers:{"x-access-token": localStorage.getItem('tokenAdminSys')}}).then((response)=>{
            if(response.data.auth === false){} else{
                if (response.data.err){
                    toast.error('Error al eliminar el rol de este usuario');
                } else {
                    setListaPrin(listaPrin.filter(value => value.CODIGO_ROL_USUARIO !== id))
                    setListaSec(listaSec.filter(value => value.CODIGO_ROL_USUARIO !== id))
                    toast.success('Se ha eliminado correctamente el rol de este usuario');
                }
            }
        });
    }

    const busquedaUsuario = (listaInfo) => {
        const input1 = document.getElementById('input-1').value;
        const input2 = document.getElementById('input-2').value;
        const input3 = document.getElementById('input-3').value;
        let tmpArray = []
        if (String(input1) === '' && String(input2) === '' && String(input3) === ''){
            return listaInfo
        } else {
            const indexes = listaInfo.map(
                    (element, index) => {
                            let flag = false;

                            if (String(input1) !== ''){
                                if(element.ENTIDAD_NOMBRE.toLowerCase().includes(input1.toLowerCase())){
                                    flag = true;
                                } else {
                                    return -1
                                }
                            }
                            const texto = element.NOMBRE_COMPLETO+' - '+element.USUARIO_WEB
                            if (String(input2) !== ''){
                                if(texto.toLowerCase().includes(input2.toLowerCase())){
                                    flag = true;
                                } else {
                                    return -1
                                }
                            }

                            if (String(input3) !== ''){
                                if(element.ROL_NOMBRE.toLowerCase().includes(input3.toLowerCase())){
                                    flag = true;
                                } else {
                                    return -1
                                }
                            }

                            if(flag){
                                return index
                            }
                            
                        }
                    ).filter(value => value >= 0)
            for (let i of indexes) {
                tmpArray.push(listaInfo[i])
            }
            return tmpArray
        }
    }

    return(
        <>
            <NavBar/>
            <div className="container-rol-usuario">
                <h3 className="blog-title">Menu - Rol Usuario</h3>
                <p className="lead blog-description">Asignación de roles a usuarios</p>
                <hr/>
                <Button 
                    className="rolUsuarioButton" 
                    variant='success' 
                    size='sm'
                    onClick={()=>{
                        setShowNew(true);
                    }}
                >
                    <AiOutlinePlus className="svgButton"/> Nuevo
                </Button>
                <p/>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Opción</th>
                            <th>Proyecto</th>
                            <th>Institución</th>
                            <th>Persona - Usuario</th>
                            <th>Rol</th>
                            <th>Vigencia Inicio - Vigencia Fin</th>
                        </tr>
                        <tr>
                        <td>Herramientas de busqueda</td>
                            <td>

                            </td>
                            <td>
                                <InputGroup className="search-input">
                                    <Button 
                                        variant="outline-secondary"
                                        onClick={()=>{
                                            setListaSec(busquedaUsuario(listaPrin));
                                        }}
                                    >
                                        <AiOutlineSearch/>
                                    </Button>
                                    <Form.Control
                                        onChange={()=>{
                                            setListaSec(busquedaUsuario(listaPrin));
                                        }}
                                        id="input-1"
                                        type="text"
                                        placeholder="busqueda por institución"
                                    />
                                </InputGroup>
                            </td>
                            <td>
                                <InputGroup className="search-input">
                                    <Button 
                                        variant="outline-secondary"
                                        onClick={()=>{
                                            setListaSec(busquedaUsuario(listaPrin));
                                        }}
                                    >
                                        <AiOutlineSearch/>
                                    </Button>
                                    <Form.Control
                                        onChange={()=>{
                                            setListaSec(busquedaUsuario(listaPrin));
                                        }}
                                        id="input-2"
                                        type="text"
                                        placeholder="busqueda por persona - usuario"
                                    />
                                </InputGroup>
                            </td>
                            <td>
                                <InputGroup className="search-input">
                                    <Button 
                                        variant="outline-secondary"
                                        onClick={()=>{
                                            setListaSec(busquedaUsuario(listaPrin));
                                        }}
                                    >
                                        <AiOutlineSearch/>
                                    </Button>
                                    <Form.Control
                                        onChange={()=>{
                                            setListaSec(busquedaUsuario(listaPrin));
                                        }}
                                        id="input-3"
                                        type="text"
                                        placeholder="busqueda por rol"
                                    />
                                </InputGroup>
                            </td>
                            <td>
                                <div className="emptySearch"><p></p></div>
                            </td>
                        </tr> 
                    </thead>
                    <tbody>
                        {
                            listaSec.map((value) => {
                                return(
                                    <tr key={value.CODIGO_ROL_USUARIO}>
                                        <td>
                                            <Button 
                                                className="rolUsuarioButton" 
                                                variant='warning' 
                                                size='sm'
                                                onClick={()=>{
                                                    setNombre(value.NOMBRE_COMPLETO);
                                                    setRol(value.CODIGO_ROL); 
                                                    setInstitucion(value.CODIGO_ENTIDAD);
                                                    var firstDate = new Date(value.FECHA_INI_VIGENCIA);
                                                    firstDate.setDate(firstDate.getDate());
                                                    var fd = firstDate.toISOString().substr(0,10); 
                                                    var secondDate = new Date(value.FECHA_FIN_VIGENCIA);
                                                    secondDate.setDate(secondDate.getDate());
                                                    var sd = secondDate.toISOString().substr(0,10); 
                                                    setInicio(fd);
                                                    setFin(sd);
                                                    setShowUpdate(true); 
                                                    setID(value.CODIGO_ROL_USUARIO);          
                                                }}
                                            >
                                                <AiFillEdit className="svgButton"/> Modificar
                                            </Button>
                                            <Button 
                                                className="rolUsuarioButton" 
                                                variant='danger' 
                                                size='sm'
                                                onClick={()=>{
                                                    deleteRol(value.CODIGO_ROL_USUARIO)
                                                }}
                                            >
                                                <AiFillDelete className="svgButton"/> Eliminar
                                            </Button>
                                        </td>
                                        <td>
                                            RIESGO
                                        </td>
                                        <td>
                                            {value.ENTIDAD_NOMBRE}
                                        </td>
                                        <td>
                                            {value.NOMBRE_COMPLETO} - {value.USUARIO_WEB}
                                        </td>
                                        <td>
                                            {value.ROL_NOMBRE}
                                        </td>
                                        <td>
                                            {getDateFormat(value.FECHA_INI_VIGENCIA)} - {getDateFormat(value.FECHA_FIN_VIGENCIA)}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
                <AsignarRol showNew={showNew} setShowNew={setShowNew} roles={roles} instituciones={instituciones} 
                    setListaPrin={setListaPrin} setListaSec={setListaSec} refresh={()=>{refresh();}}
                />
                <UpdateRol showNew={showUpdate} setShowNew={setShowUpdate} roles={roles} instituciones={instituciones}
                    nombre={nombre} institucion={institucion} rol={rol} inicio={inicio} fin={fin} setFin={setFin} setInicio={setInicio}
                    id={id} setListaPrin={setListaPrin} setListaSec={setListaSec}
                />
            </div>
        </>
    );

}