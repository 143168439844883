import React, { useState } from "react";
import './admin.css';
import { Tabs, Tab } from "react-bootstrap";
import { Stack, Typography } from "@mui/material";
//import AdminUsuarios from "./AdminUsuarios";
import RolesViews from "./RolesViews";
import UpdatedNavBar from "../NavBar/UpdatedNavBar";
import CambiarLogo from "./CambiarLogo";

function AdminView() {

    const [key, setKey] = useState('logo');
    const [refresh, setRefresh] = useState(0)

    return (
        <>
            <UpdatedNavBar />
            <div className="container-dependencias">
                <Stack spacing={1}>
                    {/**
                     * Titulos principales de la página
                     */}
                    <Typography variant="h2" color='black' >Parámetros de la empresa</Typography>
                    <Typography variant="h4" color='lightgray' >En este espacio podrá administrar los datos generales de su empresa</Typography>
                    <hr />
                    <CambiarLogo />
                </Stack>
            </div>
        </>
    )
}

export default AdminView