import React, { useState } from 'react'
import { Stack, Typography } from '@mui/material';
import { Modal, Table, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BsCheckCircle, BsXCircle } from 'react-icons/bs'
import { AiFillEdit } from 'react-icons/ai'
import EditarRol from './EditarRol.jsx';
import NuevosRoles from './NuevosRoles';

function EditarRoles(props) {

    const [nombre, setNombre] = useState('')
    const [editar, setEditar] = useState(false)
    const [crear, setCrear] = useState(false)
    const [rol, setRol] = useState('')
    const [roles, setRoles] = useState([])

    const abrirEditar = (nombre) => {
        setEditar(true)
        setCrear(false)
        setNombre(nombre)
    }

    const cerrarEditar = () => {
        setEditar(false)
        setCrear(false)
        setNombre('')
        setRol('')
    }

    const cerrarCrear = () => {
        setEditar(false)
        setCrear(false)
    }
    const abrirCrear = () => {
        var tmp = props.lista.map((rol) => { return rol.CODIGO_ROL })
        tmp = props.roles.map((el) => {
            if (tmp.includes(el.CODIGO_ROL)) {
                return -1
            } else {
                return el
            }
        }).filter(el => el.CODIGO_ROL)
        setRoles(tmp)
        setEditar(false)
        setCrear(true)
    }

    const getDateFormat = (tmpDate) => {
        let firstDate = new Date(tmpDate);
        let options = { day: 'numeric', month: 'long', year: 'numeric', timeZone: "GMT" };
        return String(firstDate.toLocaleDateString('es-ES', options))
    }

    return (
        <Modal size="xl" show={props.show} onHide={props.cerrarModal} centered>
            <Modal.Header>
                <Modal.Title>Edición de roles</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Stack spacing={1}>
                    <Typography variant='h6'>Lista de roles asignados al usuario</Typography>
                    <Button variant='success' size='small' style={{ width: '25%' }} onClick={abrirCrear} >Agregar nuevos roles a este usuario</Button>
                    {
                        (editar) ?
                            <EditarRol refresh={props.refresh} cerrarEditar={cerrarEditar} nombre={nombre} rol={rol} /> :
                            <></>
                    }
                    {
                        (crear) ?
                            <NuevosRoles roles={roles} refresh={props.refresh} cerrarEditar={cerrarCrear} nombre={nombre} id={props.id} cerrarCrear={cerrarCrear} /> :
                            <></>
                    }
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>
                                    Accion
                                </th>
                                <th>
                                    Rol
                                </th>
                                <th>
                                    Inicio de vigencia
                                </th>
                                <th>
                                    Fin de vigencia
                                </th>
                                <th>
                                    Activo
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.lista.map((rol, index) => {
                                    return (
                                        <tr key={rol.NOMBRE + index}>
                                            <td>
                                                <OverlayTrigger placement="top"
                                                    overlay={<Tooltip id="button-tooltip-1">Editar rol</Tooltip>} >
                                                    <Button variant='info' onClick={() => { abrirEditar(rol.NOMBRE); setRol(rol) }} >
                                                        <AiFillEdit />
                                                    </Button>
                                                </OverlayTrigger>
                                            </td>
                                            <td>
                                                {rol.NOMBRE}
                                            </td>
                                            <td>
                                                {getDateFormat(rol.FECHA_INI_VIGENCIA)}
                                            </td>
                                            <td>
                                                {getDateFormat(rol.FECHA_FIN_VIGENCIA)}
                                            </td>
                                            <td>
                                                {
                                                    (rol.ACTIVO === 1) ?
                                                        <p className="text-success"><BsCheckCircle /> Activo</p> :
                                                        <p className="text-danger"><BsXCircle /> No activo</p>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </Stack>
            </Modal.Body>
        </Modal>
    )
}

export default EditarRoles