import React, { useState } from 'react';
import UpdatedNavBar from '../NavBar/UpdatedNavBar';
import { Stack, Button } from '@mui/material';
import TablaDatosTipoObjetivo from './Componentes/TablaDatosTipoobjetivo';
import ObjetivoModal from './Componentes/ModalInsertarObjetivos';
import { AiOutlinePlus } from 'react-icons/ai';
import '../Dependencias/formulario.css';
import SearchObjetivoArea from './searchObjetivoArea';

export const ObjetivoAreaView = () => {
    const [periodo, setPeriodo] = useState('');
    const [tipObj, setTipObj] = useState('');
    const [selectedObjective, setSelectedObjective] = useState('');
    const [reloadTrigger, setReloadTrigger] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        console.log("Abriendo modal...");
        console.log("Periodo:", periodo);
        console.log("Tipo de Objetivo:", tipObj);
        console.log("Objetivo Seleccionado:", selectedObjective);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        console.log("Cerrando modal...");
        setIsModalOpen(false);
    };

    const reloadData = () => {
        console.log("Recargando datos...");
        setReloadTrigger(prev => prev + 1);
    };

    return (
        <>
            <UpdatedNavBar />
            <div className="container-dependencias">
                <Stack spacing={1}>
                    <Stack>
                        <h2>Asignación de Áreas a Objetivo</h2>
                        <p className="lead blog-description">Asignación de áreas a objetivo según período.</p>
                        <hr />
                    </Stack>

                    <SearchObjetivoArea 
                        setPeriodo={setPeriodo} 
                        setTipObj={setTipObj} 
                        tipObj={tipObj} 
                        periodo={periodo} 
                        setSelectedObjective={setSelectedObjective} 
                    />

                    <div className='md-col-3'>
                        <Button
                            size='sm'
                            style={{
                                backgroundColor: '#28a745',
                                borderColor: '#28a745',
                                color: 'white',
                                padding: '0.25rem 0.5rem',
                                fontSize: '0.8rem'
                            }}
                            variant="success"
                            className="nueva-dependencia"
                            onClick={openModal}
                        >
                            <AiOutlinePlus /> Asignar área
                        </Button>
                    </div>
                    <TablaDatosTipoObjetivo TipoObjetivo={tipObj} CodigoPeriodo={periodo} CodigoObjetivo={selectedObjective} reloadTrigger={reloadTrigger} />
                </Stack>
            </div>
            <ObjetivoModal
                isOpen={isModalOpen}
                onClose={closeModal}
                periodo={periodo}
                tipoObjetivo={tipObj}
                CodigoObjetivo={selectedObjective}
                onInsertSuccess={reloadData}
            />
        </>
    );
};
