import React, { useState } from "react";
import { Button } from 'react-bootstrap';
import { UploadFile } from "./uploadFile";
import { DownloadButton } from "./downloadButton";
import { GrDocumentUpdate } from "react-icons/gr";

export const DownloadAndUpdate = (props) => {

    const [flag, setFlag] = useState(true);

    return(
        (props.both)?
        <>
            {
                (flag)?
                <>
                    <DownloadButton downloadURL={props.downloadURL} path={props.path} original={props.original}/>
                    <Button className="optionButtons" size="sm" variant="info" onClick={()=>{setFlag(false)}}><GrDocumentUpdate/> Cambiar archivo</Button>
                </>:<>
                    <UploadFile inputId={props.inputId} codigo={props.codigo} URL={props.URL} refresh={()=>{props.refresh();setFlag(true)}}
                        both={true} cancel={()=>{setFlag(true);}} oldPath={props.path} maxSize={props.maxSize}
                    />
                </>
            }    
        </>:<><DownloadButton downloadURL={props.downloadURL} path={props.path} original={props.original}/></>

    );
}