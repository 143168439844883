import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Table, OverlayTrigger, Tooltip, InputGroup } from 'react-bootstrap';
import { AiOutlinePlus, AiFillEdit, AiFillDelete, AiTwotoneAppstore, AiOutlineSearch } from 'react-icons/ai';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import './formulario.css'
import PopUpBorrar from '../../Components/popUpBorrar'

// HTTP Requests
import Axios from 'axios';

toast.configure()

export const Areas = (props) => {

    /**
     * Lista de dependencias
     */
    const [listaPrinAreas, setListaPrinAreas] = useState([]);
    const [listaAreas, setListaAreas] = useState([]);


    const [listaEliminar, setListaEliminar] = useState([]);
    
    /*modal del popup borrar */
    const [id, setId] = useState(null);
    const [nombreArea, setNombreArea] = useState(null);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState(null);

    // Handle the displaying of the modal based on type and id
    const showDeleteModal = (id,nombre) => {
        setId(id);
        setNombreArea(nombre)
        setDeleteMessage(`¿Está seguro de borrar el área ${nombre} ?`);
        setDisplayConfirmationModal(true);
    };

    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };


    /**
     * Modas de areas
     */
    
    const [showState, setShowState] = useState(false);
    const [showStateUpdate, setShowStateUpdate] = useState(false);
    const [codigoEntidad, setCodigoEntidad] = useState('');

    const busquedaArea = (listaInfo) => {
        const input1 = document.getElementById('input-1').value;
        const input2 = document.getElementById('input-2').value;

        let tmpArray = []
        if (String(input1) === '' && String(input2) === ''){
            return listaInfo
        } else {
            const indexes = listaInfo.map(
                    (element, index) => {
                            let flag = false;

                            if (String(input1) !== ''){
                                if(element.DESCRIPCION.toLowerCase().includes(input1.toLowerCase())){
                                    flag = true;
                                } else {
                                    return -1
                                }
                            }

                            if (String(input2) !== ''){
                                if(element.ABREVIATURA.toLowerCase().includes(input2.toLowerCase())){
                                    flag = true;
                                } else {
                                    return -1
                                }
                            }

                            if(flag){
                                return index
                            }
                            
                        }
                    ).filter(value => value >= 0)
            for (let i of indexes) {
                tmpArray.push(listaInfo[i])
            }
            return tmpArray
        }
    }

    const refreshArea = (param) => {
        Axios.get("/api/get/areas",{headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=> {
            setListaPrinAreas(response.data);
            setListaAreas(response.data);
        });
    }

    const submitArea = () => {
        if(props.descrArea !== '' && props.abreviaturaArea !== ''){
            Axios.post("/api/insert/area", {
                descrArea: props.descrArea, 
                abreviaturaArea: props.abreviaturaArea,
                codigo_padre: codigoEntidad,
                codigo_tipo_entidad: 1,
            },{headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=>{
                if(response.data.result){
                    props.setAbreviaturaArea('')
                    props.setDescrArea('')
                    setShowState(false);
                    refreshArea(codigoEntidad)
                }else{
                    toast.error(response.data.error);
                }
            });
        } else {
            toast.error('Ingrese al menos la descripción y abreviatura del área')
        }
    };

 
    useEffect(() => {
        Axios.get('/api/various/getEntity', 
            {headers:{"x-access-token": localStorage.getItem('token')}}).then((response) =>{
                setCodigoEntidad(response.data[0].codigo_entidad);
                refreshArea(response.data[0].codigo_entidad)
        });
    }, []);

  
    const deleteArea = (codigo) => {
        Axios.delete('/api/delete/area/' + String(codigo), {headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=>{
            if (response.data.result === 'Error'){
              toast.error('Error al eliminar área: ' + nombreArea)
            } else {
                toast.info('Área borrada con éxito: ' + nombreArea)
                refreshArea(codigoEntidad)
            }
        });
        setDisplayConfirmationModal(false);
    };
  
    
    const updateArea = () => {
        if(props.descrArea !== '' && props.abreviaturaArea !== ''){
            Axios.put('/api/update/area/'+String(props.idArea), {
                descrArea: props.descrArea, 
                abreviaturaArea: props.abreviaturaArea,
                },{headers:{"x-access-token": localStorage.getItem('token')}}).then((response)=>{
                    if (response.data.result === 'Error'){
                    toast.error('Error al actualizar el área: ' + props.descrArea)
                    } else {
                        refreshArea(codigoEntidad)
                    }
            });
        } else {
            toast.error('Ingrese al menos el nombre y abreviatura del área')
        }
    };

    return (
        <div className="container">
            {/**
             * Titulos principales de la página
             */}
            <h2>Área</h2 >
            <p className="lead blog-description">Áreas de la organización</p>
            
            {/**
             * Botón para crear nueva dependencia
             */}
            <Button size='sm' variant="success" className="nueva-dependencia" onClick={() => {
                props.setDescrArea('');
                props.setAbreviaturaArea(''); 
                props.setIdArea('');
                setShowState(true);}}>
                <AiOutlinePlus/> Nueva área
            </Button>
            {/** 
             * Primer Modal
             * Este modal es para el formulario de una nueva dependencia
            */}
            <Modal show={showState} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Agregar área
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Descripción del área</Form.Label>
                            <Form.Control onChange={(e)=> {props.setDescrArea(e.target.value)}} placeholder="Descripción del área" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Abreviatura</Form.Label>
                            <Form.Control onChange={(e)=> {props.setAbreviaturaArea(e.target.value);}} placeholder="Abreviatura" onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {submitArea();}}>Guardar</Button>
                    <Button variant='secondary' onClick={()=>{setShowState(false);}}>Cancelar</Button>
                </Modal.Footer>
            </Modal>
            <p/>
            {/**
             * Esta tabla es para hacer display de todas las AREAS existentes
             */}
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Opción</th>
                        <th>Descripción</th>
                        <th>Abreviatura</th>
                    </tr>
                    <tr>
                        <td>Herramientas de búsqueda</td>
                        <td>
                            <InputGroup className="search-input">
                                <Button 
                                    variant="outline-secondary"
                                    onClick={()=>{
                                        setListaAreas(busquedaArea(listaPrinAreas));
                                    }}
                                >
                                    <AiOutlineSearch/>
                                </Button>
                                <Form.Control
                                    onChange={()=>{
                                        setListaAreas(busquedaArea(listaPrinAreas));
                                    }}
                                    id="input-1"
                                    type="text"
                                    placeholder="búsqueda por descripción"
                                />
                            </InputGroup>
                        </td>
                        <td>
                            <InputGroup className="search-input">
                                <Button 
                                    variant="outline-secondary"
                                    onClick={()=>{
                                        setListaAreas(busquedaArea(listaPrinAreas));
                                    }}
                                >
                                    <AiOutlineSearch/>
                                </Button>
                                <Form.Control
                                    onChange={()=>{
                                        setListaAreas(busquedaArea(listaPrinAreas));
                                    }}
                                    id="input-2"
                                    type="text"
                                    placeholder="busqueda por abreviatura"
                                />
                            </InputGroup>
                        </td>
                    </tr> 
                </thead>
                <tbody>
                    {listaAreas.map((value) => {
                        return (
                        <tr key={value.CODIGO}>
                            <td>
                            <Button className="segundo-boton" size="sm" variant="info" onClick={() => {
                                props.setDescrArea(value.DESCRIPCION);
                                props.setAbreviaturaArea(value.ABREVIATURA); 
                                setShowStateUpdate(true);
                                props.setIdArea(value.CODIGO);
                                }}>
                                    <AiFillEdit/>    Editar
                            </Button>
                            {
                                (!(listaEliminar.find(Element => Element.CODIGO === value.CODIGO)) 
                                    && props.usuarioWeb === value.USUARIO_CREACION)?
                                    <Button className="segundo-boton" size="sm" variant="danger"
                                    onClick={() => showDeleteModal(value.CODIGO,value.DESCRIPCION)}
                                    >
                                        <AiFillDelete/>    Eliminar
                                    </Button> : 
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip id="button-tooltip-1">No tiene autorización para eliminar esta área</Tooltip>}
                                    >
                                        <span>
                                            <Button className="segundo-boton" size="sm" variant="danger" disabled>
                                                <AiFillDelete/>    Eliminar
                                            </Button>
                                        </span>
                                    </OverlayTrigger>
                                    
                                
                            }
                            </td>
                            <td>{value.DESCRIPCION}</td>
                            <td>{value.ABREVIATURA}</td>
                        </tr>
                        ); 
                    })}
                </tbody>
            </Table>
            <Modal show={showStateUpdate} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Editar área
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Descripción del área</Form.Label>
                            <Form.Control onChange={(e)=> {props.setDescrArea(e.target.value)}} placeholder="Descripción del área" defaultValue={props.descrArea} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Abreviatura</Form.Label>
                            <Form.Control disabled onChange={(e)=> {props.setAbreviaturaArea(e.target.value);}} placeholder="Abreviatura" defaultValue={props.abreviaturaArea}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {updateArea();setShowStateUpdate(false);}}>Guardar</Button>
                    <Button variant='secondary' onClick={()=>{
                        setShowStateUpdate(false);
                        props.setDescrArea('');
                        props.setAbreviaturaArea(''); 
                        props.setIdArea('');}}>Cancelar</Button>
                </Modal.Footer>
            </Modal>    
            
            <PopUpBorrar showModal={displayConfirmationModal}
                confirmModal={deleteArea} hideModal={hideConfirmationModal}
                id={id} message={deleteMessage} />               
        </div>
    );
}    

