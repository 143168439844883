import React, { useState, useEffect } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import axios from 'axios';
import { Button } from 'react-bootstrap';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ReporteEvaluacionRiesgo = ({ evaluacion }) => {
    const [evaluacionData, setEvaluacionData] = useState([]);
    const [imageData, setImageData] = useState('');

    useEffect(() => {
        fetchEvaluacionData();
        fetchImage();
    }, [evaluacion]);

    const fetchEvaluacionData = async () => {
        if (evaluacion) {
            try {
                const response = await axios.get('/api/getRiesgoById', {
                    headers: { "x-access-token": localStorage.getItem('token') },
                    params: {
                        codigoCia: evaluacion.CODIGO_CIA,
                        codigoPeriodo: evaluacion.CODIGO_PERIODO,
                        codigoTipoObjetivo: evaluacion.CODIGO_TIPO_OBJETIVO,
                        codigoObjetivo: evaluacion.CODIGO_OBJETIVO,
                        codigoArea: evaluacion.CODIGO_AREA,
                        codigoRiesgo: evaluacion.CODIGO_RIESGO // Si se proporciona, devuelve un solo riesgo; si no, devuelve una lista.
                    }
                });

                if (Array.isArray(response.data)) {
                    console.log(response.data);
                    setEvaluacionData(response.data); // Maneja una lista de riesgos
                } else {
                    setEvaluacionData([response.data]); // Maneja un solo riesgo
                }

                console.log("Datos obtenidos:", response.data);
            } catch (error) {
                console.error("Error fetching data", error);
            }
        }
    };

    const fetchImage = async () => {
        try {
            const response = await axios.get('/api/get/image-url', {
                headers: { "x-access-token": localStorage.getItem('token') }
            });
            
            const imageUrl = 'http://localhost:3001/Pictures/' + response.data.result[0].path;
            
            const imageResponse = await axios.get(imageUrl, { responseType: 'blob' });
            const reader = new FileReader();
            reader.onloadend = async() => {
                setImageData(reader.result);
            };
            reader.readAsDataURL(imageResponse.data);
        } catch (error) {
            console.error("Error fetching image", error);
        }
    };

    const generatePdf = () => {
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString();
        const formattedTime = currentDate.toLocaleTimeString();
        const encabezadoData = [
            [{ text: 'Entidad', style: 'tableHeader' }, { text: 'DEMO', style: 'tableData' }],
            [{ text: 'Periodo de evaluación', style: 'tableHeader' }, { text: evaluacion.CODIGO_PERIODO, style: 'tableData' }]
        ];
        if (evaluacion.CODIGO_TIPO_OBJETIVO) {
            encabezadoData.push([{ text: 'Tipo Objetivo', style: 'tableHeader' }, { text: evaluacion.CODIGO_TIPO_OBJETIVO, style: 'tableData' }]);
        }
        
        if (evaluacion.CODIGO_OBJETIVO) {
            encabezadoData.push([{ text: 'Objetivo', style: 'tableHeader' }, { text: evaluacion.CODIGO_OBJETIVO, style: 'tableData' }]);
        }
        
        if (evaluacion.CODIGO_AREA) {
            encabezadoData.push([{ text: 'Área', style: 'tableHeader' }, { text: evaluacion.CODIGO_AREA, style: 'tableData' }]);
        }
        console.log('imageData: ',imageData)
        const docDefinition = {
            pageSize: { width: 1600, height: 900 }, // Ajuste del tamaño de la página
            pageOrientation: 'landscape',
            pageMargins: [30, 70, 30, 30],
            header: function (currentPage, pageCount) {
                return {
                    columns: [
                        {
                            image: imageData,
                            width: 50
                        },
                        {
                            alignment: 'right',
                            stack: [
                                { text: `Página ${currentPage.toString()} de ${pageCount}`, fontSize: 10 },
                                { text: formattedDate, fontSize: 10 },
                                { text: formattedTime, fontSize: 10 },
                            ]
                        }
                    ],
                    margin: [30, 10]
                };
            },
            content: [
                {
                    text: 'Reporte de Evaluación de Riesgos',
                    style: 'header',
                    alignment: 'center',
                    margin: [0, 0, 0, 10]
                },
                {
                    style: 'tableExample',
                    table: {
                        widths: [200, '*'],
                        body: encabezadoData
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i) {
                            return 1;
                        },
                        hLineColor: function (i) {
                            return i === 1 ? 'black' : 'black';
                        },
                        vLineColor: function () {
                            return 'black';
                        },
                    },
                    margin: [0, 15, 0, 10]
                },
                {
                    table: {
                        headerRows: 2,
                        widths: [30, 150, 100, 50, 100, 100, 230, 75, 75, 100, 100, 50, 75, 75, 100],
                        body: [
                            [
                                { text: 'No.', style: 'tableHeader', alignment: 'center', rowSpan: 2 },
                                { text: 'Objetivo', style: 'tableHeader', alignment: 'center', rowSpan: 2 },
                                { text: 'Tipo de Objetivo', style: 'tableHeader', alignment: 'center', rowSpan: 2 },
                                { text: 'Ref.', style: 'tableHeader', alignment: 'center', rowSpan: 2 },
                                { text: 'Área Evaluada', style: 'tableHeader', alignment: 'center', rowSpan: 2 },
                                { text: 'Eventos', style: 'tableHeader', alignment: 'center', rowSpan: 2 },
                                { text: 'Descripción del Riesgo', style: 'tableHeader', alignment: 'center', rowSpan: 2 },
                                { text: 'Probabilidad (P)', style: 'tableHeader', alignment: 'center', rowSpan: 2 },
                                { text: 'Severidad (S)', style: 'tableHeader', alignment: 'center', rowSpan: 2 },
                                { text: 'Riesgo Inherente (P*S)', style: 'tableHeader', alignment: 'center', rowSpan: 2 },
                                { text: 'Eficiencia del Mitigador', style: 'tableHeader', alignment: 'center', rowSpan: 2 },
                                { text: 'A Mitigar', style: 'tableHeader', alignment: 'center', rowSpan: 2 },
                                { text: 'Evaluación Ajustada Residual', style: 'tableHeader', alignment: 'center', colSpan: 2, rowSpan: 1 },
                                {}, // Celda vacía para completar la combinación
                                { text: 'Riesgo Residual (RR)', style: 'tableHeader', alignment: 'center', rowSpan: 2}
                            ],
                            [
                                {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
                                { text: 'P', style: 'tableHeader', alignment: 'center' },
                                { text: 'S', style: 'tableHeader', alignment: 'center' }
                            ],
                            ...evaluacionData.map((item, index) => [
                                { text: index + 1, style: 'tableCell', alignment: 'center' },
                                { text: item.DESCRIPCION_OBJETIVO, style: 'tableCell', alignment: 'center' },
                                { text: item.DESCRIPCION_TIPO_OBJETIVO, style: 'tableCell', alignment: 'center' },
                                { text: item.REFERENCIA , style: 'tableCell', alignment: 'center' },
                                { text: item.DESCRIPCION_AREA, style: 'tableCell', alignment: 'center' },
                                { text: item.EVENTOS, style: 'tableCell', alignment: 'center' },
                                { text: item.DESCRIPCION, style: 'tableCell', alignment: 'left' },
                                { text: item.CODIGO_PROBABILIDAD, style: 'tableCell', alignment: 'center' },
                                { text: item.CODIGO_SEVERIDAD, style: 'tableCell', alignment: 'center' },
                                { text: item.RIESGO_INHERENTE, style: 'tableCell', alignment: 'center' },
                                { text: item.VALORMITIGACION, style: 'tableCell', alignment: 'center' },
                                { text: item.VARIABLE_MITIGACION, style: 'tableCell', alignment: 'center' },
                                { text: item.PROBABILIDAD_INHERENTE, style: 'tableCell', alignment: 'center' },
                                { text: item.SEVERIDAD_INHERENTE, style: 'tableCell', alignment: 'center' },
                                { text: item.RIESGO_RESIDUAL, style: 'tableCell', alignment: 'center', fillColor: getRiesgoResidualColor(item.RIESGO_RESIDUAL), color: 'white' }
                            ])
                        ]
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            if (i === 1 || i === 2) return 0; // Esto oculta las líneas horizontales específicas
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i) {
                            return 1;
                        },
                        hLineColor: function (i) {
                            if (i === 1 || i === 2) return 'transparent'; // Cambia el color de las líneas específicas a transparente
                            return 'gray';
                        },
                        vLineColor: function () {
                            return 'gray';
                        },
                    },
                    margin: [0, 15, 0, 10]
                },
                {
                    style: 'tableFooter',
                    table: {
                        widths: ['*', '*'],
                        body: [
                            [
                                {
                                    text: 'Responsable de la elaboración de la matriz (nombre y puesto):',
                                    style: 'footerHeader',
                                    fillColor: '#2a3f54',
                                    margin: [3, 3, 3, 3]
                                },
                                {
                                    text: '',
                                    fillColor: 'white',
                                    margin: [3, 3, 3, 60]
                                }
                            ]
                        ]
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 1 : 1;
                        },
                        vLineWidth: function (i) {
                            return 1;
                        },
                        hLineColor: function (i) {
                            return 'black';
                        },
                        vLineColor: function () {
                            return 'black';
                        },
                    },
                    margin: [0, 45, 0, 10]
                }
            ],
            styles: {
                header: {
                    fontSize: 22,
                    bold: true,
                    margin: [0, 20, 0, 10]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 10,
                    color: 'white',
                    fillColor: '#2a3f54',
                    margin: [3, 3, 3, 3]
                },
                tableCell: {
                    fontSize: 10,
                    margin: [3, 3, 3, 3]
                },
                footerHeader: {
                    bold: true,
                    fontSize: 12,
                    color: 'white',
                    fillColor: '#2a3f54',
                    margin: [3, 3, 3, 3],
                    height: 120
                }
            }
        };

        pdfMake.createPdf(docDefinition).download('reporte_evaluacion_riesgo.pdf');
    };

    const getRiesgoResidualColor = (riesgoResidual) => {
        if (riesgoResidual <= 10) {
            return '#3FA535'; // Verde
        } else if (riesgoResidual <= 15) {
            return '#FFED00'; // Amarillo
        } else {
            return '#CD1719'; // Rojo
        }
    };

    return (
        <div>
            <Button variant="secondary" onClick={generatePdf}>
                Generar Reporte de Evaluación de Riesgos
            </Button>
        </div>
    );
};

export default ReporteEvaluacionRiesgo;
