import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

// HTTP Requests
import Axios from 'axios';
import { toast } from 'react-toastify';

export const EditarInformacionEntidad = (props) => {

    const [validated, setValidated] = useState(false);

    const submitInfo = () => {
        Axios.post('/api/post/editEntidadInfo', {
            limite: props.limite,
            vigencia: props.vigencia,
            codigo: props.codigo
        },{headers:{"x-access-token": localStorage.getItem('tokenAdminSys')}}).then(
            (response)=>{
                if (response.data.error){
                    toast.error(response.data.message);
                } else if (response.data.result) {
                    toast.success('Información actualizada');
                    props.refresh();
                    props.setShow(false);
                } 
            }
        );
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            setValidated(true);
        } else {
            submitInfo();
        }
        setValidated(true);
    };

    return(
        <>
            <Modal show={props.show} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <Modal.Title>Editar información de {props.nombre}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="mb-2">
                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                <Form.Label>Limite de usuarios</Form.Label>
                                <Form.Control maxLength={10} value={props.limite} type="number" onChange={(e)=>{props.setLimite(e.target.value)}} />
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom02">
                                <Form.Label>Vigencia</Form.Label>
                                <Form.Control value={(props.vigencia)?props.vigencia:''} type="date"  onChange={(e)=>{props.setVigencia(e.target.value)}} />
                            </Form.Group>
                        </Row>
                        <hr/>
                        <Button type="submit" variant="success" size='sm'>Guardar</Button>
                        <Button variant="secondary" size='sm' onClick={()=>{props.setShow(false);}}>Cancelar</Button>
                    </Form>                
                </Modal.Body>
            </Modal>
        </>
    ); 
}